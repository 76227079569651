import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../../logger';
import {
    IPlayerChannel,
    MessageBroker,
    IBetFailedPayload,
    IMyAccountOpenChallengesData,
    IOpenNativeAppData,
    IFavouriteSportsData,
} from '@sparkware/uc-sdk-core';
import { IGeolocation } from '../../geolocation/models/IGeolocation';
import { IMyAccount, IPlayer } from '../../player/models';
import { ILoginOptions } from '../../authentication/models';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { UIService } from '../../ui';

@Service()
export class PlayerChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _playerChannel: IPlayerChannel;
    private readonly _uiService: UIService;

    private get _playerPromise(): Promise<IPlayer> {
        return LoaderManager.Instance.PlayerLoader.Instance;
    }

    private get _geolocationPromise(): Promise<IGeolocation> {
        return LoaderManager.Instance.GeolocationLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PlayerChannelSubscriber');
        this._uiService = Container.get(UIService);
        this._playerChannel = MessageBroker.getInstance().player;
        this._playerChannel.topics.CTA_Bet_Failed.subscribe(this.onCTABetFailed.bind(this));
        this._playerChannel.topics.CTA_Login_Request_Submit.subscribe(
            this.onLoginRequestSubmit.bind(this),
        );
        this._playerChannel.topics.CTA_MyAccount_Open.subscribe(this.onMyAccountOpen.bind(this));
        this._playerChannel.topics.CTA_MyAccount_Open_Challenges.subscribe(
            this.onMyAccountOpenChallenges.bind(this),
        );
        this._playerChannel.topics.CTA_MyAccount_Open_Reports.subscribe(
            this.onMyAccountOpenReports.bind(this),
        );
        this._playerChannel.topics.CTA_NRS_Open.subscribe(this.onNrsOpen.bind(this));
        this._playerChannel.topics.openNativeApp.subscribe(this.onNativeAppOpen.bind(this));
        this._playerChannel.topics.loginCompletion.subscribe(this.onOpenLogin.bind(this));
        this._playerChannel.topics.registerCompletion.subscribe(this.onOpenRegister.bind(this));
        this._playerChannel.topics.favouriteSports.subscribe(this.onFavouriteSports.bind(this));
    }

    private async onCTABetFailed(message: IBetFailedPayload) {
        const geolocation = await this._geolocationPromise;
        await geolocation?.betFailedHandler(message);

        const player = await this._playerPromise;
        player?.onBetFailed();
    }

    private async onLoginRequestSubmit() {
        const player = await this._playerPromise;
        player?.onLoginRequestSubmit();
    }

    private async onMyAccountOpen(myAccountModel?: IMyAccount) {
        const player = await this._playerPromise;
        player?.onMyAccountOpen(myAccountModel);
    }

    private async onMyAccountOpenChallenges(data: IMyAccountOpenChallengesData) {
        const player = await this._playerPromise;
        player?.onMyAccountOpenChallenges(data);
    }

    private async onMyAccountOpenReports() {
        const player = await this._playerPromise;
        player?.onMyAccountOpenReports();
    }

    private async onNrsOpen() {
        const player = await this._playerPromise;
        player?.onNrsOpen();
    }

    private async onNativeAppOpen(openNativeAppInput: IOpenNativeAppData) {
        const player = await this._playerPromise;
        player?.onNativeAppOpen(openNativeAppInput);
    }

    private async onOpenLogin(options?: ILoginOptions) {
        const player = await this._playerPromise;
        player?.onOpenLogin(options);
    }

    private async onOpenRegister() {
        const player = await this._playerPromise;
        player?.onOpenRegister();
    }

    private async onFavouriteSports(data: IFavouriteSportsData) {
        this._uiService.handleFavouriteSports(data);
    }
}
