import { Lock } from '../utils/lockUtil';
import { Service, Token } from 'typedi';
import { IIdentity } from './models/IIdentity';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const IdentityToken = new Token<IIdentity>('Identity');
@Service()
export class IdentityLoader extends BaseModuleLoader<IIdentity> {
    constructor() {
        super('IdentityLoader');
    }

    protected _createModuleInstance = async (): Promise<IIdentity> => {
        const module = await import(
            /* webpackChunkName: "identity/identity" */
            './identity'
        );

        return new module.Identity();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IIdentity> => IdentityToken;
}
