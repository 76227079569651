import { isNotSet, ValidationError, validationErrorFactory } from '../helpers';
import {
    IDeviceInformation,
    IInitAnonymousMessageData,
    IInitMessageData,
    IUserInfo,
} from './models';

export const validateInitMessageData = (
    data: unknown,
    isAnonymousMode: boolean = false,
): IInitMessageData => {
    const validationError = validationErrorFactory('UcNativeSDK - init');

    if (isNotSet(data)) throw validationError('Missing all required parameters');
    let initData = data as IInitMessageData;
    if (isNotSet(initData.langID)) throw validationError('Missing required parameter: langID');
    if (isNotSet(initData.sourceProductPackageID))
        throw validationError('Missing required parameter: sourceProductPackageID');
    if (isNotSet(initData.subBrandID))
        throw validationError('Missing required parameter: subBrandID');
    if (isNotSet(initData.correlationID))
        throw validationError('Missing required parameter: correlationID');
    if (isNotSet(initData.brandId)) throw validationError('Missing required parameter: brandId');
    if (isNotSet(initData.locale)) throw validationError('Missing required parameter: locale');
    if (isNotSet(initData.country)) throw validationError('Missing required parameter: country');
    if (isNotSet(initData.currencyCode))
        throw validationError('Missing required parameter: currencyCode');
    if (isNotSet(initData.deviceInformation))
        throw validationError('Missing required parameter: deviceInformation');
    validateDeviceInformation(initData.deviceInformation, validationError);

    if (!isAnonymousMode) {
        if (isNotSet(initData.userInfo))
            throw validationError('Missing required parameter: userInfo');
        validateUserInfo(initData.userInfo, validationError);
    }

    return initData;
};

export const validateInitAnonymousMessageData = (data: unknown): IInitAnonymousMessageData => {
    const validationError = validationErrorFactory('UcNativeSDK - initAnonymous');
    let initAnonymousData = validateInitMessageData(data, true) as IInitAnonymousMessageData;
    if (isNotSet(initAnonymousData.regulationTypeId))
        throw validationError('Missing required parameter: regulationTypeId');

    return initAnonymousData;
};

const validateDeviceInformation = (
    data: unknown,
    validationError: ValidationError,
): data is IDeviceInformation => {
    let deviceInformation = data as IDeviceInformation;

    if (isNotSet(deviceInformation.isHybrid))
        throw validationError('Missing required parameter: deviceInformation.isHybrid');
    if (isNotSet(deviceInformation.osName))
        throw validationError('Missing required parameter: deviceInformation.osName');
    if (isNotSet(deviceInformation.osVersion))
        throw validationError('Missing required parameter: deviceInformation.osVersion');
    return true;
};

const validateUserInfo = (data: unknown, validationError: ValidationError): data is IUserInfo => {
    let userInfo = data as IUserInfo;

    if (isNotSet(userInfo.securityCode))
        throw validationError('Missing required parameter: userInfo.securityCode');
    if (isNotSet(userInfo.rms)) throw validationError('Missing required parameter: userInfo.rms');
    if (isNotSet(userInfo.isFtd))
        throw validationError('Missing required parameter: userInfo.isFtd');
    if (isNotSet(userInfo.isUserVip))
        throw validationError('Missing required parameter: userInfo.isUserVip');
    if (isNotSet(userInfo.isPromotionPermitted))
        throw validationError('Missing required parameter: userInfo.isPromotionPermitted');
    return true;
};
