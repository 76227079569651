import Container from 'typedi';
import {
    ClientLogger,
    DataStoreDeferredObjectToken,
    LocalStorageToken,
    PerformanceToken,
    SessionStorageToken,
    SessionTimerInterval,
    WindowToken,
} from '../../../../../injection-tokens';
import { Config } from '../../../../../models/enums/Consts';
import { INativeMessage } from '../../native/models/INativeMessage';
import { MbAdapter } from '../../../../native-mb-adapter/native-mb-adapter';
import { TrackJSClientLogger } from '../../../../logger';

export const setMBServices = () => {
    Container.set(WindowToken, window);
    Container.set(ClientLogger, TrackJSClientLogger);
};

export const setMutualServices = () => {
    try {
        Container.set(SessionStorageToken, sessionStorage);
        Container.set(LocalStorageToken, localStorage);
        Container.set(PerformanceToken, performance);
        Container.set(SessionTimerInterval, Config.SessionTimerInterval);
        Container.set(DataStoreDeferredObjectToken, dataStoreRefreshDO);
    } catch (err) {
        console.log('setMutualServices failed');
    }
};

export const ucSendMessage = async (payload: INativeMessage): Promise<void> => {
    const _mbAdapter = Container.get(MbAdapter);
    await _mbAdapter.sendMessage(payload);
};
