import {
    IAuthenticateTopicData,
    ICookieBannerToggleData,
    IDialogTopicPayload,
    IIdentityChannel,
    ILogoutData,
    INativeChannel,
    INavigateData,
    INavigationChannel,
    IPlayerChannel,
    IPlayerSegmentationData,
    ITopicSubscription,
    ITrackingChannel,
    IUiChannel,
    IUserInteractionChannel,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import DialogType from '../ui/enums/DialogType';
import { IClientIntegrationPublishers } from './interfaces/IClientIntegrationPublishers';
import { IClientIntegrationSubscribers } from './interfaces/IClientIntegrationSubscribers';

const HEADERS = { publisher: 'client-integration' };

export class ClientIntegrationPubSub
    implements IClientIntegrationPublishers, IClientIntegrationSubscribers
{
    protected readonly _identityChannel: IIdentityChannel;
    protected readonly _navigationChannel: INavigationChannel;
    protected readonly _playerChannel: IPlayerChannel;
    protected readonly _userInteractionChannel: IUserInteractionChannel;
    protected readonly _uiChannel: IUiChannel;
    protected readonly _trackingChannel: ITrackingChannel;
    protected readonly _nativeChannel: INativeChannel;
    private readonly _sessionChannel: any;
    private readonly _sportChannel: any;
    private readonly _walletChannel: any;
    private _clientDeactivated?: boolean;

    constructor() {
        this._identityChannel = MessageBroker.getInstance().identity;
        this._nativeChannel = MessageBroker.getInstance().native;
        this._navigationChannel = MessageBroker.getInstance().navigation;
        this._playerChannel = MessageBroker.getInstance().player;
        this._sessionChannel = MessageBroker.getInstance().session;
        this._sportChannel = MessageBroker.getInstance().sport;
        this._trackingChannel = MessageBroker.getInstance().tracking;
        this._uiChannel = MessageBroker.getInstance().ui;
        this._userInteractionChannel = MessageBroker.getInstance().userinteraction;
        this._walletChannel = MessageBroker.getInstance().wallet;

        this.loginPublish = this.loginPublish.bind(this);
        this.logoutPublish = this.logoutPublish.bind(this);
        this.navigatePublish = this.navigatePublish.bind(this);
        this.deactivatePublish = this.deactivatePublish.bind(this);
        this.playerSegmentationPublish = this.playerSegmentationPublish.bind(this);
        this.initThirdPartyPublish = this.initThirdPartyPublish.bind(this);
        this.oddsFormatUpdatePublish = this.oddsFormatUpdatePublish.bind(this);
        this.sendAnalytics = this.sendAnalytics.bind(this);
        this.migrationPopupPublish = this.migrationPopupPublish.bind(this);
        this.isTouchIdEnabledPublish = this.isTouchIdEnabledPublish.bind(this);
        this.webLoginIsShownPublish = this.webLoginIsShownPublish.bind(this);
        this.webLoginLoadedPublish = this.webLoginLoadedPublish.bind(this);
        this.cookieBannerToggleVisibilityPublish =
            this.cookieBannerToggleVisibilityPublish.bind(this);
        this.tokenSubscribe = this.tokenSubscribe.bind(this);
        this.loginFailSubscribe = this.loginFailSubscribe.bind(this);
        this.loginSuccessSubscribe = this.loginSuccessSubscribe.bind(this);
        this.openLoginSubscribe = this.openLoginSubscribe.bind(this);
        this.playerPreferencesSubscribe = this.playerPreferencesSubscribe.bind(this);
        this.clientInitFailSubscribe = this.clientInitFailSubscribe.bind(this);
        this.clientInitSuccessSubscribe = this.clientInitSuccessSubscribe.bind(this);
        this.messageBrokerSubscribe = this.messageBrokerSubscribe.bind(this);
        this.openMyBetsSubscribe = this.openMyBetsSubscribe.bind(this);
        this.betSuccessSubscribe = this.betSuccessSubscribe.bind(this);
        this.changeBrowserURISubscribe = this.changeBrowserURISubscribe.bind(this);
    }

    //#region Publishers

    public loginPublish(authenticateData: IAuthenticateTopicData) {
        this._identityChannel.topics.authenticate.publish(HEADERS, authenticateData);
    }

    public logoutPublish(logoutData: ILogoutData) {
        this._identityChannel.topics.logout.publish(HEADERS, logoutData);
    }

    public navigatePublish(uri) {
        this._clientDeactivated = false;

        this._navigationChannel.topics.Navigate.publish(HEADERS, {
            uri: decodeURIComponent(uri),
        } as INavigateData);
    }

    public deactivatePublish() {
        if (!this._clientDeactivated) {
            this._clientDeactivated = true;
            this._navigationChannel.topics.Deactivate.publish(HEADERS, {});
        }
    }

    public playerSegmentationPublish(data?: IPlayerSegmentationData) {
        this._playerChannel.topics.playerSegmentationUpdate.publish(HEADERS, data);
    }

    public initThirdPartyPublish(Token) {
        this._identityChannel.topics.initThirdParty.publish(HEADERS, { Token });
    }

    public oddsFormatUpdatePublish(data) {
        this._userInteractionChannel.topics.oddsFormatUpdate.publish(HEADERS, data);
    }

    public cookieBannerToggleVisibilityPublish(data: ICookieBannerToggleData) {
        this._uiChannel.topics.cookieBannerToggleVisibility.publish(HEADERS, data);
    }

    public migrationPopupPublish() {
        this._uiChannel.topics.Dialog.publish(HEADERS, {
            dialogType: DialogType.Migration,
        } as IDialogTopicPayload);
    }

    public sendAnalytics(data) {
        this._trackingChannel.topics.sendAnalytics.publish(HEADERS, data);
    }

    public isTouchIdEnabledPublish(data: boolean) {
        this._nativeChannel.topics.isTouchIdEnabled.publish(HEADERS, data);
    }

    public webLoginIsShownPublish(data: boolean) {
        this._uiChannel.topics.webLoginIsShown.publish(HEADERS, data);
    }

    public webLoginLoadedPublish(data) {
        this._uiChannel.topics.webLoginLoaded.publish(HEADERS, data);
    }

    //#endregion Publishers

    //#region Subscribers

    public tokenSubscribe(callback): ITopicSubscription {
        return this._identityChannel.topics.getToken.subscribe(callback);
    }

    public loginFailSubscribe(callback): ITopicSubscription {
        return this._identityChannel.topics.userAuthenticationFailed.subscribe(callback);
    }

    public loginSuccessSubscribe(callback): ITopicSubscription {
        return this._identityChannel.topics.userAuthenticationSucceeded.subscribe(callback);
    }

    public openLoginSubscribe(callback): ITopicSubscription {
        return this._playerChannel.topics.openLogin.subscribe(callback);
    }

    public playerPreferencesSubscribe(callback?: (data: any) => void): ITopicSubscription {
        return this._playerChannel.topics.playerPreferences.subscribe(callback);
    }

    public clientInitFailSubscribe(callback): ITopicSubscription {
        return this._sessionChannel.topics.clientInitFailed.subscribe(callback);
    }

    public clientInitSuccessSubscribe(callback): ITopicSubscription {
        return this._sessionChannel.topics.clientInitSucceeded.subscribe(callback);
    }

    public messageBrokerSubscribe(callback): ITopicSubscription {
        return this._sessionChannel.topics.subscribedToMessageBroker.subscribe(callback);
    }

    public openMyBetsSubscribe(callback): ITopicSubscription {
        return this._sportChannel.topics.openMyBets.subscribe(callback);
    }

    public betSuccessSubscribe(callback): ITopicSubscription {
        return this._walletChannel.topics.PlayerAction_BetSucceeded.subscribe(callback);
    }

    public changeBrowserURISubscribe(callback): ITopicSubscription {
        return this._navigationChannel.topics.changeBrowserURI.subscribe(callback);
    }

    //#endregion Subscribers
}
