import { Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { IAppCatalog } from './interfaces';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
const moduleLock = new Lock();

export const AppCatalogToken = new Token<IAppCatalog>('AppCatalog');
@Service()
export class AppCatalogLoader extends BaseModuleLoader<IAppCatalog> {
    constructor() {
        super('AppCatalogLoader');
    }

    protected _createModuleInstance = async (): Promise<IAppCatalog> => {
        const module = await import(
            /* webpackChunkName: "app-catalog/root" */
            './app-catalog'
        );
        return new module.AppCatalog();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IAppCatalog> => AppCatalogToken;
}
