import Container, { Service } from 'typedi';

import { SessionStoreService } from '../store';
import { BaseDataStoreService } from './BaseDataStoreService';

@Service()
export class NativeDataStoreService extends BaseDataStoreService<any> {
    constructor() {
        super(Container.get(SessionStoreService), 'NativeDataStore');
    }
}
