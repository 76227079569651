import { Service, Token } from 'typedi';
import { IBiometricsAuthenticationService } from '../models/';
import { Lock } from '../../utils/lockUtil';
import { BaseModuleLoader } from '../../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const BiometricsAuthenticationServiceToken = new Token<IBiometricsAuthenticationService>(
    'BiometricsAuthenticationService',
);

@Service()
export class BiometricsAuthenticationServiceLoader extends BaseModuleLoader<IBiometricsAuthenticationService> {
    constructor() {
        super('BiometricsAuthenticationServiceLoader');
    }

    protected _createModuleInstance = async (): Promise<IBiometricsAuthenticationService> => {
        const { BiometricsAuthenticationService } = await import(
            /* webpackChunkName: "authentication/service/biometrics" */
            './biometrics-authentication-service'
        );

        return new BiometricsAuthenticationService();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IBiometricsAuthenticationService> =>
        BiometricsAuthenticationServiceToken;
}
