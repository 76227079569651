import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../logger';
import {
    SessionStorageToken,
    UserContextToken,
    FeatureAvailabilityToken,
} from '../../injection-tokens';
import { CRMService } from '../crm/crm.service';
import { ClientsFrameworkOpenClientProductService } from '../external/clients-framework';
import { IUserContext } from '../user-context/user-context-interface';
import { Utils } from '../utils';
import { WebLoginService } from '../web-login/web.login.service';
import {
    ActionID,
    AppIdentifiers,
    AreaType,
    IOpenCISResultData,
    IPerformActionTopicPayload,
    MessageBroker,
    TriggerType,
    CrossSellChannel,
} from '@sparkware/uc-sdk-core';
import { CookieStoreItemService } from '../storage/implementations';
import { UrlUtils } from '../utils/urlUtils';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';
import { ABTestFeatures } from '../action-handler/enums';
import { Brand, Features } from '../../models/enums/Consts';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import { ClickStreamTrackingProvider } from '../tracking';
import { IActionHandlerFactory } from '../action-handler/models/IActionHandlerFactory';
import { BrandUtils } from '../../../Modules/Utils/BrandUtils';
import PageContextManager from 'page-context-manager';
import { INativeService } from '../native/models/INativeService';
import { LoaderManager } from '../../loaders/LoaderManager';

interface OpenCasinoParams {
    productOfferingId: number;
    subBrandId: number;
    productPackageId: number;
    isRealMode: number;
    specificNavigation?: string;
    target: string;
    linkId?: string;
    checkIfTargetStillExists?: boolean;
    addToQueryString?: boolean;
}

@Service()
export class CrossSellService {
    private readonly _cfOpenClientProductService: ClientsFrameworkOpenClientProductService;
    private readonly _channel: CrossSellChannel;
    private readonly _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private readonly _cookieStoreItemService: CookieStoreItemService;
    private readonly _crmService: CRMService;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private readonly _featureAvailability: IFeatureAvailability;
    private readonly _logger: ILogger;
    private readonly _sessionStorage: Storage;
    private readonly _urlUtils: UrlUtils;
    private readonly _userContext: IUserContext;
    private readonly _utils: Utils;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    private get _actionHandlerFactoryPromise(): Promise<IActionHandlerFactory> {
        return LoaderManager.Instance.ActionHandlerFactoryLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('CrossSellService');
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('CrossSellService');
        this._channel = MessageBroker.getInstance().crossSell;
        this._userContext = Container.get(UserContextToken);
        this._crmService = Container.get(CRMService);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        this._sessionStorage = Container.get(SessionStorageToken);
        this._cfOpenClientProductService = Container.get(ClientsFrameworkOpenClientProductService);
        this._cookieStoreItemService = Container.get(CookieStoreItemService);
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
    }

    public OpenSport = (data: any) => {
        if (this._userContext.IsAuthenticated) {
            this.SportAutoLogin();
        }
    };

    public SportAutoLogin() {
        PF.Web.ClientsFramework.OpenClientProductPackage.clientAutoLogin(
            4,
            null,
            9,
            1,
            null,
            null,
            null,
        );
    }

    public OpenCasino = (data: any) => {
        const deepLinkData = data?.deeplink || null;

        if (this._userContext.IsAuthenticated) {
            const linkId = data?.linkId || null;

            this.CasinoAutoLogin(data?.target || null, deepLinkData, linkId);
        } else this.OpenAnonymousCasino(data);
    };

    public OpenCIS = async (data) => {
        let isCISEnabled = false;

        const nativeService = await this._nativeServicePromise;
        if (nativeService) {
            const isNativeCISEnabled = nativeService.findIfCISIsEnabled();
            if (isNativeCISEnabled)
                isCISEnabled = this._featureAvailability.IsFeatureEnabled(
                    ABTestFeatures.CASINO_IN_SPORT,
                );
        }
        if (!isCISEnabled) return false;

        const action: IPerformActionTopicPayload = {
            actionID: ActionID.openCIS,
            correlationID: this._utils.generateCorrelationID(),
            launchInfo: {
                businessCorrelationID: this._utils.generateCorrelationID(),
                sequentialCorrelationID: 1,
                channel: {
                    area: AreaType.button,
                    source: 'Unified Client',
                    element: 'Default',
                },
                trigger: TriggerType.userSelection,
                sourceAppID: AppIdentifiers.UnifiedClient,
                sourceAppVersion: '0.0.0',
                clientVersion: PageContextManager.getDeviceData().clientVersion,
            },
            actionData: data,
        };

        const actionHandlerFactory = await this._actionHandlerFactoryPromise;
        await actionHandlerFactory.performAction(action);

        return true;
    };

    public OpenAnonymousCasino = (data) => {
        const isIOSNativeOpenCasinoFeature = this._findIfIsIOSNativeOpenCasinoFeature();
        const { brandId } = PageContextManager.getBrandData();
        if (
            isIOSNativeOpenCasinoFeature &&
            (brandId === Brand.Sport_Com || brandId === Brand.Sport_UK)
        ) {
            //Feature to open casino inside sport - NOT USED at this moment
            this._sessionStorage.setItem('IsCasinoAutoLogin', 'true');
            const webLoginService = Container.get(WebLoginService);
            webLoginService.Show();
        } else {
            const queryString = data?.deeplink ? this._getOpenGameQS(data?.deeplink, true) : '';

            const casinoUrl = !isIOSNativeOpenCasinoFeature
                ? this._getCasinoUrl()
                : PageContextManager.getUrlResourceData().iosNativeCasinoUrl;

            if (casinoUrl) {
                const mrgBrands = [Brand.Sport_MG_SE, Brand.Sport_MG_DK, Brand.Sport_MG_MGA];
                const openCasinoUrl = mrgBrands.some((b) => b === brandId)
                    ? casinoUrl + queryString
                    : this._urlUtils.getURLWithSCut(casinoUrl + queryString);

                if (data?.target === 'open-cis') {
                    const relativeUrl = openCasinoUrl.replace(casinoUrl, '');
                    const parsedUrl = new URL(relativeUrl, window.location.origin);
                    const queryObject = Object.fromEntries(parsedUrl.searchParams.entries());
                    const cisResultData: IOpenCISResultData = {
                        relativeUrl,
                        url: openCasinoUrl,
                        queryObject,
                    };
                    this._channel.topics.CTA_CasinoClient_GetURL.publish(
                        { publisher: 'UC-CrossSellService' },
                        cisResultData,
                    );
                } else {
                    this._crmService.OpenExternalUrl(openCasinoUrl, data?.target);
                }
            }
        }
    };

    public CasinoAutoLogin = (formTarget?, deepLinkData?, casinoLinkId?) => {
        const params: OpenCasinoParams = this._getCasinoAutoLoginData(
            formTarget,
            deepLinkData,
            casinoLinkId,
        );

        const {
            isRealMode,
            productOfferingId,
            productPackageId,
            subBrandId,
            target,
            addToQueryString,
            checkIfTargetStillExists,
            linkId,
            specificNavigation,
        } = params;

        const eventParams = Object.keys(params).reduce((acc, nextKey) => {
            acc[`ccs_${nextKey}`] = params[nextKey];
            return acc;
        }, {});
        const formatter = this._eventFormatterBuilder.createFormatter('casinoAutoLogin');
        const elasticEventObj = formatter.formatUCEvent(
            { message: 'Authenticated cross to Casino' },
            { correlationID: this._utils.generateCorrelationID() },
            {
                event: 'casino-cross-sell',
                ...eventParams,
            },
        );
        this._clickStreamTrackingProvider.sendEventV2(elasticEventObj);

        this._cfOpenClientProductService.clientAutoLogin(
            productOfferingId,
            subBrandId,
            productPackageId,
            isRealMode,
            specificNavigation,
            target,
            linkId,
            checkIfTargetStillExists,
            addToQueryString,
        );
    };

    public AutoLoginToLiveDealer(target?) {
        let deviceInformation = PageContextManager.getDeviceData();

        target = target || '_blank';
        let subBrandId = null; // todo: change to 78 for scasino

        target = target || 'Casino';
        if (deviceInformation.isMobile) {
            this.getCasinoForMobileParams(target, 3, subBrandId, 88, 1, 1);
        } else {
            this._cfOpenClientProductService.clientAutoLogin(3, subBrandId, 88, 1, '', target, 1);
        }
    }

    public GamesAutoLogin() {
        let deviceInformation = PageContextManager.getDeviceData();
        if (deviceInformation.isMobile) {
            this.OpenGamesForMobile();
        } else {
            this._cfOpenClientProductService.clientAutoLogin(3, 79, 88, 1, '', '', 30);
        }
    }

    public async PokerAutoLogin(target = '_blank') {
        const specificNavigation =
            '<specificNavigation><CallBackURL>' +
            location.origin +
            '</CallBackURL></specificNavigation>';
        await this._cfOpenClientProductService.clientAutoLogin(
            2,
            null,
            146,
            1,
            specificNavigation,
            target,
            null,
        );
    }

    public async PISAutoLogin(target?) {
        const isNative = this._utils.findIfIsNative();
        target = target || '_blank';
        if (isNative) {
            await this._openPISForNative();
        } else {
            this._openPISForPC(target);
        }
    }

    OpenCGPForMobile(
        target,
        productOfferingId,
        subBrandId,
        productPackageId,
        isRealMode,
        linkId,
        deepLinkData,
    ) {
        let addToQueryString = null;
        if (deepLinkData) {
            addToQueryString = { deeplink: deepLinkData };
        }

        const { schemaUrl } = PageContextManager.getNativeData();

        if (this._utils.findIfIsNative()) {
            let specificNavigation =
                '<specificNavigation><CallBackURL>' +
                schemaUrl +
                '</CallBackURL></specificNavigation>';
            this._cfOpenClientProductService.clientAutoLogin(
                productOfferingId,
                subBrandId,
                productPackageId,
                isRealMode,
                specificNavigation,
                'native',
                linkId,
                undefined,
                addToQueryString,
            );
        } else {
            let specificNavigation =
                '<specificNavigation><CallBackURL>' +
                location.origin +
                '</CallBackURL></specificNavigation>';
            target = target || '_self';
            this._cfOpenClientProductService.clientAutoLogin(
                productOfferingId,
                subBrandId,
                productPackageId,
                isRealMode,
                specificNavigation,
                target,
                linkId,
                undefined,
                addToQueryString,
            );
        }
    }

    getCasinoForMobileParams = (
        target,
        productOfferingId?,
        subBrandId?,
        productPackageId?,
        isRealMode?,
        linkId?,
        addToQueryString?,
    ): OpenCasinoParams => {
        const params: OpenCasinoParams = {
            target,
            productOfferingId,
            subBrandId,
            isRealMode,
            linkId,
            addToQueryString,
            checkIfTargetStillExists: undefined,
            productPackageId,
        };

        const { schemaUrl } = PageContextManager.getNativeData();

        if (this._utils.findIfIsNative()) {
            params.specificNavigation = `<specificNavigation>
                    <CallBackURL>${schemaUrl}</CallBackURL>
                </specificNavigation>`;

            params.target = ['casinoNativeIOS', 'open-cis'].some(
                (targetTest) => target === targetTest,
            )
                ? target
                : 'native';
        } else {
            params.specificNavigation = `<specificNavigation><CallBackURL>${location.origin}</CallBackURL></specificNavigation>`;
            params.target = target || '_self';
        }

        return params;
    };

    public OpenGamesForMobile() {
        if (this._utils.findIfIsNative()) {
            const { schemaUrl } = PageContextManager.getNativeData();
            const specificNavigation =
                '<specificNavigation><CallBackURL>' +
                schemaUrl +
                '</CallBackURL></specificNavigation>';
            this._cfOpenClientProductService.clientAutoLogin(
                3,
                79,
                88,
                1,
                specificNavigation,
                'native',
                30,
            );
        } else {
            const specificNavigation =
                '<specificNavigation><CallBackURL>' +
                location.origin +
                '</CallBackURL></specificNavigation>';

            this._cfOpenClientProductService.clientAutoLogin(
                3,
                79,
                88,
                1,
                specificNavigation,
                '',
                30,
            );
        }
    }

    public VirtualSportAutoLogin() {
        this._getCasinoAutoLoginData('_blank', undefined, true);
    }

    private _getCasinoAutoLoginData(target?, deepLinkData?, linkId?): OpenCasinoParams {
        const isIOSNativeOpenCasinoFeature = this._findIfIsIOSNativeOpenCasinoFeature();
        const virtualSportRedirect = linkId ? linkId : undefined;
        const deviceInformation = PageContextManager.getDeviceData();
        let params: OpenCasinoParams = {} as OpenCasinoParams;
        const currentDomain = this._urlUtils.getDomain();
        const casinoDomain = this._urlUtils.getDomain(this._getCasinoUrl());
        const brandId = PageContextManager.getBrandData().brandId;
        target =
            isIOSNativeOpenCasinoFeature && target !== 'open-cis'
                ? 'casinoNativeIOS'
                : target ||
                  (currentDomain === casinoDomain &&
                  (brandId === Brand.Sport_MG_SE || brandId === Brand.Sport_MG_DK)
                      ? '_self'
                      : '_blank');
        let subBrandId = null; // todo: change to 78 for scasino

        let addToQueryString = null;
        if (deepLinkData) {
            addToQueryString = { deeplink: deepLinkData };
        }
        const userSelectedState = this._cookieStoreItemService.get('userSelectedState');
        if (userSelectedState) {
            if (addToQueryString) {
                addToQueryString.userSelectedState = userSelectedState;
            } else {
                addToQueryString = { userSelectedState };
            }
        }
        if (deviceInformation.isMobile || deviceInformation.isHybrid) {
            params = this.getCasinoForMobileParams(
                target,
                3,
                subBrandId,
                88,
                1,
                virtualSportRedirect ? virtualSportRedirect : '',
                addToQueryString,
            );
        } else {
            params = {
                productOfferingId: 3,
                subBrandId,
                productPackageId: 88,
                isRealMode: 1,
                specificNavigation: null,
                target,
                linkId: virtualSportRedirect,
                checkIfTargetStillExists: undefined,
                addToQueryString,
            };
        }

        return params;
    }

    private _openPISForPC(target) {
        if (this._userContext.IsAuthenticated) {
            this._cfOpenClientProductService.clientAutoLogin(null, 80, 146, 1, null, target, null);
        } else {
            const openPokerUrl = this._urlUtils.getURLWithSCut('https://www.888poker.com/');
            this._crmService.OpenExternalUrl(openPokerUrl);
        }
    }

    private async _openPISForNative() {
        const nativeService = await this._nativeServicePromise;
        if (nativeService) {
            nativeService.openExternalLink(this._getPISSmartLink());
        }
    }

    private _findIfIsIOSNativeOpenCasinoFeature = (): boolean => {
        const isNative = this._utils.findIfIsNative();
        const isIOSNativeCasinoActive = this._featureAvailability.IsFeatureEnabled(
            Features.IOS_NATIVE_CASINO,
        );

        if (isNative) {
            return !!PageContextManager.getDeviceData().isIOS && !!isIOSNativeCasinoActive;
        }

        return false;
    };

    private _getOpenGameQS = (deepLinkData, isQSEmpty) => {
        let result = '';

        result = `#${encodeURIComponent(`deeplink=${JSON.stringify(deepLinkData)}`)}`;

        return result;
    };

    private _getCasinoUrl = () => {
        const { brandId } = PageContextManager.getBrandData();

        let casinoUrl = null;

        switch (brandId) {
            case Brand.Sport_Spoker:
                if (BrandUtils.isPokerClient()) casinoUrl = `https://www.888casino.com/`;
                break;
            case Brand.Sport_Scasino:
            case Brand.Sport_UK:
            case Brand.Sport_Com:
                casinoUrl = 'https://www.888casino.com/';
                break;
            case Brand.Sport_IT:
                casinoUrl = 'https://www.888casino.it/';
                break;
            case Brand.Sport_ES:
                casinoUrl = 'https://www.888casino.es/';
                break;
            case Brand.Sport_DK:
                casinoUrl = 'https://www.888casino.dk/';
                break;
            case Brand.Sport_RO: {
                casinoUrl = 'https://www.888casino.ro/';
                break;
            }
            case Brand.Sport_SE:
                casinoUrl = 'https://www.888casino.se/';
                break;
            case Brand.Sport_CA_ON:
                casinoUrl = 'https://www.888casino.ca/';
                break;
            case Brand.Sport_NL:
                casinoUrl = 'https://www.888casino.nl/';
                break;
            case Brand.Sport_US_MI:
                casinoUrl = 'https://casino.sisportsbook.com/';
                break;
            case Brand.Sport_MG_SE:
            case Brand.Sport_MG_DK:
            case Brand.Sport_MG_MGA:
                casinoUrl = '/';
                break;
        }

        return casinoUrl;
    };

    private _getPISSmartLink = (): string => {
        let smartLinkURL: string;
        const userCountry = PageContextManager.getGlobalizationData().country;
        const { isIOS } = PageContextManager.getDeviceData();
        const isUKorIRL = ['IRL', 'GBR'].some(
            (country) => country.toLowerCase() === userCountry?.toLowerCase(),
        );
        const { crossSells } = PageContextManager.getSiteData();
        const pisCrossSellData = crossSells.filter((crossSellData) => crossSellData.id === 'PIS');
        if (pisCrossSellData) {
            if (isIOS) smartLinkURL = pisCrossSellData[0].nativeSmartLink;
            else
                smartLinkURL = isUKorIRL
                    ? pisCrossSellData[0].nativeSmartLink + 'GP'
                    : pisCrossSellData[0].nativeSmartLinkSecondary;
        }
        return smartLinkURL;
    };
}
