export class Lock {
    isLocked: boolean;
    pendingLockRequests: any[];

    constructor() {
        this.isLocked = false;
        this.pendingLockRequests = [];
    }

    acquireLock() {
        return new Promise<void>((resolve) => {
            if (!this.isLocked) {
                this.isLocked = true;
                resolve();
            } else {
                this.pendingLockRequests.push(resolve);
            }
        });
    }

    releaseLock() {
        if (this.pendingLockRequests.length > 0) {
            const nextLockRequest = this.pendingLockRequests.shift();
            nextLockRequest();
        } else {
            this.isLocked = false;
        }
    }
}
