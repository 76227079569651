import Container, { Service } from 'typedi';
import { ClientsFrameworkLibrary } from 'library-manager';

import { ILogger, LoggerProvider } from '../../logger';
import { IClientsFrameworkLogoutService } from './interfaces';

@Service()
export class ClientsFrameworkLogoutService implements IClientsFrameworkLogoutService {
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientsFrameworkLogoutService');
    }

    public doLogout = async (reason) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Logout.doLogout(reason),
        );
    };

    public doLogoutAndShowTimeoutDialog = async (kickReasonId?, timeoutKickOptions?) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Logout.doLogoutAndShowTimeoutDialog(
                kickReasonId,
                timeoutKickOptions,
            ),
        );
    };

    public doLogoutRefreshState = async (logoutData: any, kickReasonId?: number) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Logout.doLogoutRefreshState(logoutData, kickReasonId),
        );
    };

    public doLogoutWithoutRefresh = async (
        kickReasonId,
        isLogoutFromPlatform,
    ): Promise<boolean> => {
        await ClientsFrameworkLibrary.ready;
        return PF.Web.ClientsFramework.Logout.doLogoutWithoutRefresh(
            kickReasonId,
            isLogoutFromPlatform,
        );
    };

    public doSoftKick = async (kickReasonId: number, correlationId?: string) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Logout.doSoftKick(kickReasonId, correlationId),
        );
    };

    public removeLobbyCookie = async () => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Logout.removeLobbyCookie(),
        );
    };

    public cleanUserStorageItems = async () =>
        ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Logout.cleanUserStorageItems(),
        );
}
