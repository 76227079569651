import API from 'api';
import { HTTPVerb } from '../Modules/Utils/API/Constants/HTTPVerb';
import { AxiosRequestConfig } from 'axios';

enum APIConstants {
    GetToken = '/api/GetToken',
    GetFirebaseToken = '/api/GetTokenController/GetFirebaseToken/',
    GetCGPToken = '/api/GetTokenController/GetGGPToken/',
    GetTouchIdToken = '/api/GetTokenController/GetTouchIdToken/',
}

export default class TokenApi {
    public static GetToken = async (params?) => {
        const { response } = await API.authenticatedRequest({
            url: APIConstants.GetToken,
            method: HTTPVerb.GET,
            params,
        });

        return response;
    };

    public static GetFirebaseToken = async () => {
        const { response } = await API.authenticatedRequest({
            url: APIConstants.GetFirebaseToken,
            method: HTTPVerb.POST,
        });

        return response;
    };

    public static GetCGPToken = async (data: any) => {
        const { response } = await API.authenticatedRequest({
            url: APIConstants.GetCGPToken,
            method: HTTPVerb.POST,
            data,
        });

        return (response && response.Token) || undefined;
    };

    public static GetTouchIdTokenData = async (data, onSuccess?, onFail?) => {
        const options: AxiosRequestConfig = {
            url: APIConstants.GetTouchIdToken,
            data: data,
            method: HTTPVerb.POST,
        };

        const { response, errorResponse } = await API.authenticatedRequest(options);

        if (response) onSuccess && onSuccess(response);
        else if (errorResponse) onFail && onFail(errorResponse);
    };
}
