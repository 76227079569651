import { IWalletChannel, MessageBroker, IBalanceData } from '@sparkware/uc-sdk-core';
import Container, { Service } from 'typedi';
import UserApi from '../../../APIs/UserApi';
import { ILogger, LoggerProvider } from '../logger';

@Service()
export class WalletService {
    private readonly _logger: ILogger;

    private readonly _walletChannel: IWalletChannel;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('WalletService');

        this._walletChannel = MessageBroker.getInstance().wallet;
    }

    public sendBalanceUpdated = (balanceData) => {
        this._publishBalanceUpdated(balanceData);
    };

    public sendCurrentUserBalanceUpdated = async (): Promise<void> => {
        try {
            this._logger.debug('Begin send current user updated balance');
            const { balance, error } = await this.getCurrentUserBalance();
            if (!error) {
                this._publishBalanceUpdated(balance);
            }
            this._logger.debug('End in sending current user updated balance');
        } catch (err) {
            this._logger.error(`[sendCurrentUserBalanceUpdated] failed`, err);
            throw err;
        }
    };

    private _publishBalanceUpdated = (balanceData: IBalanceData) => {
        this._walletChannel.topics.BalanceUpdated.publish(
            { publisher: 'UCF-WalletService' },
            { Balance: balanceData },
        );
    };

    private getCurrentUserBalance = async () => {
        try {
            this._logger.debug('Begin get current user balance');
            const { response, errorResponse } = await UserApi.GetCurrentBalance();
            if (errorResponse) {
                this._logger.error(`Fail to get current user balance.\nError: `, errorResponse);
            }
            this._logger.debug('End in getting user balance');
            return { balance: errorResponse ? null : response, error: errorResponse };
        } catch (err) {
            this._logger.error(`[getCurrentUserBalance] failed`, err);
            return {
                balance: null,
                error: err,
            };
        }
    };
}
