import { Service, Token } from 'typedi';
import { IAuthenticationStorage } from '../models';
import { Lock } from '.././../utils/lockUtil';
import { BaseModuleLoader } from '../../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const AuthenticationStorageToken = new Token<IAuthenticationStorage>(
    'AuthenticationStorage',
);

@Service()
export class AuthenticationStorageLoader extends BaseModuleLoader<IAuthenticationStorage> {
    constructor() {
        super('AuthenticationStorageLoader');
    }

    protected _createModuleInstance = async (): Promise<IAuthenticationStorage> => {
        const { AuthenticationStorage } = await import(
            /* webpackChunkName: "authentication/storage" */
            './authentication.storage'
        );

        return new AuthenticationStorage();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IAuthenticationStorage> => AuthenticationStorageToken;
}
