import Container, { Service } from 'typedi';
import IdentityApi from '../../../APIs/IdentityApi';
import UserApi from '../../../APIs/UserApi';
import {
    AppIdentifiers,
    ICreateAppContainerData,
    ProductPackageToID,
} from '@sparkware/uc-sdk-core';
import {
    AuthenticationStatus,
    MessageType,
    NativeInterfaces,
    UrlSites,
} from '../client-integration/init/native/enums/enums';
import { INativeMessage } from '../client-integration/init/native/models/INativeMessage';
import { ILogger, LoggerProvider } from '../logger';
import { ClickStreamTrackingProvider } from '../tracking';
import { MessageBroker, IUiChannel } from '@sparkware/uc-sdk-core';
import {
    IGetNetAcuityDataResponse,
    IHandleAppCatalogResponse,
    IInitAnonymousMessageData,
    IInitMessageData,
    IValidateTokenRequest,
    IValidateTokenResponse,
} from './models';
import { IDeviceInfoResponse } from './models/IDeviceInfoResponse';
import INativeSdkClickStreamEventData from './models/INativeSdkClickStreamEventData';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
    Level,
} from '@unified-client/event-formatter';
import { IUpdateContextMessageData } from './models/IUpdateContextMessageData';
import { ClientTracking } from '../tracking/models/clientTracking';
import { UserContextToken, UserDataStoreDeferredObjectToken } from '../../injection-tokens';
import { IUserContext } from '../user-context/user-context-interface';
import { WindowSimpleStoreService } from '../storage/implementations/simple-store';
import { LocalStoreService } from '../storage/implementations/store';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { IUserFeatureEligibilityData } from './models/IUserFeatureEligibilityData';
import { IBIEventHandler } from '../bi/models/IBIEventHandler';
import { NativeSdkDispatcher } from './native-sdk-dispatcher';
import { IAppCatalog, IAppsCatalogData } from '../app-catalog/interfaces';
import { IInitNativeResult } from './models/IInitNativeResult';
import { IApplicationsDataType } from './models/IApplicationsData';
import PageContextManager from 'page-context-manager';
import { FeatureNativeStorage } from '../feature/feature-native.storage';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import { ISessionUserData } from '../session-manager/interfaces/ISessionUserData';
import {
    IABFeatureTest,
    IGetABTestActiveFeaturesRequest,
} from '../feature/feature-availability/interfaces';
import { Utils } from '../utils';
import { LoaderManager } from '../../loaders/LoaderManager';
@Service()
export class NativeSdkUtils {
    private readonly _uiChannel: IUiChannel;
    private readonly _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private readonly _logger: ILogger;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private readonly _userContextNative: IUserContext;
    private readonly _windowSimpleStoreService: WindowSimpleStoreService;
    private readonly _localStoreService: LocalStoreService;
    private readonly _nativeSdkDispatcher: NativeSdkDispatcher;
    protected readonly _clientTracking: ClientTracking;
    private readonly _featureNativeStorage: FeatureNativeStorage;
    private readonly _utils: Utils;
    private _getNetAcuityDataPromise: Promise<IGetNetAcuityDataResponse>;
    private _getDeviceInfoPromise: Promise<IDeviceInfoResponse>;

    private _userDataStoreDeferredObject: DeferredObject<ISessionUserData>;

    private get _appCatalogPromise(): Promise<IAppCatalog> {
        return LoaderManager.Instance.AppCatalogLoader.Instance;
    }

    private get _biEventHandlerPromise(): Promise<IBIEventHandler> {
        return LoaderManager.Instance.BIEventHandlerLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('NativeSdkUtils');
        this._uiChannel = MessageBroker.getInstance().ui;
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._uiChannel.topics.CreateAppContainer.subscribe(this.createAppContainer.bind(this));
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('NativeSdkUtils');
        this._userContextNative = Container.get(UserContextToken);
        this._windowSimpleStoreService = Container.get(WindowSimpleStoreService);
        this._localStoreService = Container.get(LocalStoreService);
        this._nativeSdkDispatcher = Container.get(NativeSdkDispatcher);
        this._clientTracking = Container.get(ClientTracking);
        this._featureNativeStorage = Container.get(FeatureNativeStorage);
        this._utils = Container.get(Utils);
        this._userDataStoreDeferredObject = Container.get(UserDataStoreDeferredObjectToken);
    }

    public processInitMessage = async (message: IInitMessageData): Promise<void> => {
        try {
            this._logger.info(`[processInitMessage] start`);
            const initStartTime: number = Date.now();

            this._setContextData(message);
            const messageData: IInitNativeResult = await this._initApis(message);
            const initResult: INativeMessage = {
                messageType: MessageType.initResult,
                messageData,
            };
            this._generateElasticInitResults(
                'handleInitMessage',
                'native-sdk-initresult',
                messageData,
                initStartTime,
                message.correlationID,
            );
            this._logger.log(
                `NativeSDK init result object: ${JSON.stringify(initResult.messageData)}`,
            );
            this._nativeSdkDispatcher.dispatchNSDKEvent(
                NativeInterfaces.PokerNativeInterface,
                initResult,
            );
        } catch (err) {
            this._logger.error(`[processInitMessage] failed`, err);
        }
    };

    public processInitAnonymousMessage = async (
        message: IInitAnonymousMessageData,
    ): Promise<void> => {
        try {
            this._logger.info(`[processInitAnonymousMessage] start`);
            const initStartTime: number = Date.now();

            this._setContextData(message);
            const messageData: IInitNativeResult = await this._initAnonymousApis(message);
            const initResult: INativeMessage = {
                messageType: MessageType.initResult,
                messageData,
            };
            this._generateElasticInitResults(
                'handleInitAnonymousMessage',
                'native-sdk-init-anonymous-result',
                messageData,
                initStartTime,
                message.correlationID,
            );
            this._logger.log(
                `NativeSDK init anonymous result object: ${JSON.stringify(initResult.messageData)}`,
            );
            this._nativeSdkDispatcher.dispatchNSDKEvent(
                NativeInterfaces.PokerNativeInterface,
                initResult,
            );
        } catch (err) {
            this._logger.error(`[processInitAnonymousMessage] failed`, err);
        }
    };

    public setInitAnonymousSessionStorageItems = (
        initAnonymousMessageData: IInitAnonymousMessageData,
        deviceInfoResponse: IDeviceInfoResponse,
        getNetAcuityDataResponse: IGetNetAcuityDataResponse,
    ): void => {
        this.setInitSessionStorageItems(
            initAnonymousMessageData,
            deviceInfoResponse,
            getNetAcuityDataResponse,
            null,
        );
        PageContextManager.updatePageContext({
            regulation: {
                regulationTypeId: initAnonymousMessageData.regulationTypeId,
            },
            brand: {
                brandId: initAnonymousMessageData.brandId,
                subBrandId: initAnonymousMessageData.subBrandID,
            },
        });
    };

    public setInitSessionStorageItems = (
        initMessageData: IInitMessageData,
        deviceInfoResponse: IDeviceInfoResponse,
        getNetAcuityDataResponse: IGetNetAcuityDataResponse,
        validateTokenResponse?: IValidateTokenResponse,
    ): void => {
        PageContextManager.updatePageContext({
            brand: {
                ...PageContextManager.getBrandData(),
                ...(validateTokenResponse && {
                    brandId: validateTokenResponse.SessionDetails?.BrandId,
                    subBrandId: validateTokenResponse.SessionDetails?.SubBrand,
                    brandName: initMessageData.brandName,
                }),
            },
            device: {
                ...PageContextManager.getDeviceData(),
                productPackage: ProductPackageToID.UNIFIED_CLIENT_NATIVE,
                browser: deviceInfoResponse.Browser,
                deviceTypeId: deviceInfoResponse.DeviceTypeId,
                isHybrid: /true/i.test(initMessageData.deviceInformation.isHybrid as any),
                osName: initMessageData.deviceInformation.osName,
                osVersion: initMessageData.deviceInformation.osVersion,
            },
            localization: {
                ...PageContextManager.getLocalizationData(),
                langIso3: initMessageData.langID,
                clientLangIso3: initMessageData.langID,
                culture: initMessageData.locale,
                currency: initMessageData.currencyCode,
            },
            globalization: {
                ...PageContextManager.getGlobalizationData(),
                country: initMessageData.country,
            },
            network: {
                ...PageContextManager.getNetworkData(),
                ...(validateTokenResponse && {
                    ipAddress: validateTokenResponse.SessionDetails?.ClientIp,
                }),
            },
        });

        if (validateTokenResponse) {
            PageContextManager.updatePageContextAuthenticated({
                regulation: {
                    regulationTypeId: validateTokenResponse.SessionDetails?.RegulationType,
                },
                user: {
                    ...PageContextManager.getUserData(),
                    isPromotionPermitted: initMessageData.userInfo.isPromotionPermitted,
                    rms: initMessageData.userInfo.rms,
                    isFtd: initMessageData.userInfo.isFtd,
                    isUserVip: initMessageData.userInfo.isUserVip,
                    stateId: Number(initMessageData.stateID || getNetAcuityDataResponse.StateID),
                    cid: validateTokenResponse.PlayerId,
                },
                network: {
                    isp: getNetAcuityDataResponse.ISP,
                    proxyType: getNetAcuityDataResponse.ProxyType,
                },
                session: {
                    globalSessionId: validateTokenResponse.SessionDetails?.InitialSessionId,
                    playerSessionId: validateTokenResponse.SessionDetails?.PlayerSessionId,
                },
            });
        }

        this._windowSimpleStoreService.set(
            StorageItemEnum.NativeSDKSourceProductPackage,
            initMessageData.sourceProductPackageID,
        );
    };

    public createAppContainer = (messageData: ICreateAppContainerData) => {
        if (!document.getElementById(messageData.containerID)) {
            const container = document.createElement('div');
            container.setAttribute('id', messageData.containerID);
            document.body.appendChild(container);
        }
    };

    public handleUpdateContext = (messageData: IUpdateContextMessageData) => {
        const savedCurrentContext = this._windowSimpleStoreService.get('contextData');

        const currentContext = JSON.parse(savedCurrentContext);
        currentContext.language = messageData.langID;

        this._windowSimpleStoreService.set('contextData', JSON.stringify(currentContext));

        PageContextManager.updatePageContext({
            localization: {
                ...PageContextManager.getLocalizationData(),
                langIso3: messageData.langID,
                clientLangIso3: messageData.langID,
                culture: messageData.locale,
            },
        });
    };

    private _generateElasticInitResults = (
        subComponentName: string,
        eventName: string,
        messageData: IInitNativeResult,
        initStartTime: number,
        correlationID: string,
    ) => {
        this._logger.info(`[_generateElasticInitResults] start`);
        const formatter = this._eventFormatterBuilder.createFormatter(subComponentName);
        const eventData: INativeSdkClickStreamEventData = {
            event: eventName,
            messagePayload: messageData,
            duration: Date.now() - initStartTime,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'Handler RegistrationPostMessageHandler is going to be executed',
            eventData,
        );
    };

    private _setContextData = (message: IInitMessageData) => {
        this._logger.info(`[_setContextData] start`);
        const contextData = {
            brandId: message.brandId,
            subBrandId: message.subBrandID,
            appId: AppIdentifiers.UnifiedClientNative,
            country: message.country,
            language: message.langID,
            publicationsLanguages: {},
            siteUrl: UrlSites.PokerNativeUrl,
            state: '',
            sr: '',
        };
        this._windowSimpleStoreService.set('contextData', JSON.stringify(contextData));
        this._windowSimpleStoreService.set(
            StorageItemEnum.ClientThemeName,
            message.clientThemeName,
        );

        PageContextManager.updatePageContext({
            brand: {
                //TODO Temporary solution until Poker will start sending us marketingBrandID
                marketingBrandId: this._utils.getMarketingBrandIdByWidgetTheme(),
            },
        });
    };

    private async _initApis(message: IInitMessageData): Promise<IInitNativeResult> {
        let authenticationStatus = AuthenticationStatus.Success;
        let errorDescription = '';
        let erroCode = 0;
        let applicationsData: IApplicationsDataType = {};

        const ValidateTokenRequest: IValidateTokenRequest = {
            AppId: AppIdentifiers.UnifiedClientNative,
            ProductPackageId: ProductPackageToID.UNIFIED_CLIENT_NATIVE,
            SourceProductPackageId: message.sourceProductPackageID,
            SubBrandId: message.subBrandID,
            Token: message.userInfo.securityCode,
        };
        const validateTokenPromise: Promise<IValidateTokenResponse> =
            IdentityApi.ValidateToken(ValidateTokenRequest);

        if (!this._getNetAcuityDataPromise)
            this._getNetAcuityDataPromise = IdentityApi.GetNetAcuityData();

        if (!this._getDeviceInfoPromise) this._getDeviceInfoPromise = IdentityApi.GetDeviceInfo();

        try {
            const apiPromisesExecution = await Promise.all([
                validateTokenPromise,
                this._getNetAcuityDataPromise,
                this._getDeviceInfoPromise,
            ]);
            const [validateTokenResponse, getNetAcuityDataResponse, deviceInfoResponse] =
                apiPromisesExecution;
            this._logger.info(
                `[processInitMessage] all promises resolved, validateTokenResponse: ${JSON.stringify(
                    validateTokenResponse,
                )}`,
            );

            // validateTokenResponse
            if (validateTokenResponse?.IsOk) {
                this._userContextNative.setAuthorizationToken(validateTokenResponse.AccessToken);

                this._userDataStoreDeferredObject.resolve();
                this._logger.log('Security code successfully validated.');
            } else {
                authenticationStatus = AuthenticationStatus.Fail;
                errorDescription = `ValidateToken: ${validateTokenResponse?.ErrorDescription}`;
                erroCode = validateTokenResponse?.ErrorCode;
                this._logger.error(
                    `failed to validate token, error code: ${validateTokenResponse?.ErrorCode}, error description: ${validateTokenResponse?.ErrorDescription}`,
                );
                return { authenticationStatus, errorDescription, erroCode, applicationsData };
            }

            // getNetAcuityDataResponse
            if (getNetAcuityDataResponse?.IsOk) {
                sessionStorage['CID'] = validateTokenResponse.PlayerId;
                this.setInitSessionStorageItems(
                    message,
                    deviceInfoResponse,
                    getNetAcuityDataResponse,
                    validateTokenResponse,
                );
                this._clientTracking.addTrackingAuthMetadata();
                this._sendAuthenticationResultFromAutologin().then(() => {});
            } else {
                authenticationStatus = AuthenticationStatus.Fail;
                errorDescription = `GetNetAcuityData: ${getNetAcuityDataResponse?.ErrorDescription}`;
                erroCode = getNetAcuityDataResponse?.ErrorCode;
                this._logger.error(
                    `Failed getting ISP, StateID and ProxyType, error code: ${getNetAcuityDataResponse?.ErrorCode}, error description: ${getNetAcuityDataResponse?.ErrorDescription}`,
                );
                return { authenticationStatus, errorDescription, erroCode, applicationsData };
            }

            const appCatalog = await this._appCatalogPromise;
            const appCatalogDataPromise: Promise<IAppsCatalogData> = appCatalog.getAppsCatalog();

            const abPromise = this._setFeatureABSessionStorage(
                validateTokenResponse.SessionDetails?.PlayerSessionId,
            );
            const [appCatalogResponse, abResponse] = await Promise.all([
                appCatalogDataPromise,
                abPromise,
            ]);
            // appCatalogResponse
            const handleAppCatalogResponseResult =
                await this._handleAppCatalogResponse(appCatalogResponse);
            if (handleAppCatalogResponseResult.isOK) {
                applicationsData = handleAppCatalogResponseResult.applicationsData;
            } else {
                return handleAppCatalogResponseResult;
            }

            // abResponse
            if (!abResponse) {
                authenticationStatus = AuthenticationStatus.Fail;
                errorDescription = `initiated ab failed`;
                return { authenticationStatus, errorDescription, erroCode, applicationsData };
            }

            // <- everything passed ok ->
            return { authenticationStatus, errorDescription, erroCode, applicationsData };
        } catch (error) {
            authenticationStatus = AuthenticationStatus.Fail;
            errorDescription = error;
            this._logger.error(`Failed NativeSdk init flow: error description: ${error}`);
            return { authenticationStatus, errorDescription, erroCode, applicationsData };
        }
    }

    private async _initAnonymousApis(
        message: IInitAnonymousMessageData,
    ): Promise<IInitNativeResult> {
        let authenticationStatus = AuthenticationStatus.Success;
        let errorDescription = '';
        let erroCode = 0;
        let applicationsData: IApplicationsDataType = {};

        if (!this._getNetAcuityDataPromise)
            this._getNetAcuityDataPromise = IdentityApi.GetNetAcuityData();

        if (!this._getDeviceInfoPromise) this._getDeviceInfoPromise = IdentityApi.GetDeviceInfo();

        try {
            const apiPromisesExecution = await Promise.all([
                this._getNetAcuityDataPromise,
                this._getDeviceInfoPromise,
            ]);
            const [getNetAcuityDataResponse, deviceInfoResponse] = apiPromisesExecution;
            this._logger.info('[processInitAnonymousMessage] all promises resolved');

            // getNetAcuityDataResponse
            if (getNetAcuityDataResponse?.IsOk) {
                this.setInitAnonymousSessionStorageItems(
                    message,
                    deviceInfoResponse,
                    getNetAcuityDataResponse,
                );
                this._clientTracking.addTrackingAuthMetadata();
                this._sendAuthenticationResultFromAutologin().then(() => {});
            } else {
                authenticationStatus = AuthenticationStatus.Fail;
                errorDescription = `GetNetAcuityData: ${getNetAcuityDataResponse?.ErrorDescription}`;
                erroCode = getNetAcuityDataResponse?.ErrorCode;
                this._logger.error(
                    `Failed getting ISP, StateID and ProxyType, error code: ${getNetAcuityDataResponse?.ErrorCode}, error description: ${getNetAcuityDataResponse?.ErrorDescription}`,
                );
                return { authenticationStatus, errorDescription, erroCode, applicationsData };
            }

            const appCatalog = await this._appCatalogPromise;
            const appCatalogDataPromise: Promise<IAppsCatalogData> = appCatalog.getAppsCatalog();
            const [appCatalogResponse] = await Promise.all([appCatalogDataPromise]);
            // appCatalogResponse
            const handleAppCatalogResponseResult =
                await this._handleAppCatalogResponse(appCatalogResponse);
            if (handleAppCatalogResponseResult.isOK) {
                applicationsData = handleAppCatalogResponseResult.applicationsData;
            } else {
                return handleAppCatalogResponseResult;
            }

            // <- everything passed ok ->
            return { authenticationStatus, errorDescription, erroCode, applicationsData };
        } catch (error) {
            authenticationStatus = AuthenticationStatus.Fail;
            errorDescription = error;
            this._logger.error(`Failed NativeSdk init anonymous flow: error description: ${error}`);
            return { authenticationStatus, errorDescription, erroCode, applicationsData };
        }
    }

    private _handleAppCatalogResponse = async (
        appCatalogResponse: IAppsCatalogData<any>,
    ): Promise<IHandleAppCatalogResponse> => {
        let handleAppCatalogResponseResult = {} as IHandleAppCatalogResponse;
        if (appCatalogResponse) {
            const appCatalog = await this._appCatalogPromise;
            handleAppCatalogResponseResult.applicationsData =
                await appCatalog.filterAppsCatalogData();
            handleAppCatalogResponseResult.isOK = true;
        } else {
            handleAppCatalogResponseResult.authenticationStatus = AuthenticationStatus.Fail;
            handleAppCatalogResponseResult.errorDescription = `app Catalog Promise failed`;
            handleAppCatalogResponseResult.erroCode = -1;
            this._logger.error(`Failed getting appCatalogPromise`);
            return handleAppCatalogResponseResult;
        }
        return handleAppCatalogResponseResult;
    };

    private _sendAuthenticationResultFromAutologin = async () => {
        const biEventHandler = await this._biEventHandlerPromise;
        biEventHandler?.sendAuthenticationResultFromAutologin();
    };

    private _setFeatureABSessionStorage = async (playerSessionId: number): Promise<boolean> => {
        try {
            let featureABData: Array<IABFeatureTest> = null;

            const savedUserFeatureABData = this._localStoreService.get<IUserFeatureEligibilityData>(
                StorageItemEnum.NsdkFeatureAB,
            );

            if (savedUserFeatureABData?.playerSessionId != playerSessionId) {
                const getABActiveFeaturesRequest: IGetABTestActiveFeaturesRequest = {
                    AnonymousPlayerID: this._utils.getAnonymousPlayerID(),
                };

                const { response, errorResponse } = await UserApi.GetABTestActiveFeatures(
                    true,
                    getABActiveFeaturesRequest,
                );

                if (errorResponse) {
                    this._logger.error(
                        `_setFeatureABSessionStorage: Error retrieving a/b data`,
                        errorResponse,
                    );

                    return Promise.reject(errorResponse);
                } else {
                    featureABData = response.aBFeatureTests;
                    this._localStoreService.set<IUserFeatureEligibilityData>(
                        StorageItemEnum.NsdkFeatureAB,
                        { playerSessionId, featureABData },
                    );
                }
            } else featureABData = savedUserFeatureABData?.featureABData;

            this._featureNativeStorage.ABTestFeatures = featureABData;

            this._logger.log('_setFeatureABSessionStorage: initiated eligibility success');
            return Promise.resolve(true);
        } catch (err) {
            this._logger.error('_setFeatureABSessionStorage: initiated eligibility failed', err);
            return false;
        }
    };
}
