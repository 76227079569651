import { Service, Token } from 'typedi';
import { IPostMessageHandler } from '../post-message';
import { Lock } from '../utils/lockUtil';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
const moduleLock = new Lock();

export const PendingHandlerToken = new Token<IPostMessageHandler>('PendingHandler');

@Service()
export class PendingHandlerLoader extends BaseModuleLoader<IPostMessageHandler> {
    constructor() {
        super('PendingHandlerLoader');
    }

    protected _createModuleInstance = async (): Promise<IPostMessageHandler> => {
        const { PendingHandler } = await import(
            /* webpackChunkName: "pending/handler" */
            './pending.handler'
        );

        return new PendingHandler();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IPostMessageHandler> => PendingHandlerToken;
}
