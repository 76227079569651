import Container, { Service, Token } from 'typedi';
import { IWebPushController } from './models/IWebPushController';
import { Lock } from '../utils/lockUtil';
import { DeviceUtils } from '../utils/deviceUtils';
import PageContextManager from 'page-context-manager';
import { FeatureAvailabilityToken } from '../../injection-tokens';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';
import { Brand, Features } from '../../models/enums/Consts';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

export const WebPushToken = new Token<IWebPushController>('WebPush');

const moduleLock = new Lock();

@Service()
export class WebPushLoader extends BaseModuleLoader<IWebPushController> {
    private readonly _deviceUtils: DeviceUtils;
    private _featureAvailabilityService: IFeatureAvailability;

    constructor() {
        super('WebPushLoader');
        this._deviceUtils = Container.get(DeviceUtils);
        this._featureAvailabilityService = Container.get(FeatureAvailabilityToken);
    }

    protected override _canUse = async (): Promise<boolean> => {
        const isNativeApp =
            this._deviceUtils.isCasinoApp() ||
            this._deviceUtils.isPokerApp() ||
            this._deviceUtils.isSportHybridApp();

        const airshipEnabled = this._featureAvailabilityService.IsFeatureEnabled(Features.AIRSHIP);
        const { country } = PageContextManager.getGlobalizationData();
        const { brandId } = PageContextManager.getBrandData();

        const isComGbrEnabled =
            airshipEnabled && brandId === Brand.Sport_Com && country?.toLocaleLowerCase() === 'gbr';

        const isNotComEnabled = airshipEnabled && brandId !== Brand.Sport_Com;

        const isEnabled = !isNativeApp && (isComGbrEnabled || isNotComEnabled);

        return isEnabled;
    };

    protected override _createModuleInstance = async (): Promise<IWebPushController> => {
        const module = await import(
            /* webpackChunkName: "web-push/web-push" */
            './web.push.controller'
        );

        return new module.WebPushController();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IWebPushController> => WebPushToken;
}
