import { Container, Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import {
    AreaType,
    IChannel,
    INavigationChannel,
    ISessionChannel,
    MessageBroker,
    TriggerType,
    IFlowRunnerTopicPayload,
    IPerformActionTopicPayload,
} from '@sparkware/uc-sdk-core';

import { AppIdentifiers, ActionID } from '@sparkware/uc-sdk-core';
import { ClickStreamTrackingProvider } from '../tracking';
import { FlowsRunnerElasticEvents } from './enums/consts';
import { IDeepLink, IDeepLinkAction } from '@sparkware/uc-sdk-core';
import LogLevel from '../tracking/enums/LogLevel';
import { Utils } from '../utils';
import { ElementType, SourceType } from '../app-launcher/enums';
import { LocalSimpleStoreService } from '../storage/implementations/simple-store';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { IActionHandlerFactory } from '../action-handler/models/IActionHandlerFactory';
import PageContextManager from 'page-context-manager';
import { LoaderManager } from '../../loaders/LoaderManager';
import { INativeService } from '../native/models/INativeService';

@Service()
export class FlowsRunner {
    private _utils: Utils;
    private _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private actionsList: IDeepLink;
    private readonly _sessionManagerChannel: ISessionChannel;
    private readonly _navigationChannel: INavigationChannel;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;

    private get _actionHandlerFactoryPromise(): Promise<IActionHandlerFactory> {
        return LoaderManager.Instance.ActionHandlerFactoryLoader.Instance;
    }

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    constructor() {
        this._sessionManagerChannel = MessageBroker.getInstance().session;
        this._navigationChannel = MessageBroker.getInstance().navigation;
        this._utils = Container.get(Utils);
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('FlowsRunner');

        this._navigationChannel.topics.flowRunner.subscribe(this.onIFlowRunnerOperation);
        this.actionsList = {} as IDeepLink;
    }

    public run = async (DeepLinkHash: string): Promise<void> => {
        const data: IDeepLink = this.validateActionData(DeepLinkHash);
        if (data) {
            if (this._utils.findIfIsNative())
                await (
                    await this._nativeServicePromise
                ).NativeStoreReadyDO.promise;

            this.actionsList = data;
            await this.performDeepLinkAction();
        }
    };

    public runDeeplink = async (deepLink: IDeepLink): Promise<void> => {
        this.actionsList = deepLink;
        await this.performDeepLinkAction();
    };

    public runNextAction = () => {
        this.actionsList.actions.shift();
        if (!!this.actionsList.actions.length) {
            // TODO pass correlationID
            this.performDeepLinkAction();
        }
    };

    private async performDeepLinkAction(
        correlationID: string = this._utils.generateCorrelationID(),
    ): Promise<void> {
        const action: IDeepLinkAction = this.actionsList.actions[0];
        const businessCorrelationID = this._utils.generateCorrelationID();
        const channel: IChannel = {
            area: this.actionsList?.area || AreaType.UD,
            source: this.actionsList?.source || SourceType.DeepLink,
            element: this.actionsList?.element || ElementType.UD,
        };

        const openAction: IPerformActionTopicPayload = {
            actionID: action?.actionID,
            correlationID,
            launchInfo: {
                businessCorrelationID,
                channel,
                sourceAppID: AppIdentifiers.UnifiedClient,
                sourceAppVersion: '###UNKNOWN###',
                appSpecificParameters: action?.appSpecificParameters,
                trigger: TriggerType.automaticFlow,
                containerID: undefined,
                clientVersion: PageContextManager.getDeviceData().clientVersion,
            },
            actionData: action?.actionData,
        };

        this._localSimpleStoreService.set(StorageItemEnum.ActionStarted, openAction.actionID);
        this._sessionManagerChannel.topics.actionCompleted.subscribe(() => {
            this._localSimpleStoreService.remove(StorageItemEnum.ActionStarted);
            this.runNextAction();
        });
        this._sessionManagerChannel.topics.actionCancelled.subscribe(() => {
            this._localSimpleStoreService.remove(StorageItemEnum.ActionStarted);
        });

        const actionHandlerFactory = await this._actionHandlerFactoryPromise;
        await actionHandlerFactory.performAction(openAction);
    }

    private validateActionData(deepLinkHash: string) {
        try {
            const data: IDeepLink = JSON.parse(deepLinkHash);
            if (data.actions && data.area) {
                let deepLinkIsValid = true;
                data?.actions?.forEach((action) => {
                    let actionIsValid = ActionID[action?.actionID];
                    if (!actionIsValid) {
                        this.sendEventData(
                            deepLinkHash,
                            LogLevel.Error,
                            `action ${action?.actionID} does not exist in the action list`,
                        );
                        deepLinkIsValid = false;
                        return;
                    }
                });

                if (deepLinkIsValid) {
                    this.sendEventData(deepLinkHash, LogLevel.Information, 'deeplink is valid');
                    return data;
                } else {
                    return null;
                }
            } else {
                this.sendEventData(
                    deepLinkHash,
                    LogLevel.Error,
                    'channel or actions does not exist',
                );
                return null;
            }
        } catch (e) {
            this.sendEventData(deepLinkHash, LogLevel.Error, 'failed to parse deeplink');
            return null;
        }
    }

    private sendEventData(deeplink: string, level: any, details: string) {
        const formatter = this._eventFormatterBuilder.createFormatter('sendEventData');

        const options = {
            level: level,
        };

        const event = formatter.formatUCEvent(
            { message: 'Flows runner - send event data' },
            { correlationID: this._utils.getCorrelationId() },
            { event: FlowsRunnerElasticEvents.DeepLinkValidation, deeplink, details, options },
        );
        this._clickStreamTrackingProvider.sendEventV2(event);
    }

    private onIFlowRunnerOperation = (data: IFlowRunnerTopicPayload) => {
        this.run(data.deepLinkHash);
    };
}
