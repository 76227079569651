import { Container, Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { Features } from '../../models/enums/Consts';
import { IUniqueTabService } from './models/IUniqueTabService';
import { FeatureAvailabilityToken } from '../../injection-tokens';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';

const moduleLock = new Lock();

export const UniqueTabServiceToken = new Token<IUniqueTabService>('UniqueTabService');

@Service()
export class UniqueTabServiceLoader extends BaseModuleLoader<IUniqueTabService> {
    private readonly _featureAvailability: IFeatureAvailability;
    constructor() {
        super('UniqueTabServiceLoader');
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
    }

    protected _createModuleInstance = async (): Promise<IUniqueTabService> => {
        const { UniqueTabService } = await import(
            /* webpackChunkName: "unique-tab/service" */
            './unique-tab.service'
        );

        return new UniqueTabService();
    };

    protected override _canUse: () => Promise<boolean> = async () => {
        return this._featureAvailability.IsFeatureEnabled(Features.SINGLE_TAB_LOGGED_IN);
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IUniqueTabService> => UniqueTabServiceToken;
}
