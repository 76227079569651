import Container, { Service } from 'typedi';

import {
    WebSimpleStorageService,
    WebWindowSimpleStorageService,
} from '../../../../../Modules/Storage/implementations';
import { LocalStorageToken, SessionStorageToken, WindowToken } from '../../../../injection-tokens';
import { ILogger, LoggerProvider } from '../../../logger';
import { ISimpleStoreService } from '../../interfaces/ISimpleStoreService';
import { BaseSimpleStoreService } from './BaseSimpleStoreService';

@Service()
export class SessionSimpleStoreService
    extends BaseSimpleStoreService
    implements ISimpleStoreService
{
    protected _logger: ILogger;

    constructor() {
        const sessionStorageToken = Container.get(SessionStorageToken);
        const webObjectStorageService = new WebSimpleStorageService(sessionStorageToken);

        super(webObjectStorageService);

        this._logger = Container.get(LoggerProvider).getLogger('SessionSimpleStoreService');
    }
}

@Service()
export class LocalSimpleStoreService extends BaseSimpleStoreService implements ISimpleStoreService {
    protected _logger: ILogger;

    constructor() {
        const localStorageToken = Container.get(LocalStorageToken);
        const webObjectStorageService = new WebSimpleStorageService(localStorageToken);

        super(webObjectStorageService);

        this._logger = Container.get(LoggerProvider).getLogger('LocalSimpleStoreService');
    }
}

@Service()
export class WindowSimpleStoreService
    extends BaseSimpleStoreService
    implements ISimpleStoreService
{
    protected _logger: ILogger;

    constructor() {
        const windowToken = Container.get(WindowToken);
        const windowStorageItemService = new WebWindowSimpleStorageService(windowToken);

        super(windowStorageItemService);

        this._logger = Container.get(LoggerProvider).getLogger('WindowSimpleStoreService');
    }
}
