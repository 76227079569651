import API, { ApiResponse } from 'api';
import { AxiosRequestConfig } from 'axios';
import { HTTPVerb } from '../Modules/Utils/API/Constants/HTTPVerb';
import { AuthenticatedPageContextData } from 'page-context-manager';
import { IInitialStateData } from '@sparkware/uc-sdk-core';

enum APIConstants {
    GetContextDataStore = '/api/context/store/',
    GetAuthenticatedContextData = '/api/context/authenticated/',
    GetStoreState = '/api/context/store-state/',
}

export default class ContextApi {
    public static GetContextDataStore = async (): Promise<ApiResponse<any>> => {
        const requestOptions: AxiosRequestConfig = {
            url: APIConstants.GetContextDataStore,
            method: HTTPVerb.GET,
        };

        return API.request(requestOptions);
    };

    public static GetAuthenticatedContextData = async (): Promise<
        ApiResponse<AuthenticatedPageContextData>
    > => {
        const requestOptions: AxiosRequestConfig = {
            url: APIConstants.GetAuthenticatedContextData,
            method: HTTPVerb.GET,
        };

        return API.authenticatedRequest(requestOptions);
    };

    public static GetStoreState = async (): Promise<ApiResponse<IInitialStateData>> => {
        const requestOptions: AxiosRequestConfig = {
            url: APIConstants.GetStoreState,
            method: HTTPVerb.GET,
        };

        return API.authenticatedRequest(requestOptions);
    };
}
