import PageContextManager from 'page-context-manager';
import { ClientIntegrationFacade } from './client-integration.facade';
import { IPlayerSegmentationData } from '@sparkware/uc-sdk-core';

export class PokerClientIntegrationFacade extends ClientIntegrationFacade {
    constructor() {
        super();
        this._readyToConnectDO.resolve(true);
    }

    public getPlayerSegmentation = (): IPlayerSegmentationData => {
        const clientLangIso3 = PageContextManager.getLocalizationData().clientLangIso3;

        return {
            ...this.getPlayerSegmentationInitial(),
            languageCode: clientLangIso3,
        };
    };
}
