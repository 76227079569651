import { Service, Token } from 'typedi';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import { IAppLauncher } from './models/IAppLauncher';
import { Lock } from '../utils/lockUtil';
import PageContextManager from 'page-context-manager';
import { SDKType } from '../../models/enums/Consts';

const moduleLock = new Lock();

export const AppLauncherToken = new Token<IAppLauncher>('AppLauncher');
@Service()
export class AppLauncherLoader extends BaseModuleLoader<IAppLauncher> {
    constructor() {
        super('AppLauncherLoader');
    }

    protected _createModuleInstance = async (): Promise<IAppLauncher> => {
        const { sdkType } = PageContextManager.getSiteData();

        switch (sdkType) {
            case SDKType.AppWrapper:
            case SDKType.NativeSDK: {
                const { AppLauncherNsdk } = await import(
                    /* webpackChunkName: "action/app-launcher/nsdk" */
                    './app-launcher.nsdk'
                );

                return new AppLauncherNsdk();
            }
            case SDKType.Web: {
                const { AppLauncher } = await import(
                    /* webpackChunkName: "action/app-launcher/web" */
                    './app-launcher'
                );

                return new AppLauncher();
            }
        }

        return null;
    };

    protected _canUse: () => Promise<boolean> = async () => {
        const { sdkType } = PageContextManager.getSiteData();

        return [SDKType.AppWrapper, SDKType.NativeSDK, SDKType.Web].some((sdk) => sdk === sdkType);
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IAppLauncher> => AppLauncherToken;
}
