export default {
    cookie: {
        Name: 'envAccessAuth',
        ValidForDays: 14,
    },
    API: {
        Endpoint: '/api/env-access-auth/checkPassword/',
    },
    protectedEnvironments: [],
};
