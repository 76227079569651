import API from 'api';
import { HTTPVerb } from '../Modules/Utils/API/Constants/HTTPVerb';

enum APIConstants {
    ValidateSecurityCode = '/api/identity/ValidateSecurityCode/',
    GetNetAcuityData = '/api/identity/GetNetAcuityData/',
    GetDeviceInfo = '/api/identity/GetDeviceInfo/',
}

export default class IdentityApi {
    public static ValidateToken = async (data: any): Promise<any> => {
        const { response } = await API.request({
            url: APIConstants.ValidateSecurityCode,
            method: HTTPVerb.POST,
            data,
        });

        return response;
    };

    public static GetNetAcuityData = async (): Promise<any> => {
        const { response } = await API.request({
            url: APIConstants.GetNetAcuityData,
            method: HTTPVerb.GET,
        });

        return response;
    };

    public static GetDeviceInfo = async (): Promise<any> => {
        const { response } = await API.request({
            url: APIConstants.GetDeviceInfo,
            method: HTTPVerb.GET,
        });

        return response;
    };
}
