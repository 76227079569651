import { Container, Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { IActionHandlerFactory } from './models/IActionHandlerFactory';
import { DeviceUtils } from '../utils/deviceUtils';
import { WindowToken } from '../../injection-tokens';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import PageContextManager from 'page-context-manager';
import { SDKType } from '../../models/enums/Consts';

const moduleLock = new Lock();

export const ActionHandlerFactoryToken = new Token<IActionHandlerFactory>('ActionHandlerFactory');

@Service()
export class ActionHandlerFactoryLoader extends BaseModuleLoader<IActionHandlerFactory> {
    private readonly _deviceUtils: DeviceUtils;
    private readonly _window: Window;

    constructor() {
        super('ActionHandlerFactoryLoader');

        this._deviceUtils = Container.get(DeviceUtils);
        this._window = Container.get(WindowToken);
    }

    private _isPokerAppWithInterface = () => {
        return this._deviceUtils.isPokerApp() && !!this._window['pokerNativeInterface'];
    };

    protected _createModuleInstance = async (): Promise<IActionHandlerFactory> => {
        const { sdkType } = PageContextManager.getSiteData();
        const isPokerApp = this._isPokerAppWithInterface();

        switch (sdkType) {
            case SDKType.AppWrapper: {
                const { ActionHandlerFactoryAppWrapper } = await import(
                    /* webpackChunkName: "action/handler-factory/app-wrapper" */
                    './action-handler-factory-app-wrapper'
                );

                return new ActionHandlerFactoryAppWrapper();
            }
            case SDKType.NativeSDK: {
                const { ActionHandlerFactoryNativeSDK } = await import(
                    /* webpackChunkName: "action/handler-factory/native-sdk" */
                    './action-handler-factory-native-sdk'
                );

                return new ActionHandlerFactoryNativeSDK();
            }
            case SDKType.Web: {
                const { ActionHandlerFactory } = await import(
                    /* webpackChunkName: "action/handler-factory/web" */
                    './action-handler-factory'
                );
                return new ActionHandlerFactory();
            }
            default: {
                if (isPokerApp) {
                    const { ActionHandlerFactoryPoker } = await import(
                        /* webpackChunkName: "action/handler-factory/poker" */
                        './action-handler-factory-poker'
                    );

                    return new ActionHandlerFactoryPoker();
                }

                return null;
            }
        }
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IActionHandlerFactory> => ActionHandlerFactoryToken;
}
