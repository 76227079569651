import { Brand, ClientProvider } from '../../src/models/enums/Consts';
import PageContextManager from 'page-context-manager';

export class BrandUtils {
    public static isPokerClient = (): boolean =>
        PageContextManager.getClientProviderData()?.name === ClientProvider.Poker;

    public static isKambi = (): boolean =>
        PageContextManager.getClientProviderData()?.name === ClientProvider.Kambi;

    public static isSpectate = (): boolean =>
        PageContextManager.getClientProviderData()?.name === ClientProvider.Spectate;

    public static isPIS = (): boolean => {
        const brandId = PageContextManager.getBrandData().brandId;
        return this.isPokerClient() && brandId === Brand.Sport_Com;
    };

    public static isPIC = (): boolean => {
        const brandId = PageContextManager.getBrandData().brandId;
        return this.isPokerClient() && brandId === Brand.Sport_Scasino;
    };
}
