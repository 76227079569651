import { Service, Token } from 'typedi';
import { IPostMessageHandler } from '../post-message';
import { Lock } from '../utils/lockUtil';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const RegistrationHandlerToken = new Token<IPostMessageHandler>('RegistrationHandler');

@Service()
export class RegistrationHandlerLoader extends BaseModuleLoader<IPostMessageHandler> {
    constructor() {
        super('RegistrationHandlerLoader');
    }

    protected _createModuleInstance = async (): Promise<IPostMessageHandler> => {
        const { RegistrationHandler } = await import(
            /* webpackChunkName: "registration/handler" */
            './registration.handler'
        );

        return new RegistrationHandler();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IPostMessageHandler> => RegistrationHandlerToken;
}
