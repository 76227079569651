import { Service, Token } from 'typedi';
import { IAuthenticationFlowFactory } from './models/';
import { Lock } from './../utils/lockUtil';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const AuthenticationFlowFactoryToken = new Token<IAuthenticationFlowFactory>(
    'AuthenticationFlowFactory',
);

@Service()
export class AuthenticationFlowFactoryLoader extends BaseModuleLoader<IAuthenticationFlowFactory> {
    constructor() {
        super('AuthenticationFlowFactoryLoader');
    }

    protected _createModuleInstance = async (): Promise<IAuthenticationFlowFactory> => {
        const { AuthenticationFlowFactory } = await import(
            /* webpackChunkName: "authentication/flow-factory" */
            './authentication-flow-factory'
        );

        return new AuthenticationFlowFactory();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IAuthenticationFlowFactory> =>
        AuthenticationFlowFactoryToken;
}
