import { Container, Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { IGeolocation } from './models/IGeolocation';
import { UserContextToken } from '../../injection-tokens';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import { IUserContext } from '../user-context/user-context-interface';
import PageContextManager from 'page-context-manager';

const moduleLock = new Lock();

export const GeolocationToken = new Token<IGeolocation>('Geolocation');
@Service()
export class GeolocationLoader extends BaseModuleLoader<IGeolocation> {
    private readonly _userContext: IUserContext;
    constructor() {
        super('GeolocationLoader');
        this._userContext = Container.get(UserContextToken);
    }

    protected _createModuleInstance = async (): Promise<IGeolocation> => {
        const module = await import(
            /* webpackChunkName: "geolocation/geolocation" */
            './geolocation'
        );
        return new module.Geolocation();
    };

    protected override _canUse = async (): Promise<boolean> => {
        const { geolocationEnabled } = PageContextManager.getRegulationData();
        return geolocationEnabled;
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IGeolocation> => GeolocationToken;
}
