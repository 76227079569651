export enum NavigationResultCode {
    ContentPageNotFound = 1,
}

export enum NavigationResultDesciption {
    ContentPageNotFound = 'Navigation to content page returned 404 - not found',
}

export interface IControlCenterOptions {
    deeplink?: number;
    clientPlatform?: number;
    source?: string;
}
