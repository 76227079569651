import { Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { IPlayer } from './models';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const PlayerToken = new Token<IPlayer>('Player');

@Service()
export class PlayerLoader extends BaseModuleLoader<IPlayer> {
    constructor() {
        super('PlayerLoader');
    }

    protected _createModuleInstance = async (): Promise<IPlayer> => {
        const { Player } = await import(
            /* webpackChunkName: "player/player" */
            './player'
        );

        return new Player();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IPlayer> => PlayerToken;
}
