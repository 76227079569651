import { RestrictionActions, RestrictionEntity, RestrictionEntityValues } from '../enums';

export class GeolocationMessagesUtils {
    // TODO: this might not be the correct place to put this method (try geolocation.ts)
    public static isRestricted(message: any): boolean {
        const resrictionsOnPlay = message?.RestrictionsByAction?.find(
            (restriction) => restriction?.ActionId == RestrictionActions.Play,
        );
        const restrictionsonGameType = resrictionsOnPlay?.Entities?.find(
            (restriction) => restriction?.EntityId == RestrictionEntity.GameType,
        );
        const foundRestriction = restrictionsonGameType?.Values.find(
            (restriction) =>
                restriction?.Value == RestrictionEntityValues.All ||
                restriction?.Value == RestrictionEntityValues.Spectate,
        );

        return foundRestriction;
    }
}
