import { Service, Token } from 'typedi';
import { ICMSContentLoader } from '../models/ICMSContentLoader';
import { BaseModuleLoader } from '../../../loaders/BaseModuleLoader';
import { Lock } from '../../utils/lockUtil';

const moduleLock = new Lock();

export const CMSContentLoaderToken = new Token<ICMSContentLoader>('CMSContentLoader');

@Service()
export class CMSContentLoaderLoader extends BaseModuleLoader<ICMSContentLoader> {
    constructor() {
        super('CMSContentLoaderLoader');
    }

    protected _createModuleInstance = async (): Promise<ICMSContentLoader> => {
        const { CMSContentLoader } = await import(
            /* webpackChunkName: "view-injector/content-loader" */
            './CMSContentLoader'
        );

        return new CMSContentLoader();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<ICMSContentLoader> => CMSContentLoaderToken;
}
