import Container from 'typedi';
import { StorageItemEnum } from '../../../models/enums/storage-enums';
import { IGeolocationData } from '../../authentication/models';
import { LocalSimpleStoreService } from '../../storage/implementations/simple-store';

export class GeolocationUtils {
    public static setGeolocationData(data: IGeolocationData): void {
        if (!data) return;

        const localSimpleStoreService = Container.get(LocalSimpleStoreService);
        localSimpleStoreService.set(StorageItemEnum.GeolocationData, JSON.stringify(data));
    }
}
