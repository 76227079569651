import API, { ApiResponse } from 'api';
import { HTTPVerb } from '../Modules/Utils/API/Constants/HTTPVerb';
import {
    IEligibilityResponseWrapper,
    IUnifiedCashierEligibilityResponse,
} from '../src/modules/feature/feature-eligibility/interfaces';
import {
    IABFeatureTest,
    IGetABTestActiveFeaturesResponse,
} from '../src/modules/feature/feature-availability/interfaces';

enum APIConstants {
    GetEligibility = '/api/user/eligibility/',
    GetCurrentBalance = '/api/user/getCurrentBalance/',
    GetABTestActiveFeaturesAuthenticated = '/api/user/abtest-active-features-authenticated/',
    GetABTestActiveFeaturesAnonymous = '/api/user/abtest-active-features-anonymous/',
    GetPlayerWinLoss = 'api/user/player-win-loss/',
    RegulationLoginMessage = 'api/user/regulation-login-message/',
}

export interface RegulationLoginMessageLimit {
    Type: number;
    Amount: number;
}

export interface RegulationLoginMessageResponse {
    DepositLimits: RegulationLoginMessageLimit[];
    TotalNetLoss: number;
}

export default class UserApi {
    public static GetEligibility = async (
        params,
    ): Promise<ApiResponse<IEligibilityResponseWrapper<IUnifiedCashierEligibilityResponse>>> =>
        API.authenticatedRequest({
            url: APIConstants.GetEligibility,
            method: HTTPVerb.GET,
            params,
        });

    public static GetABTestActiveFeatures = async (
        authenticated: boolean,
        params?,
    ): Promise<ApiResponse<IGetABTestActiveFeaturesResponse>> => {
        const requestOptions = {
            url: authenticated
                ? APIConstants.GetABTestActiveFeaturesAuthenticated
                : APIConstants.GetABTestActiveFeaturesAnonymous,
            method: HTTPVerb.GET,
            enableCaching: !authenticated,
            params,
        };

        const apiMethod = authenticated ? API.authenticatedRequest : API.request;

        return apiMethod(requestOptions);
    };

    public static GetCurrentBalance = async (): Promise<ApiResponse> =>
        API.authenticatedRequest({
            url: APIConstants.GetCurrentBalance,
            method: HTTPVerb.GET,
        });

    public static GetPlayerWinLoss = async (): Promise<ApiResponse> =>
        API.authenticatedRequest({
            url: APIConstants.GetPlayerWinLoss,
            method: HTTPVerb.GET,
        });

    public static RegulationLoginMessage = async (): Promise<
        ApiResponse<RegulationLoginMessageResponse>
    > =>
        API.authenticatedRequest({
            url: APIConstants.RegulationLoginMessage,
            method: HTTPVerb.GET,
        });
}
