import { ObjectStorageData } from '../../../../../Modules/Storage/interfaces';
import { IDataStoreService, IStoreService } from '../../interfaces';

export abstract class BaseDataStoreService<
    T extends U extends any ? ObjectStorageData<T> : unknown,
    U = any,
> implements IDataStoreService<T, U>
{
    protected readonly _storeService: IStoreService;
    protected readonly _storeName: string;

    constructor(storeService: IStoreService, storeName: string) {
        this._storeService = storeService;
        this._storeName = storeName;
    }

    public set = (data: (T | Partial<T>) & ObjectStorageData<T>) => {
        this._storeService.set<T>(this._storeName, data);
    };

    public get = (): T => {
        return this._storeService.get<T>(this._storeName);
    };

    public pick = (keys: Array<keyof T & string>): Partial<T> => {
        return this._storeService.pick(this._storeName, keys);
    };

    public remove = () => {
        this._storeService.remove(this._storeName);
    };
}
