import Container, { Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import { IGeolocationClickStreamEventData, IGeolocationMeasurementSender } from '../models';
import { PerformanceMarks, PerformanceMeasures } from '../../performance/enums/consts';

import { ClickStreamTrackingProvider } from '../../tracking';
import { GeolocationElasticEvents } from '../enums';
import { PerformanceManager } from '../../performance/performance-manager';

@Service()
export class GeolocationMeasurement {
    private readonly _performanceManager: PerformanceManager;
    private readonly _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;

    private _correlationID: string;
    private _perfMarkLogin: string;
    private _perfMarkLoginWithPlugin: string;
    private _perfMarkRetry: string;
    private _perfMarkInterval: string;
    private _perfMeasureLogin: string;
    private _perfMeasureLoginWithPlugin: string;
    private _perfMeasureRetry: string;
    private _perfMeasureInterval: string;

    public constructor() {
        this._performanceManager = Container.get(PerformanceManager);
        const efBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder = efBuilderFactory.createEventFormatterBuilder('geolocation');
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
    }

    public GetGeolocationMeasurer(correlationId: string): IGeolocationMeasurementSender {
        if (!correlationId) {
            throw 'CorrelationId is required for geolocation measurement!';
        }

        this.InitPerformanceMeasurement(correlationId);

        return {
            start: {
                login: (): void => {
                    this._performanceManager.startWatch(this._perfMarkLogin);
                },
                loginWithPlugin: (): void => {
                    this._performanceManager.startWatch(this._perfMarkLoginWithPlugin);
                },
                retry: (): void => {
                    this._performanceManager.startWatch(this._perfMarkRetry);
                },
                interval: (): void => {
                    this._performanceManager.startWatch(this._perfMarkInterval);
                },
            },
            clear: {
                login: (): void => {
                    this._performanceManager.clearWatches([this._perfMarkLogin]);
                },
                loginWithPlugin: (): void => {
                    this._performanceManager.clearWatches([this._perfMarkLoginWithPlugin]);
                },
                retry: (): void => {
                    this._performanceManager.clearWatches([this._perfMarkRetry]);
                },
                interval: (): void => {
                    this._performanceManager.clearWatches([this._perfMarkInterval]);
                },
            },
            removeAll: (): void => {
                this.removeMeasurementMarks();
            },
            send: (foundRestriction: boolean): void => {
                this.sendPerformanceMeasurements(foundRestriction);
            },
        };
    }

    private InitPerformanceMeasurement = (correlationId: string): void => {
        this._correlationID = correlationId;

        this._perfMarkLogin = `${PerformanceMarks.GeolocationLoginStart}-${correlationId}`;
        this._perfMarkLoginWithPlugin = `${PerformanceMarks.GeolocationLoginWithPluginStart}-${correlationId}`;
        this._perfMarkRetry = `${PerformanceMarks.GeolocationRetryStart}-${correlationId}`;
        this._perfMarkInterval = `${PerformanceMarks.GeolocationIntervalStart}-${correlationId}`;

        this._perfMeasureLogin = `${PerformanceMeasures.GeolocationLogin}-${correlationId}`;
        this._perfMeasureLoginWithPlugin = `${PerformanceMeasures.GeolocationLoginWithPlugin}-${correlationId}`;
        this._perfMeasureRetry = `${PerformanceMeasures.GeolocationRetry}-${correlationId}`;
        this._perfMeasureInterval = `${PerformanceMeasures.GeolocationInterval}-${correlationId}`;
    };

    private sendPerformanceMeasurements = (foundRestriction: boolean): void => {
        const geolocationDetails = `User is ${foundRestriction ? 'restricted' : 'permitted'}`;
        const eventsData = this.getPerformanceMeasurements(geolocationDetails);

        eventsData.forEach((eventData: any) => {
            if (eventData) {
                this._clickStreamTrackingProvider.sendEventV2(eventData);
            }
        });

        this.removeMeasurementMarks();
    };

    private getPerformanceMeasurements = (geolocationDetails: string): Array<any> => {
        return [
            this.getPerformanceMeasurementEventData(
                this._perfMarkLogin,
                this._perfMeasureLogin,
                GeolocationElasticEvents.GL_Performance_Login,
                geolocationDetails,
            ),
            this.getPerformanceMeasurementEventData(
                this._perfMarkLoginWithPlugin,
                this._perfMeasureLoginWithPlugin,
                GeolocationElasticEvents.GL_Performance_Login_With_Plugin,
                geolocationDetails,
            ),
            this.getPerformanceMeasurementEventData(
                this._perfMarkRetry,
                this._perfMeasureRetry,
                GeolocationElasticEvents.GL_Performance_Retry,
                geolocationDetails,
            ),
            this.getPerformanceMeasurementEventData(
                this._perfMarkInterval,
                this._perfMeasureInterval,
                GeolocationElasticEvents.GL_Performance_Interval,
                geolocationDetails,
            ),
        ];
    };

    private getPerformanceMeasurementEventData = (
        markName: string,
        measureName: string,
        eventName: GeolocationElasticEvents,
        geolocationDetails: string,
    ): any => {
        if (markName && measureName && eventName) {
            if (this._performanceManager.isStartedWatch(markName)) {
                const duration = this._performanceManager.getDuration(measureName, markName);

                if (duration) {
                    const formatter = this._eventFormatterBuilder.createFormatter(
                        'sendPerformanceMeasurements',
                    );
                    const eventData = { event: eventName } as IGeolocationClickStreamEventData;
                    return formatter.formatUCEvent(
                        { message: geolocationDetails, durationInMS: duration },
                        { correlationID: this._correlationID },
                        { ...eventData },
                    );
                }
            }
        }

        return null;
    };

    private removeMeasurementMarks() {
        // Clear measurement marks
        this._performanceManager.clearWatches([
            this._perfMarkLogin,
            this._perfMarkLoginWithPlugin,
            this._perfMarkInterval,
            this._perfMarkRetry,
        ]);
    }
}
