import Container, { Service } from 'typedi';

import { UrlUtils } from '../utils/urlUtils';
import PageContextManager from 'page-context-manager';

@Service()
export class FeatureApplicationCapability {
    private _urlUtils: UrlUtils;
    constructor() {
        this._urlUtils = Container.get(UrlUtils);
    }

    public IsAppVersionHigherThan = (minimumNativeAppVersion: number): boolean => {
        const isHybrid = PageContextManager.getDeviceData().isHybrid;

        const nativeUXVersion = this._urlUtils.getNativeUXVersion();

        if (!isHybrid) return true;

        return minimumNativeAppVersion <= nativeUXVersion;
    };
}
