import Container, { Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { IBIEventHandler } from './models/IBIEventHandler';
import { Utils } from '../utils';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
const moduleLock = new Lock();

export const BIEventHandlerToken = new Token<IBIEventHandler>('BIEventHandler');

@Service()
export class BIEventHandlerLoader extends BaseModuleLoader<IBIEventHandler> {
    private readonly _utils: Utils;
    constructor() {
        super('BIEventHandlerLoader');

        this._utils = Container.get(Utils);
    }

    protected _createModuleInstance = async (): Promise<IBIEventHandler> => {
        const { BIEventHandler } = await import(
            /* webpackChunkName: "bi/event-handler" */
            './bi-event-handler'
        );

        return new BIEventHandler();
    };

    protected override _canUse: () => Promise<boolean> = async () => {
        return !this._utils.isAppWrapper();
    };
    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IBIEventHandler> => BIEventHandlerToken;
}
