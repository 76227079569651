export enum MessageType {
    onLoad = 'onload',
    init = 'init',
    initAnonymous = 'initAnonymous',
    initResult = 'initresult',
    playerAppsState = 'playerappsstate',
    performAction = 'performAction',
    openApp = 'openApp',
    createAppContainer = 'createAppContainer',
    showError = 'showError',
    appClosed = 'appClosed',
    appClosing = 'appClosing',
    removeAppContainer = 'removeAppContainer',
    updateContext = 'updateContext',
    chatActivity = 'chatActivity',
    toggleAppContainer = 'toggleAppContainer',
    actionInterrupted = 'actionInterrupted',
}

export enum AuthenticationStatus {
    Success = 'success',
    Fail = 'fail',
}

export enum NativeInterfaces {
    PokerNativeInterface = 'PokerNativeInterface',
}

export enum UrlSites {
    PokerNativeUrl = 'https://poker-native-sdk.com/',
}
