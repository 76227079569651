import Container from 'typedi';
import { HTTPVerb } from '../Modules/Utils/API/Constants/HTTPVerb';
import { IEvent } from '@unified-client/event-formatter';
import { LoggerProvider } from '../src/modules/logger';
import { NonServiceInstance } from 'api';
import PageContextManager from 'page-context-manager';

export default class ClickStreamApi {
    public static SendReport = async <T>(data: IEvent<T, 'UCEvent' | string>): Promise<void> => {
        const logger = Container.get(LoggerProvider).getLogger('ClickStreamApi');
        const clickStreamUrl = PageContextManager.getUrlResourceData().clickStreamUrl;

        if (!clickStreamUrl) {
            logger.warn('Clickstream URL is missing...');
            return;
        }

        const formattedData = JSON.stringify(data);

        const { response } = await NonServiceInstance.request({
            url: clickStreamUrl,
            method: HTTPVerb.POST,
            data: formattedData,
            withCredentials: false,
            headers: { 'content-type': 'application/json' },
        });

        if (response) {
            logger.debug(`The following event was successfully sent: ${formattedData}`);
        }
    };
}
