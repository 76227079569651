import { BiometricsFlowType, BiometricsStoreItem } from './enums/consts';
import Container, { Service } from 'typedi';

import { MessageBroker, NativeChannel, SessionChannel } from '@sparkware/uc-sdk-core';
import { WebLoginService } from '../web-login/web.login.service';
import { FIT_TO_PLAY_BRANDS } from '../../models/enums/Consts';
import { LocalSimpleStoreService } from '../storage/implementations/simple-store';
import { TypeUtils } from '../../../Modules/Utils/TypeUtils';
import PageContextManager from 'page-context-manager';
import { INativeService } from '../native/models/INativeService';
import { IBiometricsFlowSettings } from './models';
import { LoaderManager } from '../../loaders/LoaderManager';

@Service()
export class BiometricsUtils {
    private readonly _nativeChannel: NativeChannel;
    private readonly _sessionChannel: SessionChannel;
    private readonly _localStorageItemService: LocalSimpleStoreService;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    constructor() {
        this._nativeChannel = MessageBroker.getInstance().native;
        this._sessionChannel = MessageBroker.getInstance().session;
        this._localStorageItemService = Container.get(LocalSimpleStoreService);
    }

    public FindIfIsEnrolled = (): boolean => {
        const isEnrolled = this._localStorageItemService.get(BiometricsStoreItem.IsEnrolled);

        return TypeUtils.toBoolean(isEnrolled);
    };

    public FindIfBiometricsFlowIsEnabled = async () => {
        const { isBiometricsNewFlowEnabled, isTouchIdCanBeUsed } =
            await this.GetBiometricsFlowSettings();

        return isBiometricsNewFlowEnabled || isTouchIdCanBeUsed;
    };

    public FindIfBiometricsNewFlowIsEnabled = async () => {
        const { isBiometricsNewFlowEnabled } = await this.GetBiometricsFlowSettings();

        return isBiometricsNewFlowEnabled;
    };

    public FindIfIsTouchIdCanBeUsed = async () => {
        const { isTouchIdCanBeUsed } = await this.GetBiometricsFlowSettings();

        return isTouchIdCanBeUsed;
    };

    public GetBiometricsFlowSettings = async (): Promise<IBiometricsFlowSettings> => {
        let biometricsFlowSettings = {
            biometricsFlowType: null,
            isBiometricsFlowEnabled: false,
            isBiometricsNewFlowEnabled: false,
            isTouchIdCanBeUsed: false,
        };

        const nativeService = await this._nativeServicePromise;
        if (nativeService) {
            const { isBiometricsNewFlowEnabled, isTouchIdCanBeUsed } =
                nativeService.getBiometricsFlowSettings();
            biometricsFlowSettings.isBiometricsFlowEnabled =
                isBiometricsNewFlowEnabled || isTouchIdCanBeUsed;
            biometricsFlowSettings.isBiometricsNewFlowEnabled = isBiometricsNewFlowEnabled;
            biometricsFlowSettings.isTouchIdCanBeUsed = isTouchIdCanBeUsed;

            biometricsFlowSettings.biometricsFlowType =
                biometricsFlowSettings.isBiometricsFlowEnabled
                    ? isBiometricsNewFlowEnabled
                        ? BiometricsFlowType.Biometrics
                        : BiometricsFlowType.TouchId
                    : null;
        }
        return biometricsFlowSettings;
    };

    public IsFitToPlay = async (): Promise<boolean> => {
        return new Promise<boolean>(async (resolve) => {
            const brandId = PageContextManager.getBrandData().brandId;
            const isFitToPlayEnabled = FIT_TO_PLAY_BRANDS.some((brand) => brand === brandId);
            const { isBiometricsNewFlowEnabled } = await this.GetBiometricsFlowSettings();
            const isEnrolled = this.FindIfIsEnrolled();
            if (!(isFitToPlayEnabled && isBiometricsNewFlowEnabled && isEnrolled)) {
                return resolve(true);
            }
            const userIsFitToPlaySub = this._nativeChannel.topics.userIsFitToPlay.subscribe(() => {
                resolve(true);
                userIsFitToPlaySub.unsubscribe();
            });
            const webLoginClosedSub = this._sessionChannel.topics.webLoginClosed.subscribe(() => {
                resolve(false);
                webLoginClosedSub.unsubscribe();
            });
            const webLoginService = Container.get(WebLoginService);
            webLoginService.ShowFitToPlay();
        });
    };
}
