import { Service, Token } from 'typedi';
import { IPostLogin } from './models/IPostLogin';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import { Lock } from '../utils/lockUtil';

const moduleLock = new Lock();

export const PostLoginToken = new Token<IPostLogin>('PostLogin');

@Service()
export class PostLoginLoader extends BaseModuleLoader<IPostLogin> {
    constructor() {
        super('PostLoginLoader');
    }

    protected _createModuleInstance = async (): Promise<IPostLogin> => {
        const { PostLogin } = await import(
            /* webpackChunkName: "post-login/root" */
            './post-login'
        );

        return new PostLogin();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IPostLogin> => PostLoginToken;
}
