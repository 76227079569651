import { Container, Service } from 'typedi';
import { ILogger, LoggerProvider } from '../logger';
import { UIService } from '../ui';
import { CookieStoreItemService } from '../storage/implementations';
import EnvAccessAuthenticationConsts from './constants/EnvAccessAuthenticationConsts';
import DialogType from '../ui/enums/DialogType';
import EnvAccessAuthenticationApi from '../../../APIs/EnvAccessAuthenticationApi';

@Service()
export class EnvAccessAuthentication {
    private _logger: ILogger;
    protected readonly _uiService: UIService;
    private readonly _cookieStoreItemService: CookieStoreItemService;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('EnvAccessAuthentication');
        this._cookieStoreItemService = Container.get(CookieStoreItemService);
        this._uiService = Container.get(UIService);
    }

    public init = async () => {
        //if cookie exists, password has beein already checked recently
        const hasCookie =
            this._cookieStoreItemService.get(EnvAccessAuthenticationConsts.cookie.Name) !=
            undefined;

        // init is being called only during correct(password enabled) environments == true;
        const isEnvAuthenticationEnabled = true;

        const needsPasswordProtection = isEnvAuthenticationEnabled && !hasCookie;

        if (needsPasswordProtection) this.registerEnvAccessAuthentication();
    };

    checkPassword = async (password: string): Promise<boolean> => {
        //1) Do API Call.
        //2) if Failed: return false;
        //3) if true: 3.1) set cookie
        //4) return API response true/false

        const { response, errorResponse } = await EnvAccessAuthenticationApi.CheckPassword({
            password: password,
        });

        if (errorResponse) {
            this._logger.error(
                'Env Access Auth - failed API Call.\n Error Response:',
                errorResponse,
            );
            return false;
        }

        this._logger.info(`Env Access Auth - ${response}`);

        if (response == true) {
            this.setEnvAuthCookie();
        }

        return response;
    };

    private async registerEnvAccessAuthentication() {
        this._uiService.handleDialog({ dialogType: DialogType.EnvAccessAuthentication });
    }

    setEnvAuthCookie = async () => {
        this._cookieStoreItemService.set(EnvAccessAuthenticationConsts.cookie.Name, ' ', {
            expires: EnvAccessAuthenticationConsts.cookie.ValidForDays,
        });
    };
}
