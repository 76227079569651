import Container from 'typedi';
import StringHelper from '../../../Modules/Utils/StringUtils';
import { Utils } from '../utils';
import { INativeService } from './models/INativeService';
import { LoaderManager } from '../../loaders/LoaderManager';
import { IMessagesHandler } from '../push/models/IMessagesHandler';
import NativeApi from '../../../APIs/NativeApi';
import { IDialogTopicPayload, MessageBroker } from '@sparkware/uc-sdk-core';
import DialogType from '../ui/enums/DialogType';
import DataStoreHelper from '../../../Modules/Utils/DataStoreHelper';
import { FeatureAvailabilityToken } from '../../injection-tokens';
import { Features } from '../../models/enums/Consts';

export class NativeUtils {
    private static get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    private static get _messageHandlerPromise(): Promise<IMessagesHandler> {
        return LoaderManager.Instance.MessagesHandlerLoader.Instance;
    }

    public static onNativeReady = async (osName: string) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            if (osName != null && osName != '') {
                nativeService.init(osName);
                nativeService.onNativeReady();
            }
        }
    };

    public static onDisplayWebpageComplete = async () => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.onDisplayWebpageComplete();
        }
    };

    public static onGetInfoComplete = async (
        deviceType,
        os,
        udid,
        language,
        nativeClientVersion,
        appName,
        productPackage,
        adxDeviceID,
        adxMCD,
    ) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.onGetInfoComplete(
                deviceType,
                os,
                udid,
                language,
                nativeClientVersion,
                appName,
                productPackage,
                adxDeviceID,
                adxMCD,
            );
        }
    };

    public static onShowTouchId = async (res) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.onShowTouchId(res);
        }
    };

    public static onGetCid = async (res) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.onGetCid(res);
        }
    };

    public static onLocationResponse = async (res) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.onLocationResponse(res);
        }
    };

    public static onLocationPermissionResponse = async (res) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.onLocationPermissionResponse(res);
        }
    };

    public static onGeoLocationResponseSuccess = async (res) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.onGeoLocationResponseSuccess(res);
        }
    };

    public static onGeoLocationResponseError = async (res) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.onGeoLocationResponseError(res);
        }
    };

    public static onBackToForeground = async () => {
        const messagesHandler = await LoaderManager.Instance.MessagesHandlerLoader.Instance;
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            messagesHandler.addUpdateRestrictionHandler();
            nativeService.onBackToForeground();
        }
    };

    public static appInBackground = async () => {
        const messagesHandler = await NativeUtils._messageHandlerPromise;
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            messagesHandler.removeUpdateRestrictionHandler();
            nativeService.appInBackground();
        }
    };

    public static openExternalUrl = async (url: string) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        const utils = Container.get(Utils);
        if (nativeService) {
            if (typeof WrapperInterface !== 'undefined') WrapperInterface.openExternalLink(url);
            else if (nativeService.isiOS) {
                nativeService.openExternalLink(url);
            }
        } else utils.openWindow(url);
    };

    //TO DO - check if this is still used
    public static isMissingInfo = (deviceInfo) => {
        return (
            StringHelper.isNullOrWhiteSpaces(deviceInfo.BundleID) ||
            StringHelper.isNullOrWhiteSpaces(deviceInfo.AFInstallData) ||
            StringHelper.isNullOrWhiteSpaces(deviceInfo.AFUDID) ||
            (StringHelper.isNullOrWhiteSpaces(deviceInfo.AFInstallData) &&
                (StringHelper.isNullOrWhiteSpaces(deviceInfo.ADXMCD) ||
                    StringHelper.isNullOrWhiteSpaces(deviceInfo.ADXDeviceID)))
        ); // if AppsFlyer is not presents then check AD-X
    };

    //TO DO - check if this is still used
    public static getInfo = async () => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.getInfo();
        }
    };

    //TO DO - check if this is still used
    public static setPushNotificationRiskTag = async (tag) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.setPushNotificationRiskTag(tag);
        }
    };

    //TO DO - check if this is still used
    public static getGPSCountry = () => {};

    //TO DO - check if this is still used
    public static getGPSCountryTimeout = () => {};

    public static showBottomBar = async (visible: boolean) => {
        const nativeService = await NativeUtils._nativeServicePromise;
        if (nativeService) {
            nativeService.showBottomBar(visible);
        }
    };

    public static checkAppUpgradeRequired = async () => {
        const utils = Container.get(Utils);
        const featureAvailability = Container.get(FeatureAvailabilityToken);
        const mb = MessageBroker.getInstance();
        const isAppUpgradeRequiredFeatureOn = featureAvailability.IsFeatureEnabled(
            Features.APP_UPGRADE_REQUIRED,
        );

        const nativeService = await this._nativeServicePromise;

        if (!(isAppUpgradeRequiredFeatureOn && utils.findIfIsNative())) {
            return;
        }

        await nativeService.NativeStoreReadyDO.promise;

        const { response } = await NativeApi.GetAppUpgradeRequired();

        if (response) {
            const isiOS = nativeService?.isiOS || false;

            const currentAppVersion = DataStoreHelper.getNativeStoreItem(
                isiOS ? 'ApplicationVersion' : 'Version',
            );
            let appDownloadLink = isiOS
                ? response.AppDownloadLinkIOS
                : response.AppDownloadLinkAndroid;

            const minimumAppVersion = isiOS
                ? response.MinimumAppVersionIOS
                : response.MinimumAppVersionAndroid;

            const versionComparisonResult = utils.compareVersions(
                currentAppVersion,
                minimumAppVersion,
            );

            if (versionComparisonResult === -1) {
                await NativeUtils.showBottomBar(false);
                mb.ui.topics.Dialog.publish({ publisher: 'NativeService' }, {
                    dialogType: DialogType.AppUpgradeRequired,
                    options: {
                        minimumAppVersion,
                        appDownloadLink,
                    },
                } as IDialogTopicPayload);
            }

            return versionComparisonResult === -1;
        }

        return false;
    };
}
