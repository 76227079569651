import { Service, Token } from 'typedi';
import { IRegularAuthenticationService } from '../models/';
import { Lock } from '../../utils/lockUtil';
import { BaseModuleLoader } from '../../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const RegularAuthenticationServiceToken = new Token<IRegularAuthenticationService>(
    'RegularAuthenticationService',
);

@Service()
export class RegularAuthenticationServiceLoader extends BaseModuleLoader<IRegularAuthenticationService> {
    constructor() {
        super('RegularAuthenticationServiceLoader');
    }

    protected _createModuleInstance = async (): Promise<IRegularAuthenticationService> => {
        const { RegularAuthenticationService } = await import(
            /* webpackChunkName: "authentication/service/regular" */
            './regular-authentication.service'
        );
        return new RegularAuthenticationService();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IRegularAuthenticationService> =>
        RegularAuthenticationServiceToken;
}
