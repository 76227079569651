import API, { ApiResponse } from 'api';
import { HTTPVerb } from '../Modules/Utils/API/Constants/HTTPVerb';
import {
    IGetPendingActionsParams,
    IGetPendingActionsResponse,
} from '../src/modules/pending/interfaces';

enum APIConstants {
    HandlePending = 'api/pending/autologin-data/',
    GetPendingActions = '/api/pending/',
}

export default class PendingApi {
    public static HandlePending = async (data?: any): Promise<any> =>
        API.request({
            url: APIConstants.HandlePending,
            method: HTTPVerb.GET,
            data,
        });

    public static GetPendingActions = async (
        params?: IGetPendingActionsParams,
    ): Promise<ApiResponse<IGetPendingActionsResponse>> =>
        API.authenticatedRequest({
            url: APIConstants.GetPendingActions,
            method: HTTPVerb.GET,
            params,
        });
}
