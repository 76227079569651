import Container, { Service, Token } from 'typedi';
import { IMessagesHandler } from './models/IMessagesHandler';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import { Lock } from '../utils/lockUtil';

const moduleLock = new Lock();
export const MessagesHandlerToken = new Token<IMessagesHandler>('PostLogin');

@Service()
export class MessagesHandlerLoader extends BaseModuleLoader<IMessagesHandler> {
    constructor() {
        super('MessagesHandlerLoader');
    }

    protected _createModuleInstance = async (): Promise<IMessagesHandler> => {
        const { MessagesHandler } = await import(
            /* webpackChunkName: "triple8/message-handler" */
            './messages.handler'
        );

        return new MessagesHandler();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IMessagesHandler> => MessagesHandlerToken;
}
