import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../logger';
import { ISiteSegmentationContext } from './models/ISiteSegmentationContext';
import { LocalSimpleStoreService } from '../storage/implementations/simple-store';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import PageContextManager from 'page-context-manager';
import { UserDataStoreDeferredObjectToken } from '../../injection-tokens';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import { ISessionUserData } from '../session-manager/interfaces/ISessionUserData';

@Service()
export class SiteSegmentationContext {
    private readonly _logger: ILogger;
    private readonly _localSimpleStorageService: LocalSimpleStoreService;
    private readonly _userDataStoreDeferredObject: DeferredObject<ISessionUserData>;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('SiteSegmentationContext');
        this._localSimpleStorageService = Container.get(LocalSimpleStoreService);
        this._userDataStoreDeferredObject = Container.get(UserDataStoreDeferredObjectToken);
    }

    public setSiteSegmentation = async () => {
        const siteSegmentationContext = await this._generate();
        const siteSegmentationContextEncoded = this._encode(siteSegmentationContext);
        this._localSimpleStorageService.set(
            StorageItemEnum.SiteSegmentationContext,
            siteSegmentationContextEncoded,
        );
        return siteSegmentationContextEncoded;
    };

    public update = async (siteSegmentationContext: Partial<ISiteSegmentationContext>) => {
        let existingSiteSegmentation = await this.getJSON();
        if (!existingSiteSegmentation) {
            existingSiteSegmentation = await this._generate();
        }

        const updatedSiteSegmentationContext: ISiteSegmentationContext = {
            ...existingSiteSegmentation,
            ...siteSegmentationContext,
        };

        this._localSimpleStorageService.set(
            StorageItemEnum.SiteSegmentationContext,
            this._encode(updatedSiteSegmentationContext),
        );
    };

    public getString = async () => {
        const siteSegmentation = this._localSimpleStorageService.get(
            StorageItemEnum.SiteSegmentationContext,
        );
        return siteSegmentation ? siteSegmentation : await this.setSiteSegmentation();
    };

    public getJSON = async (): Promise<ISiteSegmentationContext> => {
        const siteSegmentationContextString = await this.getString();
        if (!siteSegmentationContextString) return null;

        return this._decode(siteSegmentationContextString);
    };

    private _generate = async (): Promise<ISiteSegmentationContext> => {
        await this._userDataStoreDeferredObject.promise;
        const localizationData = PageContextManager.getLocalizationData();
        const userData = PageContextManager.getUserData();
        const deviceData = PageContextManager.getDeviceData();
        const infraData = PageContextManager.getInfraData();
        const envData = PageContextManager.getEnvironmentData();

        const siteSegmentationContext: ISiteSegmentationContext = {
            BankrollCurrency: localizationData.currency,
            IsAuthenticated: !!userData.cid,
            IsHybrid: deviceData.isHybrid,
            SystemId: Number(envData.systemId),
            Serial: Number(infraData.serial),
            IsUC: true,
        };
        if (userData.cid) {
            siteSegmentationContext.IsFTD = userData.isFtd;
            siteSegmentationContext.PlayerStatus = userData.statusGroupId;
        }

        return siteSegmentationContext;
    };

    private _encode = (siteSegmentationContext: ISiteSegmentationContext) =>
        encodeURIComponent(JSON.stringify(siteSegmentationContext));
    private _decode = (siteSegmentationContextString: string) =>
        JSON.parse(decodeURIComponent(siteSegmentationContextString));
}
