import Container, { Service, Token } from 'typedi';
import { BaseModuleLoader } from '../../../loaders/BaseModuleLoader';
import { IPreloaderManager } from './models/interfaces/IPreloaderManager';
import { Lock } from '../../utils/lockUtil';
import { Utils } from '../../utils';

const moduleLock = new Lock();

export const PreloaderManagerToken = new Token<IPreloaderManager>('PreloaderManager');

@Service()
export class PreloaderManagerLoader extends BaseModuleLoader<IPreloaderManager> {
    private readonly _utils: Utils;
    constructor() {
        super('PreloaderManagerLoader');
        this._utils = Container.get(Utils);
    }

    protected _createModuleInstance = async (): Promise<IPreloaderManager> => {
        const { PreloaderManager } = await import(
            /* webpackChunkName: "preloader/preloader-manager" */
            './preloader-manager'
        );

        return new PreloaderManager();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IPreloaderManager> => PreloaderManagerToken;

    protected override _canUse = async (): Promise<boolean> => {
        const isNativeSdk = this._utils.isNativeSDK();

        return !isNativeSdk;
    };
}
