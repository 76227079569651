import { Service, Token } from 'typedi';
import { IAuthenticationFlowRunner } from '../models/';
import { Lock } from '../../utils/lockUtil';
import { BaseModuleLoader } from '../../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const CrossAuthenticationFlowRunnerToken = new Token<IAuthenticationFlowRunner>(
    'CrossAuthenticationFlowRunner',
);

@Service()
export class CrossAuthenticationFlowRunnerLoader extends BaseModuleLoader<IAuthenticationFlowRunner> {
    constructor() {
        super('CrossAuthenticationFlowRunnerLoader');
    }

    protected _createModuleInstance = async (): Promise<IAuthenticationFlowRunner> => {
        const { CrossAuthenticationFlowRunner } = await import(
            /* webpackChunkName: "authentication/flow-runners/cross" */
            './cross-authentication-flow-runner'
        );

        return new CrossAuthenticationFlowRunner();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IAuthenticationFlowRunner> =>
        CrossAuthenticationFlowRunnerToken;
}
