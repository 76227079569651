import Container, { Service } from 'typedi';
import { ClientsFrameworkLibrary } from 'library-manager';

import { ILogger, LoggerProvider } from '../../logger';
import { IClientsFrameworkAutologinService } from './interfaces';

@Service()
export class ClientsFrameworkAutologinService implements IClientsFrameworkAutologinService {
    protected readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientsFrameworkAutologinService');
    }

    public SetBackButtonData = async (data): Promise<void> => {
        await ClientsFrameworkLibrary.ready.then(() => {
            PF.Web.ClientsFramework.AutoLogin.SetBackButtonData(data);
        });
    };
}
