import Container, { Service } from "typedi";
import { ITrackingEvent } from "./ITrackingEvent";
import { GTMDataLayerToken } from "../../../injection-tokens";
import { ILogger, LoggerProvider } from "../../logger";
import { ITrackingProvider } from "./interfaces";
import { IGTMTrackingEvent } from "./IGTMTrackingEvent";

@Service()
export class GTMTracking implements ITrackingProvider {
    private _logger: ILogger;
    private _GTMDataLayer: Array<IGTMTrackingEvent>;

    public get GTMDataLayer() {
        return this._GTMDataLayer;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger("GTMTracking");
        this._GTMDataLayer = Container.get(GTMDataLayerToken);
    }

    track(eventData: ITrackingEvent): void {
        if (!eventData) return;

        if (this._GTMDataLayer) {
            const customDimensions = eventData.custom || {};
            delete eventData.custom;

            const gtmEventData: IGTMTrackingEvent = {
                event: eventData.event,
                category: `${eventData.subBrandName} - ${eventData.deviceType} - ${eventData.category}`,
                action: eventData.action,
                label: eventData.label,
                value: eventData.value,
                ...customDimensions,
            };

            //push the event to GTM datalayer
            this._logger.table("Adding event to GTM data layer", gtmEventData);
            this.GTMDataLayer.push(gtmEventData);
        } else
            this._logger.error(
                "Can't add to GTMDataLayer because 'GTMDataLayerToken' is null or undefined"
            );
    }
}
