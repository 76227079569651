import { ClientInit } from './client-init';
import { Service } from 'typedi';
import { ILoginSuccessUCData } from '../../global-subscriber/mb-channels';

@Service()
export class KambiClientInit extends ClientInit {
    constructor() {
        super();
    }

    protected override async onLoginSuccess(data: ILoginSuccessUCData) {
        await super.onLoginSuccess(data);
    }
}
