import { IObjectStorageService, ObjectStorageData } from '../interfaces';

export class WebObjectStorageService implements IObjectStorageService {
    constructor(private readonly _storage: Storage) {}

    upsert = <T>(storageKey: string, data: (T | Partial<T>) & ObjectStorageData<T>) => {
        const existingStoreData = this.select<T>(storageKey);

        const storeData = existingStoreData
            ? Object.assign({}, existingStoreData, data)
            : data === null
            ? undefined
            : data;

        this._storage.setItem(storageKey, JSON.stringify(storeData));
    };

    insert = <T>(storageKey: string, data: (T | Partial<T>) & ObjectStorageData<T>) => {
        const storeData = data === null ? undefined : JSON.stringify(data);

        this._storage.setItem(storageKey, storeData);
    };

    update = <T>(storageKey: string, data: Partial<T> & ObjectStorageData<T>) => {
        const storeData = this.select<T>(storageKey);

        if (storeData) {
            const updateStoreData = Object.assign({}, storeData, data);

            this._storage.setItem(storageKey, JSON.stringify(updateStoreData));
        }
    };

    select = <T>(storageKey: string): T => {
        const storeData = this._storage.getItem(storageKey);

        let data: T = null;

        if (storeData) data = JSON.parse(storeData);

        return data;
    };

    selectPick = <T>(storageKey: string, keys: Array<keyof T & string>): Partial<T> => {
        if (keys && keys.length > 0) {
            const storeData = this.select<T>(storageKey);

            const keyValueArray = keys.map((storageObjectKey) => {
                return [storageObjectKey, storeData[storageObjectKey]];
            });

            return Object.fromEntries(keyValueArray);
        }

        return null;
    };

    delete = (storageKey: string) => this._storage.removeItem(storageKey);
}

export class WebWindowObjectStorageService implements IObjectStorageService {
    constructor(private readonly _window: Window) {}

    upsert = <T>(storageKey: string, data: (T | Partial<T>) & ObjectStorageData<T>) => {
        const existingStoreData = this.select<T>(storageKey);
        const storeData = existingStoreData
            ? Object.assign({}, existingStoreData, data)
            : data === null
            ? undefined
            : data;

        this._window[storageKey] = storeData;
    };

    insert = <T>(storageKey: string, data: (T | Partial<T>) & ObjectStorageData<T>) => {
        const storeData = data === null ? undefined : data;

        this._window[storageKey] = storeData;
    };

    update = <T>(storageKey: string, data: Partial<T> & ObjectStorageData<T>) => {
        const storeData = this.select<T>(storageKey);

        if (storeData) {
            const updateStoreData = Object.assign({}, storeData, data);

            this._window[storageKey] = updateStoreData;
        }
    };

    select = <T>(storageKey: string): T => {
        const storeData = this._window[storageKey];

        if (storeData) return storeData as T;

        return null;
    };

    selectPick = <T>(storageKey: string, keys: Array<keyof T & string>): Partial<T> => {
        if (keys && keys.length > 0) {
            const storeData = this.select<T>(storageKey);

            const keyValueArray = keys.map((storageObjectKey) => {
                return [storageObjectKey, storeData[storageObjectKey]];
            });

            return Object.fromEntries(keyValueArray);
        }

        return null;
    };

    delete = (storageKey: string) => delete this._window[storageKey];
}
