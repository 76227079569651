import { Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { ISport } from './models/ISport';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const SportToken = new Token<ISport>('Sport');

@Service()
export class SportLoader extends BaseModuleLoader<ISport> {
    constructor() {
        super('SportLoader');
    }

    protected _createModuleInstance = async (): Promise<ISport> => {
        const { Sport } = await import(
            /* webpackChunkName: "sport/sport" */
            './sport'
        );

        return new Sport();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<ISport> => SportToken;
}
