import { Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { IRegistrationDataService } from './models/IRegistrationDataService';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const RegistrationDataServiceToken = new Token<IRegistrationDataService>(
    'RegistrationDataService',
);

@Service()
export class RegistrationDataServiceLoader extends BaseModuleLoader<IRegistrationDataService> {
    constructor() {
        super('RegistrationDataServiceLoader');
    }

    protected _createModuleInstance = async (): Promise<IRegistrationDataService> => {
        const { RegistrationDataService } = await import(
            /* webpackChunkName: "registration/service" */
            './registration.data.service'
        );

        return new RegistrationDataService();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IRegistrationDataService> =>
        RegistrationDataServiceToken;
}
