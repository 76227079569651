import {
    IHapticFeedbackData,
    ILogger,
    IUserInteractionChannel,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { UserInteraction } from '../../user-interaction';

@Service()
export class UserInteractionChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _userInteractionChannel: IUserInteractionChannel;
    private readonly _userInteraction: UserInteraction;
    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('UIChannelSubscriber');
        this._userInteraction = Container.get(UserInteraction);
        this._userInteractionChannel = MessageBroker.getInstance().userinteraction;
        this._userInteractionChannel.topics.hapticFeedback.subscribe(
            this.onHapticFeedback.bind(this),
        );
    }

    private async onHapticFeedback(data: IHapticFeedbackData) {
        this._userInteraction.onHapticFeedback(data);
    }
}
