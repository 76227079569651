import { ClientIntegrationFacade } from './client-integration.facade';
import Container from 'typedi';
import { TrackingUtilsService } from '../tracking/tracking-utils.service';
import PageContextManager from 'page-context-manager';

export class KambiClientIntegrationFacade extends ClientIntegrationFacade {
    constructor() {
        super();
        this.loginPublish = this.loginPublish.bind(this);
    }

    public getPlayerSegmentation = () => {
        const trackingUtilsService = Container.get(TrackingUtilsService);
        var data = this.getPlayerSegmentationInitial();
        data['reportingParameters'] = trackingUtilsService.getReportingParameters();

        return data;
    };

    public loginPublish = (data: any): void => {
        const currency = PageContextManager.getLocalizationData().currency;

        super.loginPublish({ ...data, currency });
    };
}
