import Container from 'typedi';
import { FeatureAvailabilityToken } from '../../injection-tokens';
import { FeatureApplicationCapability } from './feature-application-capability';
import { FeatureEligibility } from './feature-eligibility/feature-eligibility';

export * from './feature-application-capability';
export * from './constants';

export const exportFeatureChecks = (): {
    featureIsEnabled: (featureName: string, genericFeatureName?: string) => Promise<boolean>;
    isFeatureEnabled: (featureName: string, genericFeatureName?: string) => boolean;
    isFeatureEnabledABTest: (
        abFeatureName: string,
        genericFeatureName?: string,
    ) => Promise<boolean>;
    getActiveAbTestFeatures: (featureTestId?: number) => Promise<any>;
    isUserEligible: (propertyName: string) => Promise<boolean>;
    IsAppVersionHigherThan: (minimumNativeAppVersion: number) => boolean;
} => {
    return {
        featureIsEnabled: Container.get(FeatureAvailabilityToken).FeatureIsEnabled,
        isFeatureEnabled: Container.get(FeatureAvailabilityToken).IsFeatureEnabled,
        isFeatureEnabledABTest: Container.get(FeatureAvailabilityToken).IsFeatureEnabledABTest,
        getActiveAbTestFeatures: Container.get(FeatureAvailabilityToken).GetActiveAbTestFeatures,
        isUserEligible: Container.get(FeatureEligibility).IsUserEligible,
        IsAppVersionHigherThan: Container.get(FeatureApplicationCapability).IsAppVersionHigherThan,
    };
};
