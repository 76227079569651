import { Container, Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { INativeService } from './models/INativeService';
import { Utils } from '../utils';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const NativeServiceToken = new Token<INativeService>('NativeService');
@Service()
export class NativeServiceLoader extends BaseModuleLoader<INativeService> {
    private readonly _utils: Utils;

    constructor() {
        super('NativeServiceLoader');
        this._utils = Container.get(Utils);
    }

    protected _createModuleInstance = async (): Promise<INativeService> => {
        const { NativeService } = await import(
            /* webpackChunkName: "native/native-service" */
            './native.service'
        );

        return new NativeService();
    };

    protected override _canUse: () => Promise<boolean> = async () => {
        const isNative = this._utils.findIfIsNative();

        if (!isNative) {
            this._logger.info('Native Service module will no loaded since is not native');
        }

        return isNative;
    };
    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<INativeService> => NativeServiceToken;
}
