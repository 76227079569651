import Container from 'typedi';
import { ClientSettingsToken } from '../../../../injection-tokens';
import { ClientProvider } from '../../../../models/enums/Consts';
import { Utils } from '../../../utils';
import { KambiClientSettings } from '../../settings/kambi-client-settings';
import { PokerClientSettings } from '../../settings/poker-client-settings';
import { SpectateClientSettings } from '../../settings/spectate-client-settings';

export const addSettings = () => {
    const settingsService = getClientSettings();
    Container.set(ClientSettingsToken, settingsService);
};

const getClientSettings = () => {
    const utils = Container.get(Utils);
    const clientProvider = utils.getClientProvider();

    switch (clientProvider) {
        case ClientProvider.Kambi:
            return new KambiClientSettings();
        case ClientProvider.Poker:
            return new PokerClientSettings();
        case ClientProvider.Spectate:
        default:
            return new SpectateClientSettings();
    }
};

// todo - in the future?
// const getClientSettingsAsync = async () => {
//     const utils = Container.get(Utils);
//     const clientProvider = utils.getClientProvider();

//     switch (clientProvider) {
//         case ClientProvider.Kambi:
//             {
//                 const { KambiClientSettings } = await import(/* webpackChunkName: "chunks/kambi-client-settings" */ "../../settings/kambi-client-settings");
//                 return new KambiClientSettings();
//             }
//         case ClientProvider.Poker:
//             {
//                 const { PokerClientSettings } = await import(/* webpackChunkName: "chunks/poker-client-settings" */ "../../settings/poker-client-settings");
//                 return new PokerClientSettings();
//             }
//         case ClientProvider.Spectate:
//         default:
//             {
//                 const { SpectateClientSettings } = await import(/* webpackChunkName: "chunks/spectate-client-settings" */ "../../settings/spectate-client-settings");
//                 return new SpectateClientSettings();
//             }
//     }
// };
