import { Lock } from '../utils/lockUtil';
import { Service, Token } from 'typedi';
import { IBiometricsController } from './models/IBiometricsController';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();
export const BiometricsControllerToken = new Token<IBiometricsController>('BiometricsController');

@Service()
export class BiometricsControllerLoader extends BaseModuleLoader<IBiometricsController> {
    constructor() {
        super('BiometricsControllerLoader');
    }

    protected _createModuleInstance = async (): Promise<IBiometricsController> => {
        const { BiometricsController } = await import(
            /* webpackChunkName: "biometrics/controller" */
            './biometrics.controller'
        );

        return new BiometricsController();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IBiometricsController> => BiometricsControllerToken;
}
