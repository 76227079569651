export enum PerformanceMarks {
    GetClientSettingsStart = 'get-client-settings-start',
    ViewInjectorShowContentStart = 'show-content-start',
    ViewInjectorShowClientStart = 'show-client-start',
    UserAuthenticationFailed = 'client-init-failed-received',
    UserLoginStart = 'user-login-start',
    UserClientAutoLoginStart = 'user-client-auto-login-start',
    GeolocationLoginStart = 'geolocation-login-start',
    GeolocationLoginWithPluginStart = 'geolocation-login-with-plugin-start',
    GeolocationIntervalStart = 'geolocation-interval-start',
    GeolocationRetryStart = 'geolocation-retry-start',
}

export enum PerformanceMeasures {
    UserLogin = 'user-loging-measure',
    UserAutoLogin = 'user-auto-loging-measure',
    ClientInit = 'client-init-measure',
    ViewInjectorShowClient = 'show-client-measure',
    ViewInjectorShowContent = 'show-content-measure',
    WebLogin = 'WebLoginV2',
    GeolocationLogin = 'geolocation-login',
    GeolocationLoginWithPlugin = 'geolocation-login-with-plugin',
    GeolocationInterval = 'geolocation-interval',
    GeolocationRetry = 'geolocation-retry',
}

export enum PerformanceElasticEvents {
    ClientInitFailed = 'clientInitFailed',
    ClientInitSucceeded = 'clientInitSucceeded',
    UserLoginDone = 'loginDone',
    UserLoginFailed = 'loginFailed',
}
