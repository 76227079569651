import { Service, Token } from 'typedi';
import { IAuthenticationFlowRunner } from '../models/';
import { Lock } from '../../utils/lockUtil';
import { BaseModuleLoader } from '../../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const BiometricsAuthenticationFlowRunnerToken = new Token<IAuthenticationFlowRunner>(
    'BiometricsAuthenticationFlowRunner',
);

@Service()
export class BiometricsAuthenticationFlowRunnerLoader extends BaseModuleLoader<IAuthenticationFlowRunner> {
    constructor() {
        super('BiometricsAuthenticationFlowRunnerLoader');
    }

    protected _createModuleInstance = async (): Promise<IAuthenticationFlowRunner> => {
        const { BiometricsAuthenticationFlowRunner } = await import(
            /* webpackChunkName: "authentication/flow-runners/biometrics" */
            './biometrics-authentication-flow-runner'
        );

        return new BiometricsAuthenticationFlowRunner();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IAuthenticationFlowRunner> =>
        BiometricsAuthenticationFlowRunnerToken;
}
