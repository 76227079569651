import { Container } from 'typedi';
import { ILogger, LoggerProvider } from '../logger';

import { IPostMessageHandler, IPostMessageService } from './models';

export default class PostMessageService implements IPostMessageService {
    private readonly _logger: ILogger;
    private readonly _handlers: IPostMessageHandler[];

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PostMessageService');

        this._handlers = [];
    }

    public postMessageHandler = async (event) => {
        // this._logger.log(`Event received. Origin: ${event.origin}.`);

        for (let postMessageHandler of this._handlers) {
            if (await postMessageHandler.handler(event)) break;
        }
    };

    public addHandler = (postMessageHandler: IPostMessageHandler) => {
        this._logger.log(`Registering new POSTMessageHandler named: ${postMessageHandler.name}`);

        if (!postMessageHandler) return;

        if (this._handlers.find((h) => h.name === postMessageHandler.name)) {
            throw new Error(
                `POST message handler named ${postMessageHandler.name} already exists. Please change the name or resolve the duplication issue!`,
            );
        }

        this._addHandler(postMessageHandler);
    };

    public tryAddHandler = (postMessageHandler: IPostMessageHandler): boolean => {
        this._logger.log(
            `Try Registering new POSTMessageHandler named: ${postMessageHandler.name}`,
        );

        if (!postMessageHandler) return false;

        if (this._handlers.find((h) => h.name === postMessageHandler.name)) {
            this._logger.warning(
                `POST message handler named ${postMessageHandler.name} already exists. Please change the name or resolve the duplication issue!`,
            );
            return false;
        }

        this._addHandler(postMessageHandler);
        return true;
    };

    private _addHandler(postMessageHandler: IPostMessageHandler) {
        this._handlers.push(postMessageHandler);
    }
}
