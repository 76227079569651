import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../../logger';
import { TouchIdService } from '../../native/touch.id.service';
import DocumentHelper from '../../../../Modules/Utils/DocumentHelper';
import { IGeolocation } from '../../geolocation/models/IGeolocation';
import { INativeService } from '../../native/models/INativeService';
import { IBiometricsController } from '../../biometrics/models/IBiometricsController';
import { ClientTracking } from '../../tracking/models/clientTracking';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class NativeDocumentEventsSubscriber {
    private readonly _logger: ILogger;
    private readonly _touchIdService: TouchIdService;
    private readonly _clientTracking: ClientTracking;

    private get _geolocationPromise(): Promise<IGeolocation> {
        return LoaderManager.Instance.GeolocationLoader.Instance;
    }

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    private get _biometricsControllerPromise(): Promise<IBiometricsController> {
        return LoaderManager.Instance.BiometricsControllerLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('NativeDocumentEventsSubscriber');
        this._touchIdService = Container.get(TouchIdService);
        this._clientTracking = Container.get(ClientTracking);

        DocumentHelper.addEventListener('message', this._onMessageEvent.bind(this));
        DocumentHelper.addEventListener('click', this._onClickEvent.bind(this));
        DocumentHelper.onDomReady(this._onDomReady.bind(this));
    }

    private _onMessageEvent = async (event) => {
        await this._touchIdService.onIosInitCompleted(event);

        const geolocation = await this._geolocationPromise;
        geolocation?.GCAdapter.onGeolocationEvent(event);

        const biometricsController = await this._biometricsControllerPromise;
        biometricsController?.onAppInitComplete(event);
    };

    private _onDomReady = async () => {
        const nativeService = await this._nativeServicePromise;
        nativeService.onDomReady();
    };
    private _onClickEvent = async (event: MouseEvent) => {
        this._clientTracking.onHandleClickEvents(event);
    };
}
