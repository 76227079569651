import Container from 'typedi';
import { UserContextToken } from '../../injection-tokens';
import { WindowToken } from '../../injection-tokens';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import { UrlUtils } from '../utils/urlUtils';
import { IClientIntegrationFacade } from './interfaces/IClientIntegrationFacade';
import { ClientIntegrationPubSub } from './client-integration.pubsub';
import { IPlayerSegmentationData, UserMode } from '@sparkware/uc-sdk-core';
import PageContextManager from 'page-context-manager';

export abstract class ClientIntegrationFacade
    extends ClientIntegrationPubSub
    implements IClientIntegrationFacade
{
    protected _nativeUXVersion: number;
    protected readonly _window: Window;
    protected readonly _readyToConnectDO: DeferredObject<boolean>;
    protected readonly _urlUtils: UrlUtils;

    public abstract getPlayerSegmentation(): IPlayerSegmentationData;

    public get ReadyToConnect(): DeferredObject<boolean> {
        return this._readyToConnectDO;
    }

    constructor() {
        super();
        this._window = Container.get(WindowToken);
        this._readyToConnectDO = new DeferredObject<boolean>();
        this._urlUtils = Container.get(UrlUtils);

        const messageBrokerSubscription = this.messageBrokerSubscribe(() => {
            this._readyToConnectDO.resolve(true);
            messageBrokerSubscription?.unsubscribe();
            clientInitFailSubscription?.unsubscribe();
        });

        const clientInitFailSubscription = this.clientInitFailSubscribe(() => {
            this._readyToConnectDO.resolve(false);
            messageBrokerSubscription?.unsubscribe();
            clientInitFailSubscription?.unsubscribe();
        });
    }

    public playerSegmentationPublish = async (data?: IPlayerSegmentationData) => {
        const readyToConnect = await this._readyToConnectDO.promise;
        if (readyToConnect) {
            const segmentationData = data || this.getPlayerSegmentation();
            super.playerSegmentationPublish(segmentationData);
        }
    };

    public getPlayerSegmentationInitial(): IPlayerSegmentationData {
        const userContext = Container.get(UserContextToken);
        const { isFtd, isPromotionPermitted, isPromotionRestricted, isUserVip } =
            PageContextManager.getUserData();

        const { currency, langIso3, culture } = PageContextManager.getLocalizationData();

        const country = PageContextManager.getGlobalizationData().country;
        const serial = PageContextManager.getInfraData().serial;

        const { isHybrid, isMobile } = PageContextManager.getDeviceData();

        const userData = PageContextManager.getUserData();

        const data = {
            country: country,
            currency: currency,
            isFTD: isFtd,
            isHybrid: isHybrid,
            isMobile: isMobile,
            device: isMobile ? 'mobile' : 'desktop',
            isPromotionPermitted: isPromotionPermitted,
            isPromotionRestricted: isPromotionRestricted,
            isVIP: isUserVip,
            languageCode: langIso3,
            locale: culture,
            RMS: userData.rms,
            cid: userData.cid,
            userMode: userContext.IsAuthenticated ? UserMode.Authenticated : UserMode.Anonymous,
            serial: serial,
        };

        return data;
    }
}
