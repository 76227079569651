import { ElementType, SourceType } from '../app-launcher/enums';
import { Container, Service } from 'typedi';
import { IFullCashierPayment, IFullCashierPaymentCallbacks } from './models';
import { ILogger, LoggerProvider } from '../logger';

import { IUnifiedCashierPayment } from './models';
import { PaymentAppType } from './enums';
import { AreaType, IPaymentActionData } from '@sparkware/uc-sdk-core';
import { PaymentsService } from './payments.service';
import { ClickStreamTrackingProvider } from '../tracking';
import { EventFormatterBuilderFactory } from '@unified-client/event-formatter';
import { Utils } from '../utils';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { SessionSimpleStoreService } from '../storage/implementations/simple-store';

@Service()
export class Payments {
    private readonly _logger: ILogger;
    private readonly _service: PaymentsService;
    private readonly _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private readonly _eventFormatterBuilder: any;
    private readonly _utils: Utils;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('Payments');
        this._service = Container.get(PaymentsService);
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('Payments');
        this._utils = Container.get(Utils);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
    }

    public onQuickDeposit = async (paymentAction: IPaymentActionData): Promise<void> => {
        paymentAction = {
            TabId: paymentAction?.TabId || 16,
            AppType: paymentAction?.AppType || PaymentAppType.UnifiedCashier,
            Element: paymentAction?.Element || ElementType.UD,
            Source: paymentAction?.Source || SourceType.UD,
            Area: paymentAction?.Area || AreaType.UD,
        };
        await this.onPaymentOpen(paymentAction);
    };

    public onPaymentOpen = async (paymentAction: IPaymentActionData): Promise<void> => {
        this._logger.debug(
            `Received open payment action with payment app type: '${paymentAction?.AppType}' and tab id: '${paymentAction?.TabId}' and promo code: '${paymentAction?.PromoCode}'`,
        );

        if (paymentAction?.AppType === PaymentAppType.FullCashier) {
            await this.openCashier(paymentAction);
        } else await this.openWidget(paymentAction);

        this.sendStraightToCashierEvent();
    };

    private sendStraightToCashierEvent = () => {
        const startTimeStamp = this._sessionSimpleStoreService.get(
            StorageItemEnum.CashierOpenStartTimestamp,
        );

        if (!startTimeStamp) return;
        this._sessionSimpleStoreService.remove(StorageItemEnum.CashierOpenStartTimestamp);

        const message = 'uc-straight-to-cashier';
        const durationInMS = Date.now() - Number(startTimeStamp);
        const formatter = this._eventFormatterBuilder.createFormatter('sendStraightToCashierEvent');
        const clickStreamEvent = formatter.formatUCEvent(
            { message, durationInMS },
            { correlationID: this._utils.generateCorrelationID() },
        );

        this._clickStreamTrackingProvider.sendEventV2(clickStreamEvent);
    };

    private openCashier = async (paymentAction: IPaymentActionData): Promise<void> => {
        this._logger.debug('Try open full cashier payment action');

        const data: IFullCashierPayment = {
            PromoCode: paymentAction?.PromoCode,
            TabId: paymentAction?.TabId,
        };

        const callbacks: IFullCashierPaymentCallbacks = {
            onClose: paymentAction?.OnClose,
        };

        return this._service.OpenCashier(data, callbacks);
    };

    private openWidget = async (paymentAction: IPaymentActionData): Promise<void> => {
        this._logger.debug('Try open unified cashier payment action');

        let promoCode = null;

        if (!paymentAction?.PromoCode) {
            const sessionPromo = this._sessionSimpleStoreService.get(
                StorageItemEnum.CashierPromoCode,
            );

            if (sessionPromo) {
                promoCode = sessionPromo;
            }
        } else promoCode = paymentAction.PromoCode;

        this._sessionSimpleStoreService.remove(StorageItemEnum.CashierPromoCode);

        const unifiedCashierPayment: IUnifiedCashierPayment = {
            ActionId: paymentAction?.ActionId,
            PromoCode: promoCode,
            TabId: paymentAction?.TabId,
            Channel: {
                area: paymentAction?.Area || AreaType.UD,
                source: paymentAction?.Source || SourceType.UD,
                element: paymentAction?.Element || ElementType.UD,
            },
            OnClose: paymentAction.OnClose,
            AnalyticsData: {
                category: paymentAction?.AnalyticsData?.category,
                action: paymentAction?.AnalyticsData?.action,
                label: paymentAction?.AnalyticsData?.label,
            },
        };

        await this._service.OpenWidget(unifiedCashierPayment);
    };
}
