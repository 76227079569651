import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../../logger';
import { IPostMessageChannel, MessageBroker } from '@sparkware/uc-sdk-core';

import { IPostMessageHandler, IPostMessageService } from '../../post-message';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class PostMessageChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _postMessageChannel: IPostMessageChannel;

    private get _postMessageServicePromise(): Promise<IPostMessageService> {
        return LoaderManager.Instance.PostMessageServiceLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PostMessageChannelSubscriber');
        this._postMessageChannel = MessageBroker.getInstance().postMessage;
        this._postMessageChannel.topics.RegisterHandler.subscribe(
            this.onRegisterHandler.bind(this),
        );
    }

    private async onRegisterHandler(postMessageHandler: IPostMessageHandler) {
        const postMessageService = await this._postMessageServicePromise;
        postMessageService?.addHandler(postMessageHandler);
    }
}
