export interface IDeferredObjectOptions {
    storeResolved: boolean;
}

/**
 * Used for creating a promise object
 * which can be resolved/rejected outside the place of creation
 * of the promise
 */
export default class DeferredObject<T> {
    private readonly _promise: Promise<T>;
    private _resolve: (value?: T | PromiseLike<T>) => void;
    private _reject: (reason?: any) => void;
    private _resolved: boolean = false;
    private _resolvedValue: T | PromiseLike<T> | undefined;
    private _options: Partial<IDeferredObjectOptions>;

    /**
     * Accessor for the promise
     */
    public get promise(): Promise<T> {
        return this._promise;
    }

    public get resolved(): boolean {
        return this._resolved;
    }

    public get resolvedValue(): T | PromiseLike<T> | undefined {
        return this._resolvedValue;
    }

    constructor(options: Partial<IDeferredObjectOptions> = {}) {
        this._options = options;
        this._promise = new Promise<T>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    /**
     * Method to resolve the underlined promise
     * @param value
     * @returns
     */
    public resolve = (value?: T | PromiseLike<T>) => {
        if (this._options.storeResolved) {
            this._resolvedValue = value;
        }

        this._resolved = true;

        return this._resolve(value);
    };

    /**
     * Method to reject the underlined promise
     * @param reason Reject the promise
     */
    public reject = (reason?: any) => {
        this._reject(reason);
    };
}
