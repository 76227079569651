import { ClientIntegrationFacadeToken } from '../../../injection-tokens';
import { ClientSettings } from './client-settings';
import Container from 'typedi';
import { PokerClientIntegrationFacade } from '../poker-client-integration.facade';
import {
    IClientContainerData,
    IClientSettings,
    IPokerClientSettings,
} from '@sparkware/uc-sdk-core';
import { StaticPaths } from '../../../models/enums/Consts';

export class PokerClientSettings extends ClientSettings {
    constructor() {
        super();
    }

    public getClientSettings = (): IPokerClientSettings => {
        const clientSettingsBase: IClientSettings = super.getClientSettings() as IClientSettings;

        const containers: IClientContainerData = {
            fullScreenPopup: 'uc-full-screen-popup',
            actionIndicator: 'uc-action-indicator-container',
            modalContainer: 'uc-modal-container',
        };

        const clientSettings: IPokerClientSettings = {
            ...clientSettingsBase,
            urlPathPrefix: StaticPaths.PokerRoot,
            containers: containers,
        };

        return clientSettings;
    };

    protected registerTokens() {
        super.registerTokens();
        Container.set(ClientIntegrationFacadeToken, new PokerClientIntegrationFacade());
    }
}
