import {
    IChatActivityTopicPayload,
    ICustomerRelationsChannel,
    IRemoveAppContainerDTO,
    ISessionChannel,
    IUiChannel,
    MessageBroker,
    ICreateAppContainerData,
} from '@sparkware/uc-sdk-core';
import Container, { Service } from 'typedi';
import { MessageType, NativeInterfaces } from '../client-integration/init/native/enums/enums';
import { INativeMessage } from '../client-integration/init/native/models/INativeMessage';
import { ILogger, LoggerProvider } from '../logger';
import { IInitAnonymousMessageData, IInitMessageData } from './models';
import {
    validateInitAnonymousMessageData,
    validateInitMessageData,
} from './nativesdk-message-validations';
import { NativeSdkUtils } from './native-sdk-utils';
import { NativeSdkDispatcher } from './native-sdk-dispatcher';
import { IActionHandlerFactory } from '../action-handler/models/IActionHandlerFactory';
import { LoaderManager } from '../../loaders/LoaderManager';

@Service()
export class MbAdapter {
    private readonly _sessionManagerChannel: ISessionChannel;
    private readonly _uiChannel: IUiChannel;
    private readonly _logger: ILogger;
    private readonly _customerRelations: ICustomerRelationsChannel;
    private readonly _nativeSdkUtils: NativeSdkUtils;
    private readonly _nativeSdkDispatcher: NativeSdkDispatcher;

    private get _actionHandlerFactoryPromise(): Promise<IActionHandlerFactory> {
        return LoaderManager.Instance.ActionHandlerFactoryLoader.Instance;
    }

    constructor() {
        this._sessionManagerChannel = MessageBroker.getInstance().session;
        this._uiChannel = MessageBroker.getInstance().ui;
        this._customerRelations = MessageBroker.getInstance().customerRelations;
        this._nativeSdkUtils = Container.get(NativeSdkUtils);
        this._nativeSdkDispatcher = Container.get(NativeSdkDispatcher);

        this._uiChannel.topics.ShowAppError.subscribe(this.onShowAppError.bind(this));
        this._uiChannel.topics.CreateAppContainer.subscribe(this.onCreateAppContainer.bind(this));
        this._uiChannel.topics.RemoveAppContainer.subscribe(this.onRemoveAppContainer.bind(this));
        this._customerRelations.topics.chatActivity.subscribe(this.onChatActivity.bind(this));
        this._uiChannel.topics.toggleAppContainer.subscribe(this.onToggleAppContainer.bind(this));
        this._logger = Container.get(LoggerProvider).getLogger('MbAdapter');
    }

    public sendMessage = async (payload: INativeMessage): Promise<void> => {
        if (payload) {
            const messages = {
                [MessageType.init]: async () => {
                    const messageData = payload.messageData;
                    await this.handleInitMessage(messageData);
                },
                [MessageType.initAnonymous]: async () => {
                    const messageData = payload.messageData;
                    await this.handleInitAnonymousMessage(messageData);
                },
                [MessageType.performAction]: async () => {
                    const actionHandlerFactory = await this._actionHandlerFactoryPromise;
                    await actionHandlerFactory.performAction(payload.messageData);
                },
                [MessageType.appClosing]: async () => {
                    this._sessionManagerChannel.topics.appClosing.publish(
                        { publisher: 'MbAdapter' },
                        payload.messageData,
                    );
                },
                [MessageType.appClosed]: async () => {
                    this._sessionManagerChannel.topics.appClosed.publish(
                        { publisher: 'MbAdapter' },
                        payload.messageData,
                    );
                },
                [MessageType.updateContext]: async () => {
                    const messageData = payload.messageData;
                    this._nativeSdkUtils.handleUpdateContext(messageData);
                },
            };
            if (messages[payload.messageType]) {
                await messages[payload.messageType]();
            } else {
                this._logger.info(`message type has no handler, payload: ${payload}`);
            }
        } else {
            this._logger.error(`payload is not valid, payload: ${payload}`);
        }
    };

    private handleInitMessage = async (messageData: unknown) => {
        let initMessageData: IInitMessageData;
        try {
            initMessageData = validateInitMessageData(messageData);
        } catch (err) {
            this._logger.error(`Failed validating init message, err: ${err.message}`, messageData);
            return;
        }

        await this._nativeSdkUtils.processInitMessage(initMessageData);
    };

    private handleInitAnonymousMessage = async (messageData: unknown) => {
        let initAnonymousMessageData: IInitAnonymousMessageData;
        try {
            initAnonymousMessageData = validateInitAnonymousMessageData(messageData);
        } catch (err) {
            this._logger.error(
                `Failed validating initAnonymous message, err: ${err.message}`,
                messageData,
            );
            return;
        }

        await this._nativeSdkUtils.processInitAnonymousMessage(initAnonymousMessageData);
    };

    private onShowAppError = (messageData) => {
        const payload: INativeMessage = {
            messageType: MessageType.showError,
            messageData: messageData,
        };
        this._nativeSdkDispatcher.dispatchNSDKEvent(NativeInterfaces.PokerNativeInterface, payload);
    };

    private onCreateAppContainer = (messageData: ICreateAppContainerData) => {
        const payload: INativeMessage = {
            messageType: MessageType.createAppContainer,
            messageData,
        };

        this._nativeSdkUtils.createAppContainer(messageData);

        this._nativeSdkDispatcher.dispatchNSDKEvent(NativeInterfaces.PokerNativeInterface, payload);
    };

    private onRemoveAppContainer = (messageData: IRemoveAppContainerDTO) => {
        const payload: INativeMessage = {
            messageType: MessageType.removeAppContainer,
            messageData,
        };
        this._nativeSdkDispatcher.dispatchNSDKEvent(NativeInterfaces.PokerNativeInterface, payload);
    };

    private onChatActivity = (messageData: IChatActivityTopicPayload) => {
        const payload: INativeMessage = {
            messageType: MessageType.chatActivity,
            messageData,
        };
        this._nativeSdkDispatcher.dispatchNSDKEvent(NativeInterfaces.PokerNativeInterface, payload);
    };

    private onToggleAppContainer = (messageData) => {
        const payload: INativeMessage = {
            messageType: MessageType.toggleAppContainer,
            messageData,
        };
        this._nativeSdkDispatcher.dispatchNSDKEvent(NativeInterfaces.PokerNativeInterface, payload);
    };
}
