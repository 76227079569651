import Container, { Service } from 'typedi';
import { ClientsFrameworkLibrary } from 'library-manager';

import { ILogger, LoggerProvider } from '../../logger';
import { ICommonService } from './interfaces';

@Service()
export class CommonService implements ICommonService {
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('CommonService');
    }

    public InjectWrappingDivWithCloseButton = async (
        src,
        width?,
        height?,
        border?,
        onClose?,
        productPackageId?,
        classToAdd?,
    ) =>
        ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.Common.InjectWrappingDivWithCloseButton(
                src,
                width,
                height,
                border,
                onClose,
                productPackageId,
                classToAdd,
            ),
        );

    public sendAnalytics = async (eventData: any) =>
        ClientsFrameworkLibrary.ready.then(() => PF.Web.Common.sendAnalytics(eventData));

    public CloseWrapDiv = async (obj?: any) =>
        ClientsFrameworkLibrary.ready.then(() => PF.Web.Common.CloseWrapDiv(obj));

    public OpenMobileContainerDiv = async (
        src: string,
        closeBtnRequired: boolean,
        onClose: Function,
    ) =>
        ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.Common.OpenMobileContainerDiv(src, closeBtnRequired, onClose),
        );

    public InjectWrappingDiv = async (
        src: string,
        width: number,
        height: number,
        border: boolean,
        isOmgMsg: boolean,
        classToAdd?: string,
    ) =>
        ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.Common.InjectWrappingDiv(src, width, height, border, isOmgMsg, classToAdd),
        );

    public openMobileAppWithFallback = async (
        url: string,
        fallbackURLAndroid: string,
        fallbackURLIOS: string,
    ) =>
        ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.Common.openMobileAppWithFallback(url, fallbackURLAndroid, fallbackURLIOS),
        );
}
