import Container from 'typedi';
import { NativeDataStoreService } from '../../src/modules/storage/implementations/data-store';

/**
 * Helper functions for getting and setting dataStore items (sessionStorage | localStorage)
 */
export default class DataStoreHelper {
    public static getNativeStore() {
        const nativeStorageService = Container.get(NativeDataStoreService);
        return nativeStorageService.get() || {};
    }

    public static hasNativeStore() {
        const nativeStorageService = Container.get(NativeDataStoreService);
        return !!nativeStorageService.get();
    }

    public static getNativeStoreItem(param: string) {
        const nativeStorageService = Container.get(NativeDataStoreService);

        const nativeStorage = nativeStorageService.get();

        return nativeStorage && nativeStorage[param];
    }

    public static setNativeStoreItem(param: string, value: any) {
        const nativeStorageService = Container.get(NativeDataStoreService);
        nativeStorageService.set({ [param]: value });
    }
}
