import {
    IObjectStorageService,
    ObjectStorageData,
} from '../../../../../Modules/Storage/interfaces';
import { IStoreService } from '../../interfaces';

export abstract class BaseStoreService implements IStoreService {
    protected readonly _storageItemService: IObjectStorageService;

    constructor(storageItemService: IObjectStorageService) {
        this._storageItemService = storageItemService;
    }

    public set = <T = any>(storeName: string, data: (T | Partial<T>) & ObjectStorageData<T>) => {
        this._storageItemService.upsert<T>(storeName, data);
    };

    public get = <T = any>(storeName: string): T => {
        return this._storageItemService.select<T>(storeName);
    };

    public pick = <T>(storeName: string, keys: Array<keyof T & string>): Partial<T> => {
        return this._storageItemService.selectPick<T>(storeName, keys);
    };

    public remove = (storeName: string) => {
        this._storageItemService.delete(storeName);
    };
}
