import { Service, Token } from 'typedi';
import { IPostMessageService } from './models';
import { Lock } from '../utils/lockUtil';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const PostMessageServiceToken = new Token<IPostMessageService>('PostMessageService');

@Service()
export class PostMessageServiceLoader extends BaseModuleLoader<IPostMessageService> {
    constructor() {
        super('PostMessageServiceLoader');
    }

    protected _createModuleInstance = async (): Promise<IPostMessageService> => {
        const { default: PostMessageService } = await import(
            /* webpackChunkName: "post-message/post-message.service" */
            './post-message.service'
        );
        return new PostMessageService();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IPostMessageService> => PostMessageServiceToken;
}
