import { Lock } from '../utils/lockUtil';
import { Service, Token } from 'typedi';
import { ICrossSell } from './models/ICrossSell';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const CrossSellToken = new Token<ICrossSell>('CrossSell');
@Service()
export class CrossSellLoader extends BaseModuleLoader<ICrossSell> {
    constructor() {
        super('CrossSellLoader');
    }

    protected _createModuleInstance = async (): Promise<ICrossSell> => {
        const module = await import(
            /* webpackChunkName: "cross-sell/cross-sell" */
            './cross-sell'
        );

        return new module.CrossSell();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<ICrossSell> => CrossSellToken;
}
