import { Service, Token } from 'typedi';
import { IPushController } from './models/IPushController';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import { Lock } from '../utils/lockUtil';

const moduleLock = new Lock();

export const PushToken = new Token<IPushController>('PushController');
@Service()
export class PushLoader extends BaseModuleLoader<IPushController> {
    constructor() {
        super('PushLoader');
    }

    protected _createModuleInstance = async (): Promise<IPushController> => {
        const { Push } = await import(
            /* webpackChunkName: "triple8/push" */
            './push'
        );

        return new Push();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IPushController> => PushToken;
}
