import { Lock } from '../utils/lockUtil';
import { Service, Token } from 'typedi';
import { ILoyaltyIndication } from './models/ILoyaltyIndication';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const LoyaltyIndicationToken = new Token<ILoyaltyIndication>('LoyaltyIndication');

@Service()
export class LoyaltyIndicationLoader extends BaseModuleLoader<ILoyaltyIndication> {
    constructor() {
        super('LoyaltyIndicationLoader');
    }

    protected _createModuleInstance = async (): Promise<ILoyaltyIndication> => {
        const { LoyaltyIndication } = await import(
            /* webpackChunkName: "loyalty-indication/loyalty-indication" */
            './loyalty-indication'
        );

        return new LoyaltyIndication();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<ILoyaltyIndication> => LoyaltyIndicationToken;
}
