export enum GeolocationElasticEvents {
    GL_Available_Start = 'geolocation-geolocationavailable-check-start',
    GL_Available_End = 'geolocation-geolocationavailable-check-end',
    GL_Installation_Start = 'geolocation-plugin-installation-start',
    GL_Installation_End = 'geolocation-plugin-installation-end',
    GL_Geolocation_Start = 'geolocation-getlocation-start',
    GL_Geolocation_End = 'geolocation-getlocation-end',
    GL_Plugin_Timeout = 'geolocation-plugin-timeout',
    GL_Plugin_Getlocation_Error = 'geolocation-getlocation-error',
    GL_InformLocation_Timeout = 'geolocation-informlocation-timeout',
    GL_InformLocation_Request = 'geolocation-informlocation-request',
    GL_InformLocation_Success_Response = 'geolocation-informlocation-success-response',
    GL_InformLocation_Fail_Response = 'geolocation-informlocation-fail-response',
    GL_InitGeo_Received = 'geolocation-initgeo-received',
    GL_Login_Geolocation_Data = 'geolocation-login-geolocationdata',
    GL_Update_Restriction = 'geolocation-updaterestriction-received',
    GL_Background_Restriction = 'geolocation-background-restriction',
    GL_Retry_Start = 'geolocation-retry-start',
    GL_Retry_API_Response = 'geolocation-retry-api-response',
    GL_Background_Restriction_Restricted = 'geolocation-background-restriction-restricted',
    GL_Data_Missing = 'geolocation-data-missing',
    GL_Performance_Login = 'geolocation-login-flow-finish',
    GL_Performance_Login_With_Plugin = 'geolocation-login-with-plugin-flow-finish',
    GL_Performance_Retry = 'geolocation-retry-flow-finish',
    GL_Performance_Interval = 'geolocation-interval-flow-finish',
    GL_Status_Update = 'geolocation-status-update',
    GL_Dialog_Show = 'geolocation-dialog-show',
    GL_Adapter_Loader = 'geolocation-adapter-loader',
    GL_Init_Flow = 'geolocation-init-flow',
    GL_Bet_Failed = 'geolocation-bet-failed',
    GL_Geolocation_Avialable = 'geolocation-available',
    GL_Desktop_Plugin_Not_Installed = 'geolocation-desktop-plugin-not-installed',
    GL_Handle_Result = 'geolocation-handle_result',
}

export enum GeocomplySDKEvents {
    Error = 'error',
    Log = 'log',
    Connect = 'connect',
    Geolocation = 'geolocation',
}

export enum GeolocationReason {
    Login = 'Login',
    Periodic = 'Periodic',
    User = 'User',
    Automatic = 'Automatic',
}

export enum RestrictionActions {
    Play = 3,
}

export enum RestrictionEntityValues {
    All = 'All',
    Spectate = '500002',
}

export enum RestrictionEntity {
    GameType = 2,
}

export enum GeoComplyClientFailureReason {
    NoWifi = 1,
    PlugInNotInstalled = 2,
    PlugInNotUpdated = 3,
    TechnicalProblem = 4,
    GCTimeout = 5,
}

export enum NativePluginStatus {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED',
    NoPermission = 'NO_APP_PERMISSION',
}

export enum NativeInterfaceGeolocationEvents {
    onLocationResponse = 'onLocationResponse',
    onLocationPermissionResponse = 'onLocationPermissionResponse',
    onGeoLocationResponseSuccess = 'onGeoLocationResponseSuccess',
    onGeoLocationResponseError = 'onGeoLocationResponseError',
    onBackToForeground = 'onBackToForeground',
}

export const InitGeoPostLoginAction = 'InitGeolocationFlow';

export const PLUGIN_CHECK_TIMEOUT = 600000; // 10 minutes
export const GEOLOCATION_REQUEST_TIMEOUT = 90000; // 1.5 minutes
export const RECHECK_PLUGIN_TIMEOUT = 10000; // 10 seconds
