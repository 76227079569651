import Container from 'typedi';
import { UserContextBase } from './user-context-base';
import { UserDataStoreDeferredObjectToken } from '../../injection-tokens';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import {
    LocalSimpleStoreService,
    SessionSimpleStoreService,
} from '../storage/implementations/simple-store';
import PageContextManager from 'page-context-manager';
import { ISessionUserData } from '../session-manager/interfaces/ISessionUserData';

export class UserContext extends UserContextBase {
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private _userDataStoreDeferredObject: DeferredObject<ISessionUserData>;

    constructor() {
        super();
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._userDataStoreDeferredObject = Container.get(UserDataStoreDeferredObjectToken);
        if (this._userDataStoreDeferredObject.resolved) {
            this.refresh();
        } else {
            this._userDataStoreDeferredObject.promise.then(() => this.refresh());
        }
    }

    public refresh = (): void => {
        this._logContext('[REFRESH] - Old context');
        const batchNumber = PageContextManager.getUserData().batchNumber;
        this._batchNumber = batchNumber || undefined;
        this.setAuthorizationToken();
        this._logContext('[REFRESH] - New context');
    };

    private _logContext = (message: string) => {
        const currentContext = {
            IsAuthenticated: this.IsAuthenticated,
            BatchNumber: this.BatchNumber,
        };
        this._logger.log(`${message}: ${JSON.stringify(currentContext)}`);
    };

    public setAuthorizationToken = (): void => {
        //TODO: Remove the use of sessionSimpleStoreService when remove SINGLE_TAB_LOGGED_IN
        let authorizationData = this._localSimpleStoreService.get('authorizationData');
        if (!authorizationData)
            authorizationData = this._sessionSimpleStoreService.get('authorizationData');
        const parsedAuthorizationData = authorizationData && JSON.parse(authorizationData);
        this._authorizationToken = parsedAuthorizationData?.token;
    };
}
