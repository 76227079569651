import Container, { Service } from 'typedi';
import { IDeviceTypeData, IUiChannel, MessageBroker } from '@sparkware/uc-sdk-core';

import DeviceType from '../../ui/enums/DeviceType';
import { Utils } from '../../utils';
import PageContextManager from 'page-context-manager';

@Service()
//TO DO move tracking utils service in tracking context
export class TrackingContext {
    private _deviceType: string;
    private _subBrandName: string;
    private _ucSessionCorrelationId: string;
    private readonly _uiChannel: IUiChannel;
    private _utils: Utils;

    public get SubBrandName() {
        return this._subBrandName;
    }

    public get DeviceType() {
        return this._getDeviceType();
    }

    constructor() {
        this._uiChannel = MessageBroker.getInstance().ui;
        this._utils = Container.get(Utils);

        this._uiChannel.topics.DeviceUpdate.subscribe((deviceType: IDeviceTypeData) => {
            this._deviceType = deviceType.Type;
        });

        this._subBrandName = PageContextManager.getBrandData().subBrandName;
    }

    public getUcSessionCorrelationId() {
        if (!this._ucSessionCorrelationId) {
            this._ucSessionCorrelationId = this._utils.getCorrelationId();
        }

        return this._ucSessionCorrelationId;
    }

    private _getDeviceType(): string {
        const deviceInformation = PageContextManager.getDeviceData();

        const deviceType = !this._deviceType
            ? deviceInformation?.isMobile
                ? DeviceType.Mobile
                : DeviceType.PC
            : this._deviceType;

        return deviceType;
    }
}
