import PageContextManager from 'page-context-manager';
import { Service } from 'typedi';

@Service()
export class TrackingUtilsService {
    getReportingParameters() {
        const { brandId, subBrandId } = PageContextManager.getBrandData();
        const { clientVersion, productPackage } = PageContextManager.getDeviceData();
        const { country } = PageContextManager.getGlobalizationData();
        const { currency, langId } = PageContextManager.getLocalizationData();
        const { globalSessionId, playerSessionId } = PageContextManager.getSessionData();
        const { environment } = PageContextManager.getEnvironmentData();
        const { cid } = PageContextManager.getUserData();

        return {
            brand: brandId,
            version: clientVersion,
            country: country,
            currency: currency,
            environment: environment,
            globalSessionId: globalSessionId,
            language: langId,
            parentProductPackage: productPackage,
            playerSessionId: playerSessionId,
            productPackage: productPackage,
            productPackageId: productPackage,
            subBrand: subBrandId?.toString(),
            cid: cid,
        };
    }

    getLogV2SessionData() {
        const { globalSessionId, playerSessionId } = PageContextManager.getSessionData();
        return { globalSessionID: globalSessionId, playerSessionID: playerSessionId };
    }

    getLogV2UserData() {
        const { brandId, brandName } = PageContextManager.getBrandData();
        const { cid } = PageContextManager.getUserData();

        return { cid: cid, brand: brandName, brandID: brandId };
    }
}
