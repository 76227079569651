import Container from 'typedi';
import { ILogger, LoggerProvider } from '../logger';
import { IGetPendingAction } from './interfaces';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
    Level,
} from '@unified-client/event-formatter';
import { IClickStreamEventData } from '../tracking/models/IClickStreamEventData';
import PendingApi from '../../../APIs/PendingApi';
import { PENDING_INIT_TIMEOUT } from '../../models/enums/Consts';
import { CommonService } from '../external/clients-framework';
import { Utils } from '../utils';
import { ClickStreamTrackingProvider } from '../tracking';
import { WindowToken } from '../../injection-tokens';
import { ProductPackageToID } from '@sparkware/uc-sdk-core';
import { IMrGreenMigration } from '../mr-green-migration/models/interfaces/IMrGreenMigration';
import PageContextManager from 'page-context-manager';
import { LoaderManager } from '../../loaders/LoaderManager';

export interface IPendingActionState {
    closed: boolean;
    completed: boolean;
    error: string;
}
export class PendingAction {
    private readonly _logger: ILogger;
    private readonly _pendingAction: IGetPendingAction;
    private readonly _pendingActionDO: DeferredObject<IPendingActionState>;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private _initPendingTimeout: number;
    private _cfCommonService: CommonService;
    private _utils: Utils;
    private _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private _windowToken: Window;

    private get _mrGreenMigrationPromise(): Promise<IMrGreenMigration> {
        return LoaderManager.Instance.MrGreenMigrationLoader.Instance;
    }

    constructor(pendingAction?: IGetPendingAction) {
        this._logger = Container.get(LoggerProvider).getLogger('PendingState');
        this._pendingAction = pendingAction;
        this._pendingActionDO = new DeferredObject<IPendingActionState>({ storeResolved: true });
        this._cfCommonService = Container.get(CommonService);
        this._utils = Container.get(Utils);
        this._windowToken = Container.get(WindowToken);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('PendingAction');
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
    }

    public execute = async (
        specificNavigation: string,
        pendingToken: string,
        closeBtnRequired?: boolean,
        onClose?: Function,
    ): Promise<IPendingActionState> => {
        const { response, errorResponse } = await PendingApi.HandlePending();

        if (response) {
            this._onSuccess(
                response,
                pendingToken,
                closeBtnRequired,
                onClose,
                ProductPackageToID.PENDING,
                specificNavigation,
            );
        } else {
            this._onError(errorResponse);
        }

        return this._pendingActionDO.promise;
    };

    private _onSuccess = async (
        response,
        pendingToken,
        closeBtnRequired,
        onClose,
        pendingProductPackage,
        specificNavigation,
    ) => {
        const formData = {
            ...response,
            UserInfo: pendingToken,
            pendingProductPackage,
            specificNavigation,
        };

        await this._openContainer(closeBtnRequired, onClose);
        const form: HTMLFormElement = await this._createForm(formData);

        document.body.append(form);
        form.submit();
        form.remove();

        this._initPendingTimeout = this._windowToken.setTimeout(
            this._onInitTimeOutHandler,
            PENDING_INIT_TIMEOUT,
        );
    };

    private _onError = (e) => {
        this._logger.error(`_onError: Error handling pending action. Error: `, e);
        this.resolve({
            closed: true,
            completed: false,
            error: 'Failed to get autologin data for pending!',
        });
    };

    private _createForm = async (res: any): Promise<HTMLFormElement> => {
        const { isMobile, productPackage } = PageContextManager.getDeviceData();
        const { mitIdSwitchBackUrl } = PageContextManager.getSiteData();
        const isNative = this._utils.findIfIsNative();
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', '' + res.GnwpUrl + '');
        form.setAttribute('target', 'generalIframe');
        form.setAttribute('style', 'display:none');

        const params: any = {
            UserInfo: res.UserInfo,
            LangID: res.LangID,
            ClientVersion: res.ClientVersion,
            PlatformID: res.PlatformID,
            BrandId: res.BrandID,
            SourceProductPackage: productPackage,
            TargetProductPackage: res.pendingProductPackage,
            CurrencyCode: res.CurrencyCode,
            SourceSubBrand: res.SubBrandID,
            TargetSubBrand: res.SubBrandID,
            IsRealMode: res.IsRealMode,
            SpecificNavigation: res.specificNavigation,
        };

        if (mitIdSwitchBackUrl && isNative) {
            params.SwitchBackUrl = mitIdSwitchBackUrl;
        }

        if (isMobile) {
            params.LinkID = 100;
        }

        const mrGreenMigration = await this._mrGreenMigrationPromise;
        if (mrGreenMigration) {
            params.BusinessCorrelationId = mrGreenMigration.BusinessCorrelationId;
        }

        const formattedParams = Object.keys(params).map((key) => ({
            key: key,
            value: String(params[key]),
        }));

        formattedParams.forEach(function (param) {
            const hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', param.key);
            hiddenField.setAttribute('value', param.value);
            form.append(hiddenField);
        });

        return form;
    };

    private _openContainer = async (closeBtnRequired: boolean, onClose: Function) => {
        const isMobile = PageContextManager.getDeviceData().isMobile;

        const onContainerDivClose = () => {
            this.resolve({
                closed: true,
                completed: false,
                error: null,
            });
            onClose && onClose();
        };

        //build a dynamic form and post it
        if (isMobile) {
            await this._cfCommonService.OpenMobileContainerDiv(
                '',
                closeBtnRequired,
                onContainerDivClose,
            );
            $('#mobileOverlay').css('z-index', 2001); //Should be bigger than mobile betslip (2000 currently)
        } else {
            if (closeBtnRequired) {
                await this._cfCommonService.InjectWrappingDivWithCloseButton(
                    null,
                    1008,
                    700,
                    false,
                    onContainerDivClose,
                    null,
                    'pending',
                );
            } else {
                await this._cfCommonService.InjectWrappingDiv(
                    null,
                    1040,
                    700,
                    false,
                    null,
                    'pending',
                );
            }
        }
    };

    private _onInitTimeOutHandler = () => {
        clearTimeout(this._initPendingTimeout);
        const message =
            'Pending app init timed out - did not receive an onReady message from Pending';
        this._logger.error(message);
        this._sendElasticEvent('pending-app-init-timeout', message, false, Level.error);
        const state: IPendingActionState = {
            closed: true,
            completed: false,
            error: 'timeout',
        };

        this.resolve(state);
    };

    private _sendElasticEvent = (
        eventName: string,
        message: string,
        isOk?: boolean,
        level: Level = Level.information,
    ) => {
        const formatter = this._eventFormatterBuilder.createFormatter('PendingService');
        const eventData: IClickStreamEventData = {
            event: eventName,
            ...(isOk && { isOk }),
        };
        const event = formatter.formatUCEvent(
            { message: message },
            { correlationID: this._utils.generateCorrelationID(), ...(level && { level }) },
            { ...eventData },
        );
        this._clickStreamTrackingProvider.sendEventV2(event);
    };

    public rendered = (isOk: boolean) => {
        clearTimeout(this._initPendingTimeout);
        const message = 'Pending init - isOk: ' + isOk;
        this._logger.info(message);
        this._sendElasticEvent(
            'pending-app-init-result',
            message,
            isOk,
            isOk ? Level.information : Level.error,
        );
    };

    public resolve = (state: IPendingActionState) => {
        this._logger.log(
            `resolve: Pending action deferred object was resolved with the following state:`,
            state,
        );
        this._pendingActionDO.resolve(state);
    };
}
