import Container, { Service } from 'typedi';

import { ICookieOptions } from '../models/ICookieOptions';
import { ILogger, LoggerProvider } from '../../logger';
import Cookies from 'js-cookie';
import { UrlUtils } from '../../utils/urlUtils';

@Service()
export class CookieStoreItemService {
    protected _logger: ILogger;
    private readonly _urlUtils: UrlUtils;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('CookieStoreItemService');
        this._urlUtils = Container.get(UrlUtils);
    }

    public get = (key: string): string | undefined => {
        return Cookies.get(key);
    };

    public set = (key: string, value: string, options: ICookieOptions): void => {
        Cookies.set(key, value, options);
    };

    public remove = (key: string): void => {
        const domain = this._urlUtils.getDomain();
        Cookies.remove(key, { domain });
    };
}
