import { ISimpleStorageService, SimpleStorageData } from '../interfaces';

export class WebSimpleStorageService implements ISimpleStorageService {
    constructor(private readonly _storage: Storage) {}

    public upsert = (key: string, data: SimpleStorageData) => {
        const storeData = data?.toString();

        this._storage.setItem(key, storeData);
    };

    public select = (key: string): string => {
        const storeData = this._storage.getItem(key);

        return storeData;
    };

    public delete = (key: string) => this._storage.removeItem(key);
}

export class WebWindowSimpleStorageService implements ISimpleStorageService {
    constructor(private readonly _window: Window) {}

    public upsert = (key: string, data: SimpleStorageData) => {
        this._window[key] = data?.toString();
    };

    public select = (key: string): string => {
        const storeData = this._window[key];

        return storeData?.toString();
    };

    public delete = (key: string) => delete this._window[key];
}
