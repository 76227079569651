import {
    IThemeModeUpdateData,
    IUserInteractionChannel,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import Container, { Service } from 'typedi';

import { ILogger, LoggerProvider } from '../logger';
import { LocalStoreService } from '../storage/implementations/store';
import { Utils } from '../utils';
import { UrlUtils } from '../utils/urlUtils';
import { IThemeMode } from './models';
import PageContextManager from 'page-context-manager';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';
import { FeatureAvailabilityToken } from '../../injection-tokens';
import { Features } from '../../models/enums/Consts';
import { StorageItemEnum } from '../../models/enums/storage-enums';

@Service()
export class ThemeService {
    private readonly _logger: ILogger;

    private readonly _userInteractionChannel: IUserInteractionChannel;

    private readonly _localStoreService: LocalStoreService;
    private readonly _utils: Utils;
    private readonly _urlUtils: UrlUtils;
    private _featureAvailability: IFeatureAvailability;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ThemeService');

        this._userInteractionChannel = MessageBroker.getInstance().userinteraction;

        this._localStoreService = Container.get(LocalStoreService);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
    }

    public findIfIsThemeModeEnabled = (): boolean => {
        this._logger.debug('Begin find if is theme mode enabled');

        const isThemeModeEnabled = this._featureAvailability.IsFeatureEnabled(Features.THEME_MODE);

        this._logger.debug(
            `End in finding if is theme mode enabled, return: '${isThemeModeEnabled}'`,
        );

        return !!isThemeModeEnabled;
    };

    public getThemeModeOrDefault = (): IThemeMode => {
        this._logger.debug('Begin get current/default theme mode');

        const getThemeModeArray = [];
        getThemeModeArray.push(this.getThemeMode);

        const isNative = this._utils.findIfIsNative();
        if (isNative) {
            getThemeModeArray.push(this.getNativeSystemThemeMode);
            getThemeModeArray.push(this.getNativeThemeMode);
        }

        getThemeModeArray.push(this.getDefaultThemeMode);

        let themeMode = null;

        for (let i = 0; i < getThemeModeArray.length; i++) {
            const getThemeMode = getThemeModeArray[i];

            themeMode = getThemeMode();

            if (themeMode) break;
        }

        this._logger.debug(
            `End in getting current/default theme mode, return: '${JSON.stringify(themeMode)}'`,
        );

        return themeMode;
    };

    public getThemeMode = (): IThemeMode => {
        this._logger.debug('Begin get current theme mode');

        const storedThemeMode = this._localStoreService.get<IThemeMode>('ThemeMode');

        this._logger.debug(
            `End in getting current theme mode, return: '${JSON.stringify(storedThemeMode)}'`,
        );

        return storedThemeMode;
    };

    public getNativeThemeMode = (): IThemeMode => {
        this._logger.debug('Begin get current native theme mode');

        const storedThemeMode = this._localStoreService.get<IThemeMode>(
            StorageItemEnum.NativeThemeMode,
        );

        this._logger.debug(
            `End in getting current native theme mode, return: '${JSON.stringify(
                storedThemeMode,
            )}'`,
        );

        return storedThemeMode;
    };

    public getNativeSystemThemeMode = (): IThemeMode => {
        this._logger.debug('Begin get native system theme mode');

        let themeMode = null;

        const queryStringThemeModeCode = this._urlUtils.getQueryStringParameterByName('themeMode');

        if (queryStringThemeModeCode) {
            const themeModeCode = parseInt(queryStringThemeModeCode);

            if (themeModeCode) themeMode = this.parseThemeMode(themeModeCode);
        } else {
            themeMode = this.getNativeThemeMode();
        }

        this._logger.debug(
            `End in getting native systhem theme mode, return: '${JSON.stringify(themeMode)}'`,
        );

        return themeMode;
    };

    public getDefaultThemeMode = (): IThemeMode => {
        this._logger.debug('Begin get default theme mode');

        let defaultThemeMode = null;

        const storedDefaultThemeMode = PageContextManager.getThemeData().defaultMode;

        if (storedDefaultThemeMode) defaultThemeMode = storedDefaultThemeMode;
        else this._logger.error('Error in getting default theme mode, no theme mode found');

        this._logger.debug(
            `End in getting default theme mode, return: '${JSON.stringify(defaultThemeMode)}'`,
        );

        return defaultThemeMode;
    };

    public setThemeMode = (themeMode: IThemeMode) => {
        this._logger.debug('Begin set current theme mode');

        this._localStoreService.set(StorageItemEnum.ThemeMode, themeMode);

        this._logger.debug(
            `End in settings current theme mode, set: '${JSON.stringify(themeMode)}'`,
        );
    };

    public setNativeThemeMode = (themeMode: IThemeMode) => {
        this._logger.debug('Begin set current native theme mode');

        this._localStoreService.set(StorageItemEnum.NativeThemeMode, themeMode);

        this._logger.debug(
            `End in settings current native theme mode, set: '${JSON.stringify(themeMode)}'`,
        );
    };

    public parseThemeMode = (themeModeCode: number): IThemeMode => {
        this._logger.debug('Begin parse theme mode');

        let themeMode = null;

        const themeModeSettings = this.getThemeModeSettings();
        const themeModeSetting = themeModeSettings?.find(
            (setting) => setting.code == themeModeCode,
        );

        if (themeModeSetting) themeMode = themeModeSetting;

        if (!themeMode)
            this._logger.error('Error in parsing theme mode, no matching theme mode found');

        this._logger.debug(`End in parsing theme mode, set: '${JSON.stringify(themeMode)}'`);

        return themeMode;
    };

    public getThemeModeSettings = (): Array<IThemeMode> => {
        this._logger.debug('Begin get theme modes');

        let themeModes = null;

        const storedThemeModes = PageContextManager.getThemeData().modes;

        if (storedThemeModes) themeModes = storedThemeModes;

        this._logger.debug(`End in getting theme modes, return: '${JSON.stringify(themeModes)}'`);

        return themeModes;
    };

    public sendThemeModeUpdate = (themeMode: IThemeMode) => {
        this._logger.debug('Begin send theme mode updated');

        const themeModeUpdateData: IThemeModeUpdateData = { themeMode: themeMode.code };

        this._userInteractionChannel.topics.themeModeUpdate.publish(
            { publisher: 'ThemeService' },
            themeModeUpdateData,
        );

        this._logger.debug(
            `End in sending theme mode update, sent data: '${JSON.stringify(themeModeUpdateData)}'`,
        );
    };
}
