import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../../logger';
import {
    ICrmChannel,
    ICtaExternalLinkOpenTopicPayload,
    ICtaRichOpenTopicPayload,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import { ICRM } from '../../crm/models';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class CRMChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _crmChannel: ICrmChannel;
    private get _crmPromise(): Promise<ICRM> {
        return LoaderManager.Instance.CRMLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('LoyaltyIndicationSubscriber');
        this._crmChannel = MessageBroker.getInstance().crm;
        this._crmChannel.topics.CTA_ExternalLink_Open.subscribe(
            this.onCTAExternalLinkOpen.bind(this),
        );
        this._crmChannel.topics.CTA_RICH_Open.subscribe(this.onCTARichOpen.bind(this));
    }

    private async onCTAExternalLinkOpen(externalLinkModel: ICtaExternalLinkOpenTopicPayload) {
        (await this._crmPromise)?.onExternalLinkOpen(externalLinkModel);
    }

    private async onCTARichOpen(richModel: ICtaRichOpenTopicPayload) {
        (await this._crmPromise)?.onRichOpen(richModel);
    }
}
