import {
    ISimpleStorageService,
    SimpleStorageData,
} from '../../../../../Modules/Storage/interfaces';

export abstract class BaseSimpleStoreService {
    protected readonly _simpleStorageService: ISimpleStorageService;

    constructor(simpleStorageService: ISimpleStorageService) {
        this._simpleStorageService = simpleStorageService;
    }

    public set = (key: string, data: SimpleStorageData) => {
        this._simpleStorageService.upsert(key, data);
    };

    public get = (key: string): string => {
        return this._simpleStorageService.select(key);
    };

    public remove = (key: string) => {
        this._simpleStorageService.delete(key);
    };
}
