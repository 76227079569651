export enum AuthenticationActionType {}

export enum AuthenticationFlowType {
    Regular = 'regular',
    Cross = 'cross',
    Biometrics = 'biometrics',
    Pending = 'pending',
    Registration = 'registration',
    Migration = 'migration',
    LocationConfirmation = 'location-confirmation',
    FitToPlay = 'fit-to-play',
    Web = 'web',
    AppUpgradeRequired = 'app-upgrade-required',
}

export enum AuthenticationEncryptionType {
    None = 1,
    TEA = 3,
    XTinyEncryption = 4,
    Token = 5,
    Nemo = 7,
    TouchId = 8,
}

export enum PlayMode {
    All = 0,
    Demo = 1,
    Real = 2,
}

export enum AuthenticationType {
    None = 0,
    Login = 1,
    Reconnection = 2,
    AutoLogin = 3,
    SwitchFromRealToDemo = 4,
    SwitchFromDemoToReal = 5,
    ValidateCredentials = 6,
    History = 7,
    EnterGame = 8,
}

export enum MigrationProductPackageEnum {
    Kambi = 9,
    Spectate = 112,
}

export enum AuthenticationPerformanceMarks {
    AuthenticationStart = 'authentication-start',
    ClientAuthenticationStart = 'client-authentication-start',
}
export enum AuthenticationPerformanceMeasures {
    AuthenticationTime = 'authentication-time',
    ClientAuthenticationTime = 'authentication-time',
    AuthenticationTimeFromPageHit = 'authentication-time-from-start',
    ClientAuthenticationTimeFromPageHit = 'client-authentication-time-from-start',
}

export enum AuthenticationMessage {
    Authentication = 'authentication',
    ClientAuthentication = 'client-authentication',
}
