import {
    AuthenticationFlowType,
    AuthenticationMessage,
    AuthenticationPerformanceMarks,
    AuthenticationPerformanceMeasures,
} from '../enums';
import { ClientIntegrationFacadeToken, FeatureAvailabilityToken } from '../../../injection-tokens';
import Container, { Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import {
    IAuthenticationResult,
    IBaseLoginReponseModel,
    IClientAuthenticationEvent,
    IClientAuthenticationResponseModel,
    IGradualLaunchModel,
    IMeasuredAuthenticationResult,
} from '../models';

import { ClickStreamTrackingProvider } from '../../tracking';
import { PerformanceManager } from '../../performance/performance-manager';
import { StorageItemEnum } from '../../../models/enums/storage-enums';
import { Utils } from '../../utils';
import { CookieStoreItemService } from '../../storage/implementations';
import {
    ClientsFrameworkLogoutService,
    ClientsFrameworkOpenClientProductService,
    CommonService,
} from '../../external/clients-framework';
import StringUtils from '../../../../Modules/Utils/StringUtils';
import {
    LocalSimpleStoreService,
    SessionSimpleStoreService,
} from '../../storage/implementations/simple-store';
import { IFeatureAvailability } from '../../feature/feature-availability/feature-availability-interface';
import { GeolocationUtils } from '../../geolocation/utils';
import { IClientIntegrationFacade } from '../../client-integration/interfaces/IClientIntegrationFacade';
import PageContextManager, { AuthenticatedPageContextData } from 'page-context-manager';
import { BaseSimpleStoreService } from '../../storage/implementations/simple-store/BaseSimpleStoreService';
import { Features } from '../../../models/enums/Consts';

@Service()
export class AuthenticationUtils {
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;
    private readonly _performanceManager: PerformanceManager;
    private readonly _trackingProvider: ClickStreamTrackingProvider;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private readonly _utils: Utils;
    private readonly _cookieStoreItemService: CookieStoreItemService;
    private readonly _cfCommonService: CommonService;
    private readonly _cfLogoutService: ClientsFrameworkLogoutService;
    private readonly _cfOpenClientProductService: ClientsFrameworkOpenClientProductService;
    private readonly _featureAvailability: IFeatureAvailability;
    private readonly _simpleStoreService: BaseSimpleStoreService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;

    constructor() {
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._performanceManager = Container.get(PerformanceManager);
        this._trackingProvider = Container.get(ClickStreamTrackingProvider);
        this._utils = Container.get(Utils);
        this._cookieStoreItemService = Container.get(CookieStoreItemService);
        this._cfLogoutService = Container.get(ClientsFrameworkLogoutService);
        this._cfOpenClientProductService = Container.get(ClientsFrameworkOpenClientProductService);
        this._cfCommonService = Container.get(CommonService);
        this._featureAvailability = Container.get(FeatureAvailabilityToken);

        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('AuthenticationUtils');

        if (this._featureAvailability.IsFeatureEnabled(Features.SINGLE_TAB_LOGGED_IN)) {
            this._simpleStoreService = Container.get(SessionSimpleStoreService);
        } else {
            this._simpleStoreService = Container.get(LocalSimpleStoreService);
        }
    }

    public setAuthenticatedPageContextData = (result: AuthenticatedPageContextData) => {
        PageContextManager.updatePageContextAuthenticated(result);
    };

    public updateStorageIndications = () => {
        this._simpleStoreService.remove(StorageItemEnum.PostLoginActionsInterrupted);
    };

    public updateAuthorizationData = (result: IBaseLoginReponseModel) => {
        const authorizationData = JSON.stringify({ token: result.BearerToken });
        const expirationData = JSON.stringify({ token: result.RefreshToken });
        const authorizationSessionId = result.FullResponse.SessionId;

        this._simpleStoreService.set(StorageItemEnum.AuthorizationData, authorizationData);
        this._simpleStoreService.set(StorageItemEnum.ExpirationData, expirationData);
        this._simpleStoreService.set(
            StorageItemEnum.AuthorizationSessionId,
            authorizationSessionId,
        );
    };

    public removeAuthorizationData = () => {
        this._simpleStoreService.remove(StorageItemEnum.AuthorizationData);
        this._simpleStoreService.remove(StorageItemEnum.ExpirationData);
        this._simpleStoreService.remove(StorageItemEnum.AuthorizationSessionId);
    };

    public updateLegacyStorageIndications = async (result: IBaseLoginReponseModel) => {
        this._sessionSimpleStoreService.set(StorageItemEnum.CID, String(result.Cid));

        this._cookieStoreItemService.set('lastLogin', '888Login', { expires: 30 });

        this._sessionSimpleStoreService.set(StorageItemEnum.LoginNotification, 'true');
        this._sessionSimpleStoreService.remove(StorageItemEnum.BackButtonData);
    };

    public sendPlayerStatus = () => {
        const eventData = { category: 'Conversion', action: 'Player status', label: 'Logged in' };

        this._clientIntegrationFacade.sendAnalytics(eventData);
    };

    public setGeolocationData = (result: IBaseLoginReponseModel): void => {
        GeolocationUtils.setGeolocationData(result?.GeolocationData);
    };

    public processGradualLaunch = async (
        result: IBaseLoginReponseModel,
    ): Promise<IGradualLaunchModel> => {
        let authenticateData: IGradualLaunchModel = null;
        if (!result) return authenticateData;

        if (result.RedirectToProductPackage) {
            const targetProductPackage = result.RedirectToProductPackage;
            const SubBrandId = PageContextManager.getBrandData().subBrandId;

            await this._cfOpenClientProductService.clientAutoLogin(
                null,
                SubBrandId,
                targetProductPackage,
                1,
                null,
                '_self',
            );

            await this._cfLogoutService.cleanUserStorageItems();
            await this._cfLogoutService.removeLobbyCookie();

            return authenticateData;
        }

        authenticateData = Object.assign(
            {},
            {
                CID: result.Cid,
                Token: result.Token,
            },
        );

        if (result?.FullResponse?.SportMigrationData) {
            const { HasOlderBets, BatchNumber } = result.FullResponse.SportMigrationData;
            authenticateData.HasKambiBets = HasOlderBets;
            authenticateData.BatchNumber = BatchNumber;
        }

        return authenticateData;
    };

    public removeRealityCheckTimeout = () => {
        if (this._simpleStoreService.get(StorageItemEnum.RealityCheckTimeout) !== undefined) {
            this._simpleStoreService.remove(StorageItemEnum.RealityCheckTimeout);
        }
    };

    public setBossMode = (response) => {
        if (response?.BearerToken && response?.IsBossMode) {
            this._simpleStoreService.set('IsBossMode', String(true));
        }
    };

    public measureAuthenticationPerformance = async <T>(
        method: () => Promise<T>,
        startMark: AuthenticationPerformanceMarks,
        authenticationMeasure: AuthenticationPerformanceMeasures,
        authenticationFromPageHitMeasure: AuthenticationPerformanceMeasures,
        includeLoadingTime: boolean = false,
    ): Promise<IMeasuredAuthenticationResult<T>> => {
        const measurePromise = this._performanceManager.measurePerformanceAsync(
            method,
            authenticationMeasure,
            startMark,
        );

        let measureFromStartPromise = Promise.resolve(null);

        let durationFromPageHit = undefined;
        const { result, duration } = await measurePromise;

        if (includeLoadingTime) {
            measureFromStartPromise = this._performanceManager.measurePerformanceAsync(
                () => measurePromise,
                authenticationFromPageHitMeasure,
            );
        }

        if (includeLoadingTime) {
            const { duration } = await measureFromStartPromise;
            durationFromPageHit = duration;
        }

        return {
            result,
            duration,
            durationFromPageHit,
        };
    };

    public sendAuthenticationEvent = <T extends IBaseLoginReponseModel>(
        authType: AuthenticationFlowType,
        result: IAuthenticationResult<T>,
        duration: number,
        durationFromPageHit: number,
        correlationID: string,
    ) => {
        const includeLoadingTime = !!durationFromPageHit;
        const durationInMS = duration;
        const { response, errorResponse, inputParams } = result;
        const errorCode =
            response.Response?.ErrorCode || response.Response?.FullResponse?.ErrorCode;

        const eventData: any = {
            isOk: !!response.Response?.BearerToken,
            errorCode: !errorCode || isNaN(Number(errorCode)) ? 0 : Number(errorCode),
            errorCodeString: StringUtils.toString(errorCode),
            errorDescription: StringUtils.toString(
                response.Response?.ErrorDescription ||
                    response.Response?.ErrorMessage ||
                    response.Response?.FullResponse?.ErrorDescription ||
                    errorResponse,
            ),
            authType,
            includeLoadingTime,
            inputParams: this.sanitizeInputParams(inputParams),
        };

        if (includeLoadingTime) {
            eventData.durationInMSFromPageHit = durationFromPageHit;
        }

        const formatter = this._eventFormatterBuilder.createFormatter('login');
        const clickStreamEvent = formatter.formatUCEvent(
            { message: AuthenticationMessage.Authentication, durationInMS },
            { correlationID: correlationID || this._utils.generateCorrelationID() },
            eventData,
        );

        this._trackingProvider.sendEventV2(clickStreamEvent);
    };

    public sendClientAuthenticationEvent = (
        authType: AuthenticationFlowType,
        result: IClientAuthenticationResponseModel,
        duration,
        durationWrapperAndClient,
        durationFromPageHit,
        correlationID,
    ) => {
        const includeLoadingTime = !!durationFromPageHit;
        const durationInMS = duration;
        const errorCode = result.errorCode;

        const eventData: IClientAuthenticationEvent = {
            isOk: result.isOk,
            errorCode: !errorCode || isNaN(Number(errorCode)) ? undefined : Number(errorCode),
            errorCodeString: StringUtils.toString(errorCode),
            errorDescription: StringUtils.toString(result.errorDescription),
            authType,
            includeLoadingTime,
            durationWrapperAndClientinMS: durationWrapperAndClient,
        };

        if (includeLoadingTime) {
            eventData.durationInMSFromPageHit = durationFromPageHit;
        }

        const formatter = this._eventFormatterBuilder.createFormatter('login');
        const clickStreamEvent = formatter.formatUCEvent(
            { message: AuthenticationMessage.ClientAuthentication, durationInMS },
            { correlationID: correlationID || this._utils.generateCorrelationID() },
            eventData,
        );

        this._trackingProvider.sendEventV2(clickStreamEvent);
    };

    public sanitizeInputParams = (inputParams: string): string => {
        const inputParamsObj: any = JSON.parse(inputParams);
        inputParamsObj.AuthenticationInfo = inputParamsObj.AuthenticationInfo && '#SECRET#';
        inputParamsObj.UserInfo = inputParamsObj.UserInfo && '#SECRET#';

        return JSON.stringify(inputParamsObj);
    };

    public closeIframeContainers = (): Promise<void> => this._cfCommonService.CloseWrapDiv();

    public resetABFeatures = () => {
        this._featureAvailability.ResetActiveFeatures();
    };

    public getAuthorizationData = () => {
        return this._simpleStoreService.get(StorageItemEnum.AuthorizationData);
    };

    public getExpirationData = () => {
        return this._simpleStoreService.get(StorageItemEnum.ExpirationData);
    };

    public setExpirationData = (expirationData) => {
        this._simpleStoreService.set(StorageItemEnum.ExpirationData, expirationData);
    };
}
