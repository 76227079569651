import { Service } from 'typedi';

@Service()
export class FeatureNativeStorage {
    private _abTestFeatures: any;
    constructor() {
        this._abTestFeatures = null;
    }

    get ABTestFeatures() {
        return this._abTestFeatures;
    }

    set ABTestFeatures(abTestFeatues) {
        this._abTestFeatures = abTestFeatues;
    }
}
