import Container, { Service } from 'typedi';
import { ClientsFrameworkLibrary } from 'library-manager';

import { ILogger, LoggerProvider } from '../../logger';
import { IClientsFrameworkPokerNativeService } from './interfaces';

@Service()
export class ClientsFrameworkPokerNativeService implements IClientsFrameworkPokerNativeService {
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientsFrameworkPokerNativeService',
        );
    }

    public onSiteLoaded = async () => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.PokerNativeInterface.onSiteLoaded(),
        );
    };

    public onAutologinSuccess = async () => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.PokerNativeInterface.onAutologinSuccess(),
        );
    };

    public onAutologinFailed = async () => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.PokerNativeInterface.onAutologinFailed(),
        );
    };

    public sendMessage = async (payload: any) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.PokerNativeInterface.sendMessage(payload),
        );
    };

    public performUCAction = async (payload: any) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.PokerNativeInterface.performUCAction(payload),
        );
    };
}
