import PageContextManager from 'page-context-manager';
import { ClientIntegrationFacade } from './client-integration.facade';

export class SpectateClientIntegrationFacade extends ClientIntegrationFacade {
    constructor() {
        super();
        this.navigatePublish = this.navigatePublish.bind(this);
    }

    public getPlayerSegmentation = () => {
        const clientLangIso3 = PageContextManager.getLocalizationData().clientLangIso3;

        return {
            ...this.getPlayerSegmentationInitial(),
            languageCode: clientLangIso3,
        };
    };

    public override navigatePublish(uri: string): void {
        var formattedUri = this._urlUtils.getRelativeURL(uri, {
            removeLanguage: true,
            removeBaseUrl: true,
        });
        super.navigatePublish(formattedUri);
    }
}
