import { AppIdentifiers } from '@sparkware/uc-sdk-core';

export enum AppEligibilityPropertyEnum {
    DepositWidget = 'DepositWidget',
    WithdrawalWidget = 'WithdrawalWidget',
    TransactionHistoryWidget = 'TransactionHistoryWidget',
}
export const AppEligibilityPropertyMapping = {
    [AppIdentifiers.UnifiedCashier]: AppEligibilityPropertyEnum.DepositWidget,
    [AppIdentifiers.UnifiedCashout]: AppEligibilityPropertyEnum.WithdrawalWidget,
    [AppIdentifiers.TransactionHistory]: AppEligibilityPropertyEnum.TransactionHistoryWidget,
};
