import { Container, Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { ISessionManager } from './interfaces/ISessionManager';
import { Utils } from '../utils';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const SessionManagerToken = new Token<ISessionManager>('SessionManager');

@Service()
export class SessionManagerLoader extends BaseModuleLoader<ISessionManager> {
    private readonly _utils: Utils;

    constructor() {
        super('SessionManagerLoader');
        this._utils = Container.get(Utils);
    }

    protected _createModuleInstance = async (): Promise<ISessionManager> => {
        const isNativeSdk = this._utils.isNativeSDK();
        const isNative = this._utils.findIfIsNative();
        if (isNativeSdk) {
            const { SessionManagerNSDK } = await import(
                /* webpackChunkName: "session-manager/session-manager-nsdk" */
                './session-manager-nsdk'
            );
            return new SessionManagerNSDK();
        } else if (isNative) {
            const { SessionManagerNative } = await import(
                /* webpackChunkName: "session-manager/session-manager-native" */
                './session-manager-native'
            );
            return new SessionManagerNative();
        } else {
            const { SessionManager } = await import(
                /* webpackChunkName: "session-manager/session-manager" */
                './session-manager'
            );
            return new SessionManager();
        }
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<ISessionManager> => SessionManagerToken;
}
