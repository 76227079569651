import 'reflect-metadata';

import { ClientInitToken, ClientSettingsToken } from './injection-tokens';
import {
    setLoaded,
    sendCorrelationEvent,
    addMaintenance,
    setDocumentEventsListeners,
    setEntryLoaded,
    setLastProductCookie,
} from './modules/client-integration/init';

import { ClientProvider, CloseSessionReason } from './models/enums/Consts';
import { Container } from 'typedi';
import { MessageBroker } from '@sparkware/uc-sdk-core';
import { PerformanceManager } from './modules/performance/performance-manager';
import { exportFeatureChecks } from './modules/feature';
import { getAllowAttr } from '@888webteam/iframe-allow-camera';
import { initMessageBroker } from './modules/client-integration/init/registrations/message-broker';
import { pushDataLayerData } from './modules/client-integration/init/registrations/data-layer';
import {
    setMBServices,
    setMutualServices,
} from './modules/client-integration/init/registrations/services/services';
import { setClientServices } from './modules/client-integration/init/registrations/services/client-services';
import { addSettings } from './modules/client-integration/init/registrations/client-settings';
import { getClientInitObject } from './modules/client-integration/init/registrations/client-init';
import { EnvAccessAuthentication } from './modules/environment-access-auth';
import { B2CIntegration } from './modules/b2c-integration';
import { ContentAdapter } from './modules/b2c-integration/content-adapter';
import { NativeUtils } from './modules/native/native.utils';
import { LoaderManager } from './loaders/LoaderManager';
import { Utils } from './modules/utils';
import { KambiClientInit } from './modules/client-integration/init/kambi-client-init';
import { PokerClientInit } from './modules/client-integration/init/poker-client-init';
import { SpectateClientInit } from './modules/client-integration/init/spectate-client-init';

setMBServices();
const channels = initMessageBroker('Init');
setMutualServices();
setClientServices();
addSettings();
addMaintenance();
const clientSettings = Container.get(ClientSettingsToken);
const contentAdapter = Container.get(ContentAdapter);

const clientInit = addClientInit();
const initObject = getClientInitObject();
const exportedObject: any = {
    CloseSessionReasonEnum: CloseSessionReason,
    getClientSettings: clientSettings.getClientSettings,
    channels,
    performanceManager: Container.get(PerformanceManager),
    allowAttrFunction: getAllowAttr,
    feature: exportFeatureChecks(),
    envAccessAuthFunctions: {
        checkPassword: Container.get(EnvAccessAuthentication).checkPassword,
    },
    clientInit: initObject,
    init: {
        onClientLoad: (initFn) => {
            setTimeout(() => {
                clientInit.onClientLoad();
                if (initFn) {
                    initFn(initObject);
                }
            }, 0);
        },
    },
    messagebroker: MessageBroker,
    getOptimizelyData: async () => {
        const optimizely = await LoaderManager.Instance.OptimizelyLoader.Instance;
        return optimizely ? optimizely.getOptimizelyData() : null;
    },
};

function addB2Cintegration(exportedObject) {
    exportedObject.b2c = Container.get(B2CIntegration);
}

addB2Cintegration(exportedObject);
pushDataLayerData();
setDocumentEventsListeners();
addExportObjectsToGlobal({ exportedObject, contentAdapter });
setEntryLoaded(exportedObject);
setLoaded(exportedObject);
sendCorrelationEvent();
setLastProductCookie();
addNativeServiceToGlobal();

function addExportObjectsToGlobal(obj) {
    const { exportedObject, contentAdapter } = obj;
    global.ucf = exportedObject;
    global.uc = exportedObject;
    global.contentAdapter = contentAdapter;
}

async function addNativeServiceToGlobal() {
    const nativeService = await LoaderManager.Instance.NativeServiceLoader.Instance;
    if (nativeService) {
        global.NativeInterface = nativeService;
        Object.keys(NativeUtils).map((key) => (window[key] = NativeUtils[key]));
    }
}

function addClientInit() {
    const utils = Container.get(Utils);
    const clientProvider = utils.getClientProvider();
    const clients = {
        [ClientProvider.Kambi]: () => Container.get(KambiClientInit),
        [ClientProvider.Poker]: () => Container.get(PokerClientInit),
        [ClientProvider.Spectate]: () => Container.get(SpectateClientInit),
    };
    const clientInit = clients[clientProvider]
        ? clients[clientProvider]()
        : clients[ClientProvider.Spectate]();
    Container.set(ClientInitToken, clientInit);
    return clientInit;
}
