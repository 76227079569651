import Container, { Service } from 'typedi';
import { ClientsFrameworkLibrary } from 'library-manager';

import { ILogger, LoggerProvider } from '../../logger';
import { IClientsFrameworkOpenClientProductService } from './interfaces';

@Service()
export class ClientsFrameworkOpenClientProductService
    implements IClientsFrameworkOpenClientProductService
{
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientsFrameworkOpenClientProductService',
        );
    }

    public clientAutoLogin = async (
        productOfferingId,
        subBrandId,
        productPackageId,
        isRealMode,
        additionalActivationParameters,
        target,
        linkId?,
        checkIfTargetStillExists?,
        addToQueryString?,
    ) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.OpenClientProductPackage.clientAutoLogin(
                productOfferingId,
                subBrandId,
                productPackageId,
                isRealMode,
                additionalActivationParameters,
                target,
                linkId,
                checkIfTargetStillExists,
                addToQueryString,
            ),
        );
    };

    public clientOpenUnauthorized = async (
        productOfferingId,
        subBrandId,
        productPackageId,
        isRealMode,
        additionalActivationParameters,
        target,
        linkId?,
        checkIfTargetStillExists?,
        addToQueryString?,
        windowReference?,
    ) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.OpenClientProductPackage.clientOpenUnauthorized(
                productOfferingId,
                subBrandId,
                productPackageId,
                isRealMode,
                additionalActivationParameters,
                target,
                linkId,
                checkIfTargetStillExists,
                addToQueryString,
                windowReference,
            ),
        );
    };
}
