import Container, { Service } from 'typedi';
import { INativeMessage } from '../client-integration/init/native/models/INativeMessage';
import { ILogger, LoggerProvider } from '../logger';

@Service()
export class NativeSdkDispatcher {
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('NativeSdkDispatcher');
    }

    public dispatchNSDKEvent = (objectName: string, payload: INativeMessage) => {
        const nativeInterface = window[objectName];
        if (!nativeInterface) {
            return this._logger.log('trying to dispatchNSDKEvent on empty object');
        }
        this._logger.log(
            `sending message to: ${objectName} with payload: ${JSON.stringify(payload)}`,
        );
        nativeInterface.sendMessage(payload);
    };
}
