import { Service, Token } from 'typedi';
import { IAuthenticationFlowRunner } from '../models/';
import { Lock } from '../../utils/lockUtil';
import { BaseModuleLoader } from '../../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const RegularAuthenticationFlowRunnerToken = new Token<IAuthenticationFlowRunner>(
    'RegularAuthenticationFlowRunner',
);

@Service()
export class RegularAuthenticationFlowRunnerLoader extends BaseModuleLoader<IAuthenticationFlowRunner> {
    constructor() {
        super('RegularAuthenticationFlowRunnerLoader');
    }

    protected _createModuleInstance = async (): Promise<IAuthenticationFlowRunner> => {
        const { RegularAuthenticationFlowRunner } = await import(
            /* webpackChunkName: "authentication/flow-runners/regular" */
            './regular-authentication-flow-runner'
        );
        return new RegularAuthenticationFlowRunner();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IAuthenticationFlowRunner> =>
        RegularAuthenticationFlowRunnerToken;
}
