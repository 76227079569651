import { Service } from 'typedi';
import UserApi from '../../../../APIs/UserApi';

import { IFeatureEligibility } from './feature-eligibility-interface';
import PageContextManager from 'page-context-manager';
import { AppEligibilityPropertyEnum } from '../constants';
import {
    IEligibilityResponseWrapper,
    IGetEligibilityRequest,
    IUnifiedCashierEligibilityResponse,
} from './interfaces';
import { ApiResponse } from 'api';

@Service()
export class FeatureEligibility implements IFeatureEligibility {
    public IsUserEligible = async (propertyName: AppEligibilityPropertyEnum): Promise<boolean> => {
        if (!propertyName) return false;

        const { response } = await this.GetEligibility(propertyName);

        return !!response?.IsEligible || false;
    };

    public GetEligibility = async (
        widgetType: AppEligibilityPropertyEnum,
    ): Promise<ApiResponse<IEligibilityResponseWrapper<IUnifiedCashierEligibilityResponse>>> => {
        const country = PageContextManager.getGlobalizationData().country;
        const subBrandId = PageContextManager.getBrandData().subBrandId;

        const getEligibilityRequest: IGetEligibilityRequest = {
            SubBrandId: subBrandId,
            Country: country,
            Type: widgetType,
        };
        return UserApi.GetEligibility(getEligibilityRequest);
    };
}
