import Container, { Service } from "typedi";
import { ILogger, LoggerProvider } from "../../logger";

import { PreloaderToken } from "../../../injection-tokens";

@Service()
export class PreloaderWrapper {
    private readonly _preloader: any;
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            "PreloaderWrapper"
        );
        this._preloader = this.getPreloader();
    }

    public init(preloaderWatchEvents: Array<string>) {
        if (!!this._preloader) {
            if (preloaderWatchEvents.length == 0)
                this._logger.warn(
                    "preloader initialized with empty array of watch events."
                );
            else this._preloader.setWatchEvents(preloaderWatchEvents);
        } else this._logger.error("preloader doesn't exist on window.");
    }

    public onEventFinished(watchEvent: string, data: any): void {
        this._preloader?.onEventFinished(watchEvent, data);
    }

    public getDuration(): number {
        return this._preloader?.getDuration();
    }

    public cancel(): void {
        this._preloader?.cancel();
    }

    public finish(notifyElastic: boolean): void {
        this._preloader?.finish(notifyElastic);
    }

    public removeFailed(): void {
        this._preloader?.removeErrorScreen();
    }

    public remove(): void {
        this._preloader?.remove();
    }

    public showFailed(): void {
        this._preloader?.showErrorScreen();
    }

    private getPreloader = () => {
        if (Container.has(PreloaderToken)) {
            return Container.get(PreloaderToken)
        }

        return null;
    }
}
