export enum BiometricsFlowType {
    Biometrics = 'Biometrics',
    TouchId = 'TouchId',
}

export enum BiometricsElasticEvents {
    LoginIntentAppInitRequest = 'biometrics-login-intent-app-init-request',

    //used for monitoring service methods
    Status = 'biometrics-status',
    Enrollment = 'biometrics-enrollment',
    EnrollmentFinished = 'biometrics-enrollment-finished',
    EnrollmentStatus = 'biometrics-enrollment-status',
    Authorize = 'biometrics-authorize',
    Settings = 'biometrics-settings',
    Login = 'biometrics-login',
    LoginIntent = 'biometrics-login-intent',
    LoginCancelled = 'biometrics-login-cancelled',

    //used for monitoring our controller topic requests
    StatusRequest = 'biometrics-status-request',
    EnrollmentRequest = 'biometrics-enrollment-request',
    EnrollmentStatusRequest = 'biometrics-enrollment-status-request',
    EnrollmentFinishedRequest = 'biometrics-enrollment-finished-request',
    TokenRequest = 'biometrics-request-token',
    SettingsRequest = 'biometrics-settings-request',
    LoginIntentRequest = 'biometrics-login-intent-request',
    LoginRequest = 'biometrics-login-request',
    LoginCancelledRequest = 'biometrics-login-cancelled-request',
    FitToPlayLoginIntent = 'biometrics-fit-to-play-login-intent',
}

export enum BiometricsStoreItem {
    IsEnrolled = 'BiometricsIsEnrolled',
    IsEnabled = 'BiometricsFlowEnabled',
}
