import { ClickStreamEventInnerType, WebVitalsName } from '../enums/consts';
import Container, { Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';

import { ClickStreamTrackingProvider } from '..';
import { ITrackingProvider } from './interfaces';
import IWebVitalsTrackingClickStreamEventData from './IWebVitalsTrackingClickStreamEventData';
import { Utils } from '../../utils';
import {
    CLSAttribution,
    FIDAttribution,
    INPAttribution,
    LCPAttribution,
    Metric,
    MetricWithAttribution,
} from 'web-vitals/attribution';
import { UrlUtils } from '../../utils/urlUtils';

@Service()
export class WebVitalsElasticTracking implements ITrackingProvider {
    private _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private _eventFormatterBuilder: EventFormatterBuilder;
    private _utils: Utils;
    private _urlUtils: UrlUtils;

    constructor() {
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder = eventFormatterBuilderFactory.createEventFormatterBuilder(
            'WebVitalsElasticTracking',
        );
    }

    track(eventData: Metric, correlationID: string, subComponentName: string): void {
        if (!eventData) return;

        if (!correlationID) correlationID = this._utils.getCorrelationId();
        const formatter = this._eventFormatterBuilder.createFormatter(subComponentName || 'track');

        let debugTarget = '';
        let attribution: FIDAttribution | INPAttribution | LCPAttribution | CLSAttribution;
        const eventDataWithAttribution = eventData as MetricWithAttribution;
        switch (eventData.name) {
            case 'CLS':
                attribution = eventDataWithAttribution?.attribution as CLSAttribution;
                debugTarget = attribution?.largestShiftTarget;
                break;
            case 'FID':
                attribution = eventDataWithAttribution?.attribution as FIDAttribution;
                if ('eventTarget' in attribution) {
                    debugTarget = attribution?.eventTarget;
                }
                break;
            case 'LCP':
                attribution = eventDataWithAttribution?.attribution as LCPAttribution;
                debugTarget = attribution?.element;
                break;
            case 'INP':
                attribution = eventDataWithAttribution?.attribution as INPAttribution;
                if ('interactionTarget' in attribution) {
                    debugTarget = attribution?.interactionTarget;
                }
                break;
        }
        const elasticEventData: IWebVitalsTrackingClickStreamEventData = {
            event: eventData.name,
            delta: eventData.delta,
            value: eventData.value,
            id: eventData.id,
            innerType: ClickStreamEventInnerType.WebVitals,
            rating: eventData.rating,
            navigationType: eventData.navigationType,
            entries: JSON.stringify(eventData.entries),
            attribution: JSON.stringify((eventData as MetricWithAttribution)?.attribution),
            debugTarget,
            currentUrl: this._urlUtils.getCurrentUrl(),
        };

        const event = formatter.formatUCEvent(
            { message: elasticEventData.event, innerType: ClickStreamEventInnerType.WebVitals },
            { correlationID },
            elasticEventData,
        );

        this._clickStreamTrackingProvider.sendEventV2(event);
    }
}
