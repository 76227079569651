import { Lock } from '../utils/lockUtil';
import { Service, Token } from 'typedi';
import { IBiometricsService } from './models/IBiometricsService';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const BiometricsServiceToken = new Token<IBiometricsService>('BiometricsService');

@Service()
export class BiometricsServiceLoader extends BaseModuleLoader<IBiometricsService> {
    constructor() {
        super('PostLoginLoader');
    }

    protected _createModuleInstance = async (): Promise<IBiometricsService> => {
        const { BiometricsService } = await import(
            /* webpackChunkName: "biometrics/service" */
            './biometrics.service'
        );

        return new BiometricsService();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IBiometricsService> => BiometricsServiceToken;
}
