import { Lock } from '../utils/lockUtil';
import { Service, Token } from 'typedi';
import { ICRM } from './models';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const CRMToken = new Token<ICRM>('CRM');

@Service()
export class CRMLoader extends BaseModuleLoader<ICRM> {
    constructor() {
        super('CRMLoader');
    }

    protected _createModuleInstance = async (): Promise<ICRM> => {
        const module = await import(
            /* webpackChunkName: "crm/crm" */
            './crm'
        );

        return new module.CRM();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<ICRM> => CRMToken;
}
