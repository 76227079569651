/**
 *  Helper functions for strings
 */
export default class StringUtils {
    /**
     * Function to check if an input string value is null or empty or white spaces
     * @param value input string to verify
     */
    public static isNullOrWhiteSpaces = (value: string): boolean => {
        return value == null || value.trim() === '';
    };

    /**
     * Function to check if an input string value is a json formatted string
     * @param value input string to verify
     */
    public static isJsonString(value: any): boolean {
        try {
            JSON.parse(value);
        } catch (e) {
            return false;
        }

        return true;
    }

    public static toString = (value: any): string => {
        if (typeof value === 'undefined' || value === null) return value;
        if (typeof value === 'string') return value;

        return JSON.stringify(value);
    };

    public static withDefaultValue = <T>(param: T, defaultValue: T): T => {
        let returnValue = defaultValue;
        if (typeof param === 'string') {
            if (param !== '') returnValue = param;
        } else if (param !== undefined) {
            returnValue = param;
        }
        return returnValue;
    };
}
